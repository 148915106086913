import React, { useState, useEffect } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Button,
  Breadcrumbs,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import api from "../../utils/API";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useTranslation } from "react-i18next";

// NestedCategorySelect component
const NestedCategorySelect = ({
  handleSelectCategory,
  initialBreadcrumbs = [],
  handleSelectBreadcrumbs,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { i18n } = useTranslation();
  const [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    initialBreadcrumbs[initialBreadcrumbs.length - 1] || null
  );

  const fetchSubCategories = async (categoryId) => {
    setLoading(true);
    try {
      const response = await api.get(`/category/sub-categories/${categoryId}`);
      return response.data.data; // Adjust according to your API response structure
    } catch (error) {
      console.error("Error fetching subcategories:", error);
      return []; // Return an empty array on error
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    const lastSelectedId = selectedCategory ? selectedCategory?._id : null;
    const options = await fetchSubCategories(lastSelectedId);
    setCurrentOptions(options);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategorySelect = async (category) => {
    setBreadcrumbs((prev) => [...prev, category]);
    setSelectedCategory(category);

    const options = await fetchSubCategories(category?._id);
    if (options.length === 0) {
      handleClose(); // Close if no subcategories are found
    } else {
      setCurrentOptions(options);
    }
  };

  const handleBreadcrumbClick = async (index) => {
    if (index == null) {
      setBreadcrumbs([]);
      setSelectedCategory(null);
    } else {
      const updatedBreadcrumbs = breadcrumbs.slice(0, index + 1);
      setBreadcrumbs(updatedBreadcrumbs);
      const lastSelectedCategory =
        updatedBreadcrumbs[updatedBreadcrumbs.length - 1];
      setSelectedCategory(lastSelectedCategory);
    }
  };

  useEffect(() => {
    handleSelectCategory(selectedCategory);
  }, [selectedCategory]);

  useEffect(() => {
    handleSelectBreadcrumbs(breadcrumbs);
  }, [breadcrumbs]);

  useEffect(() => {
    setBreadcrumbs(initialBreadcrumbs);
  }, [initialBreadcrumbs]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        width: "100%",
        padding: 1,
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "2px solid gray",
      }}
    >
      {/* Menu to show the options */}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {loading ? (
          <MenuItem>
            <CircularProgress size={24} />
            <Typography sx={{ ml: 2 }}>Loading...</Typography>
          </MenuItem>
        ) : (
          currentOptions.map((option) => (
            <MenuItem
              key={option._id}
              onClick={() => handleCategorySelect(option)}
            >
              {getLocalizedValue(option.name)}
            </MenuItem>
          ))
        )}
      </Menu>

      {/* Breadcrumbs */}
      <Box>
        <Breadcrumbs separator="›" aria-label="breadcrumb">
          <Link
            color="inherit"
            onClick={() => handleBreadcrumbClick(null)}
            sx={{ cursor: "pointer", color: "#000000", fontWeight: "bold" }}
          >
            All
          </Link>
          {breadcrumbs.map((crumb, index) => (
            <Link
              key={crumb._id}
              color="inherit"
              onClick={() => handleBreadcrumbClick(index)}
              sx={{ cursor: "pointer", color: "#000000", fontWeight: "bold" }}
            >
              {getLocalizedValue(crumb.name)}
            </Link>
          ))}
        </Breadcrumbs>
      </Box>
      <Button variant="text" onClick={handleClick}>
        <ArrowForwardIos
          color="primary"
          fontSize="medium"
          style={{
            fontWeight: "bold",
            transform:
              i18n.language === "ar" ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </Button>
    </Box>
  );
};

export default NestedCategorySelect;
