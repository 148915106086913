import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  Checkbox,
  CircularProgress,
  Paper,
  Container,
} from "@mui/material";
import CustomInputRange from "../common/CustomInputRange";
import CustomSelect from "../common/CustomSelect";
import CustomInputWithLabel from "../common/CustomInputWithLabel";
import useCreatePost from "../../hooks/useCreatePost";
import { categoryService } from "../../services/category.api";
import { useTranslation } from "react-i18next";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const AdditionalInfo = (props) => {
  const { name, handleCheckboxChange, isChecked } = props;
  const handleChange = (event) => {
    handleCheckboxChange(name, event.target.checked);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      px={1}
      style={{ backgroundColor: "#FFFFFF", borderRadius: "10px" }}
    >
      <Typography fontWeight="bold">{name}</Typography>
      <Checkbox {...label} checked={isChecked} onChange={handleChange} />
    </Box>
  );
};

const AdSpecifications = (props) => {
  const { t } = useTranslation();
  const { handleBack, handleNext } = props;
  const { stepData, saveData } = useCreatePost();
  const { specification, optionalSpecifications, additionalDetails } =
    stepData.form;
    
  const [formData, setFormData] = useState(
    stepData.formValue.specification || {}
  );
  const [optionalFormData, setOptionalFormData] = useState(
    stepData.formValue.optionalSpecifications || {}
  );
  const [additionalFormData, setAdditionalFormData] = useState(
    stepData.formValue.additionalDetails || {}
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formErrors, setFormErrors] = useState({}); // Track form errors

  const fetchData = async () => {
    try {
      const response = await categoryService.getCategoryById(
        stepData.adInfo.selectedCategory.categoryId
      );
      if (response.success) {
        const { specification, optionalSpecifications, additionalDetails } =
          response.data;
          
        saveData("form", {
          specification,
          optionalSpecifications,
          additionalDetails,
        });
        setLoading(false);
      } else {
        setError("Failed to load data.");
        setLoading(false);
      }
    } catch (err) {
      setError("Error fetching data.");
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleInputChange = (name, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleOptionalInputChange = (name, value) => {
    setOptionalFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (name, isChecked) => {
    setAdditionalFormData((prevState) => {
      const updatedData = { ...prevState };
      if (isChecked) {
        updatedData[name] = true; // Add key with value true if checked
      } else {
        delete updatedData[name]; // Remove key if unchecked
      }
      return updatedData;
    });
  };

  const validateForm = () => {
    let errors = {};
    specification.items.forEach((spec) => {
      if (!formData[spec.name.en]) {
        errors[spec.name.en] = `${spec.name.en} is required`;
      }
    });
    return errors;
  };

  const handleSubmit = () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors); // Display errors
    } else {
      setFormErrors({});
      saveData("formValue", {
        specification: formData,
        optionalSpecifications: optionalFormData,
        additionalDetails: additionalFormData,
      });
      handleNext(); // Proceed to next step if no errors
    }
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        minHeight='70vh'
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" textAlign="center">
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{marginTop:'30px'}}>
      <Paper elevation={0}>
        <Container maxWidth='sm'>
          <Grid
            container
            spacing={2}
            sx={{ padding: 3 }}
          >
            <Grid item xs={12}>
              <Typography
                variant="body2"
                textAlign="center"
                fontWeight="bold"
                color="primary"
              >
                {t("SELECT_AD_SPECIFICATIONS")}
              </Typography>
              <br />
              <Typography
                variant="body2"
                textAlign="center"
                fontWeight="bold"
                color="red"
              >
                {t("REQUIRED_INFORMATION")}
              </Typography>
            </Grid>
            {specification?.items?.map((spec, index) => (
          <Grid item xs={12} key={index}>
            <Box>
              {spec.type === "Input Number Range" && (
                <>
                  <CustomInputRange
                    name={spec.name.en}
                    icon={spec.icon}
                    value={formData[spec.name.en]}
                    range={spec.range}
                    isRequired={true}
                    onInputChange={(value) =>
                      handleInputChange(spec.name.en, value)
                    }
                  />
                  {formErrors[spec.name.en] && (
                    <Typography color="error" variant="body2">
                      {formErrors[spec.name.en]}
                    </Typography>
                  )}
                </>
              )}

              {spec.type === "Input" && (
                <>
                  <CustomInputWithLabel
                    name={spec.name.en}
                    icon={spec.icon}
                    isRequired={true}
                    onInputChange={(value) =>
                      handleInputChange(spec.name.en, value)
                    }
                    inputProps={spec.inputProps}
                    value={formData[spec.name.en]}
                  />
                  {formErrors[spec.name.en] && (
                    <Typography color="error" variant="body2">
                      {formErrors[spec.name.en]}
                    </Typography>
                  )}
                </>
              )}

              {spec.type === "Dropdown List" && (
                <>
                  <CustomSelect
                    name={spec.name.en}
                    icon={spec.icon}
                    options={spec?.listItems?.map((item) => ({
                      label: item?.name?.en,
                      value: item?.name?.en,
                    }))}
                    selectPreview={true}
                    isRequired={true}
                    onSelectChange={(value) =>
                      handleSelectChange(spec.name.en, value)
                    }
                    value={{
                      label: formData[spec.name.en],
                      value: formData[spec.name.en],
                    }}
                  />
                  {formErrors[spec.name.en] && (
                    <Typography color="error" variant="body2">
                      {formErrors[spec.name.en]}
                    </Typography>
                  )}
                </>
              )}
            </Box>
          </Grid>
        ))}
          </Grid>
        </Container>
      </Paper>

      {/* Optional specifications and additional details sections omitted for brevity */}
      <Paper elevation={0} marginTop='20px'>
      <Container maxWidth='sm'>
      {(optionalSpecifications?.isEnabled && optionalSpecifications?.items?.length > 0) && (<Grid
        container
        spacing={2}
        sx={{ padding: 3, mt: 4 }}
      >
        <Grid item xs={12}>
          <Typography variant="body2" textAlign="center" fontWeight="bold">
            {t("OPTIONAL_INFORMATION")}
          </Typography>
        </Grid>
        {optionalSpecifications?.items?.map((spec, index) => (
          <Grid item xs={12} key={index}>
            <Box>
            {spec.type === "Input Number Range" && (
                <>
                  <CustomInputWithLabel
                    name={spec.name.en}
                    icon={spec.icon}
                    isRequired={false}
                    onInputChange={(value) =>
                      handleOptionalInputChange(spec.name.en, value)
                    }
                    value={optionalFormData[spec.name.en]}
                  />
                </>
              )}

              {spec.type === "Input" && (
                <>
                  <CustomInputRange
                    name={spec.name.en}
                    icon={spec.icon}
                    isRequired={false}
                    inputProps={spec.inputProps}
                    onInputChange={(value) =>
                      handleOptionalInputChange(spec.name.en, value)
                    }
                  />
                </>
              )}

              {spec.type === "Dropdown List" && (
                <>
                  <CustomSelect
                    name={spec.name.en}
                    icon={spec.icon}
                    selectPreview={true}
                    options={spec?.listItems?.map((item) => ({
                      label: item?.name?.en,
                      value: item?.name?.en,
                    }))}
                    isRequired={false}
                    onSelectChange={(value) =>
                      handleOptionalInputChange(spec.name.en, value)
                    }
                    value={{
                      label: optionalFormData[spec.name.en],
                      value: optionalFormData[spec.name.en],
                    }}
                  />
                </>
              )}
            </Box>
          </Grid>
        ))}
      </Grid>)}
      </Container>
      </Paper>
      <Paper elevation={0} marginTop='20px'>
      <Container maxWidth='sm'>
      {
        (additionalDetails?.isEnabled && additionalDetails?.items?.length > 0) && (
          <Grid
            container
            spacing={2}
            sx={{ padding: 3, mt: 4 }}
          >
            <Grid item xs={12}>
              <Typography variant="body2" textAlign="center" fontWeight="bold">
                {t("ADDITIONAL_INFORMATION")}
              </Typography>
            </Grid>
            {additionalDetails?.items?.map((option, index) => (
              <Grid item xs={12} key={index}>
                <AdditionalInfo
                  name={option?.name?.en}
                  isChecked={!!additionalFormData[option?.name?.en]}
                  handleCheckboxChange={handleCheckboxChange}
                />
              </Grid>
            ))}
          </Grid>
        )
      }
      </Container>
    </Paper>
    <Paper elevation={0} marginTop='20px' p={2}>


      <Box sx={{ display: "flex", justifyContent: "center", gap: 5, mt: 3,p:3 }}>
        <Button variant="contained" style={{ backgroundColor: '#606161', textTransform: 'capitalize' }} onClick={handleBack}>
          {t("BACK")}
        </Button>
        <Button variant="contained" color="primary" style={{ textTransform: 'capitalize', paddingX: 2 }} onClick={handleSubmit}>
          {t("NEXT")}
        </Button>
      </Box>
    </Paper>
    </Box>
  );
};

export default AdSpecifications;
