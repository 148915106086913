import api from "../utils/API";

// Base URL for your API
export const dashboardService = {
  fetchCounts: async () => {
    const response = await api.get("/admin/dashboard/get-all-counts");
    return response.data;
  },

  fetchTopCustomers: async () => {
    const response = await api.get("/admin/dashboard/get-top-customers");
    return response.data;
  },

  fetchRecentAds: async () => {
    const response = await api.get("/admin/dashboard/get-recent-ads");
    return response.data;
  },
  fetchReports: async (payload) => {
    const response = await api.post("/admin/dashboard/get-reports", payload);
    return response.data;
  },
};
