import { TextField } from "@mui/material";
import { styled } from '@mui/system';


// Custom styled TextField
const CustomInput = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        backgroundColor: '#fff', // Set the background color to white
        borderRadius: '10px', // Add some border-radius for better look
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none', // Remove the border
    },
    '& .MuiInputLabel-root': {
        color: theme.palette.text.primary, // Adjust the label color
    },
    '& .MuiInputBase-input': {
        padding: '12px', // Adjust the padding for better spacing
    },
}));

export default CustomInput