import { useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import useReport from "../../hooks/useReport";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { formatTimestamp } from "../../utils/formatHelper";
import { ArrowBack } from "@mui/icons-material";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useTranslation } from "react-i18next";

function ReportView() {
  const { id } = useParams();
  const { t } = useTranslation();
  const { report, getReportById, deleteReports, loading } = useReport();
  const [reportData, setReportData] = useState({});
  const handleDeleteReport = (reportId) => {
    if (window.confirm("Are you sure you want to delete this report?")) {
      deleteReports({ ids: [reportId] }).then((res) => {
        if (res?.success) {
          window.location.href = "/reports";
        } else {
          alert(res.message || "Error deleting report");
        }
      });
    }
  };

  useEffect(() => {
    if (id) {
      getReportById(id).then((res) => {
        if (res?.success) {
          setReportData(res?.data);
        }
      });
    }
  }, [id]);
  return loading ? (
    <ReportViewSkeleton />
  ) : (
    <div>
      <CustomPageHeader
        heading={t("REPORT_VIEW")}
        buttontext={t("BACK_TO_REPORT_LIST")}
        path="/reports"
      />
      <Box>
        <ReportComponent report={reportData} loading={loading} />
      </Box>
      <Paper elevation={0} style={{ padding: "20px" }}>
        <Typography color="primary" variant="body1" fontWeight="bold">
          {" "}
          {t("DATE_TIME_OF_REPORT")}
        </Typography>
        <Typography
          variant="body2"
          fontWeight="bold"
          style={{ marginBottom: "20px" }}
        >
          {formatTimestamp(reportData?.createdAt)}
        </Typography>
        <Typography color="primary" variant="body1" fontWeight="bold">
          {t("WHY_ARE_YOU_REPORTING_THIS")}
        </Typography>
        <Box sx={{ display: "flex", marginBottom: "20px", marginTop: "10px" }}>
          {reportData?.reasons?.map((item) => (
            <Box
              bgcolor="primary.main"
              sx={{
                backgroundColor: "#ff8d7a2e",
                marginRight: ".5rem",
                padding: "5px",
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                {" "}
                {item}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography color="primary" variant="body1" fontWeight="bold">
          {t("TEXT_MESSAGE")}
        </Typography>
        <Box
          sx={{
            backgroundColor: "#ff8d7a2e",
            padding: "5px",
            marginTop: "10px",
          }}
        >
          <Typography variant="body2" fontWeight="bold">
            {reportData?.textMessage}
          </Typography>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => window.history.back()}
            >
              {t("BACK")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px" }}
              size="large"
              onClick={() => handleDeleteReport(reportData?._id)}
            >
              {t("DELETE_REPORTS")}
            </Button>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}

const ReportComponent = ({ report, loading }) => {
  const { t } = useTranslation();
  return (
    <Grid container spacing={2} paddingY={2}>
      {/* Reported On Details */}
      <Grid item xs={12} md={7}>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Typography color="primary" gutterBottom mb={"1rem"}>
            {t("REPORTED_ON")}
          </Typography>

          <Grid container spacing={2}>
            {/* Image Section */}
            <Grid item xs={4}>
              <Avatar
                src={
                  report?.reportType === "User"
                    ? report?.reportedOn?.profilePicture
                    : report?.reportedOn?.images?.[0] // Replace with ad image if available
                }
                alt={"#"}
                sx={{
                  width: "100%",
                  height: "180px",
                  maxWidth: "150px",
                  maxHeight: "174px",
                  borderRadius: 2,
                }}
                variant="square"
              />
            </Grid>

            {/* Details Section */}
            <Grid item xs={8} md={8}>
              {report?.reportType === "User" ? (
                <>
                  <Typography variant="h6">
                    {report?.reportedOn?.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>{t("LOCATION")}:</strong>{" "}
                    {report?.reportedOn?.location}
                  </Typography>
                  <Typography variant="body2">
                    <strong>{t("TOTAL_ADS")}:</strong>{" "}
                    {report?.reportedOn?.totalAds}
                  </Typography>
                  <Typography variant="body2">
                    <strong> {t("MEMBER_SINCE")}:</strong>{" "}
                    {formatTimestamp(report?.reportedOn?.createdAt, false)}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: "1rem",
                      textTransform: "inherit",
                      minWidth: "150px",
                    }}
                    size="large"
                    href={`/user/${report?.reportedOn?._id}`}
                  >
                    {t("VIEW_USER")}
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="h6">
                    {report?.reportedOn?.title}
                  </Typography>
                  <Typography
                    variant="h6"
                    color="primary"
                    fontWeight="bold"
                  ></Typography>
                  <Typography
                    variant="body2"
                    display="flex"
                    alignItems="center"
                    fontWeight="bold"
                  >
                    <LocationOnIcon color={"primary"} fontSize={"small"} />
                    {report?.reportedOn?.country}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="primary"
                    display="flex"
                    alignItems="center"
                  >
                    <strong style={{ color: "#222", marginRight: "3px" }}>
                      {t("CATEGORY")}:{" "}
                    </strong>
                    {getLocalizedValue(report?.reportedOn?.category?.name)}
                  </Typography>
                  <Typography variant="body2" fontWeight="bold" color="gray">
                    {t("ID")}: {report?._id}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      marginTop: ".5rem",
                      textTransform: "inherit",
                      minWidth: "150px",
                    }}
                    size="large"
                    href={`/ad-info/${report?.reportedOn?._id}`}
                  >
                    {t("VIEW_AD_DETAILS")}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Reported By Details */}
      <Grid item xs={12} md={5}>
        <Paper elevation={0} sx={{ padding: 2 }}>
          <Typography color="primary" gutterBottom>
            {t("REPORTED_BY")}
          </Typography>
          <Box fullwidth display="flex" justifyContent="center">
            <Avatar
              src={
                report?.reportedBy?.profilePicture ||
                "https://via.placeholder.com/150"
              }
              alt={report?.reportedBy?.name}
              sx={{
                width: "85px",
                height: "85px",
                maxWidth: "110px",
                maxHeight: "110px",
              }}
            />
          </Box>
          <Box
            fullwidth
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%" }}
          >
            <Box textAlign="center">
              <Typography variant="h6">{report?.reportedBy?.name}</Typography>
              <Typography variant="body2" color="primary">
                {t("MEMBER_SINCE")}{" "}
                {formatTimestamp(report?.reportedBy?.createdAt, false)}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  marginTop: ".5rem",
                  textTransform: "inherit",
                  minWidth: "150px",
                }}
                size="large"
                href={`/user/${report?.reportedBy?._id}`}
              >
                {t("VIEW_USER")}
              </Button>
            </Box>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ReportView;

const ReportViewSkeleton = () => {
  return (
    <div>
      <Paper elevation={0} sx={{ padding: "20px" }}>
        <Box>
          <Skeleton variant="rectangular" height={40} width="60%" />
        </Box>
      </Paper>
      <Grid container spacing={2} paddingY={2}>
        {/* Reported On Details */}
        <Grid item xs={12} md={7}>
          <Paper elevation={0} sx={{ padding: 2 }}>
            <Typography color="primary" gutterBottom mb={"1rem"}>
              <Skeleton width={120} />
            </Typography>

            <Grid container spacing={2}>
              {/* Image Section */}
              <Grid item xs={4}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={180}
                  sx={{
                    maxWidth: "150px",
                    maxHeight: "174px",
                    borderRadius: 2,
                  }}
                />
              </Grid>

              {/* Details Section */}
              <Grid item xs={8} md={8}>
                <Typography variant="h6">
                  <Skeleton width="80%" />
                </Typography>
                <Typography variant="body2">
                  <Skeleton width="60%" />
                </Typography>
                <Typography variant="body2">
                  <Skeleton width="40%" />
                </Typography>
                <Typography variant="body2">
                  <Skeleton width="50%" />
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  sx={{
                    marginTop: "1rem",
                    textTransform: "inherit",
                    minWidth: "150px",
                  }}
                >
                  <Skeleton width="100%" />
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/* Reported By Details */}
        <Grid item xs={12} md={5}>
          <Paper elevation={0} sx={{ padding: 2 }}>
            <Typography color="primary" gutterBottom>
              <Skeleton width={100} />
            </Typography>
            <Box
              fullwidth
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Skeleton
                variant="circular"
                width={85}
                height={85}
                sx={{ maxWidth: "110px", maxHeight: "110px" }}
              />
            </Box>
            <Box
              fullwidth
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%" }}
            >
              <Box textAlign="center">
                <Typography variant="h6">
                  <Skeleton width="70%" />
                </Typography>
                <Typography variant="body2" color="primary">
                  <Skeleton width="50%" />
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  disabled
                  sx={{
                    marginTop: ".5rem",
                    textTransform: "inherit",
                    minWidth: "150px",
                  }}
                >
                  <Skeleton width="100%" />
                </Button>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      <Paper elevation={0} sx={{ padding: "20px" }}>
        <Typography variant="body1" fontWeight="bold" color="primary">
          <Skeleton width="40%" />
        </Typography>
        <Skeleton
          variant="rectangular"
          height={20}
          width="30%"
          sx={{ mt: 1, mb: 2 }}
        />
        <Typography variant="body1" fontWeight="bold" color="primary">
          <Skeleton width="60%" />
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            marginBottom: "20px",
            marginTop: "10px",
          }}
        >
          {Array.from({ length: 3 }).map((_, index) => (
            <Box
              key={index}
              sx={{
                backgroundColor: "#f0f0f0",
                padding: "5px 10px",
                borderRadius: "4px",
              }}
            >
              <Skeleton width={60} />
            </Box>
          ))}
        </Box>
        <Typography variant="body1" fontWeight="bold" color="primary">
          <Skeleton width="50%" />
        </Typography>
        <Box
          sx={{
            backgroundColor: "#f0f0f0",
            padding: "10px",
            marginTop: "10px",
          }}
        >
          <Skeleton variant="rectangular" height={20} width="100%" />
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <Skeleton variant="rectangular" width={150} height={40} />
            <Skeleton variant="rectangular" width={150} height={40} />
          </Box>
        </Container>
      </Paper>
    </div>
  );
};
