import api from "../utils/API";

// Base URL for your API
export const bannerService = {
  fetchBannerItems: async (payload) => {
    const response = await api.get("/admin/banners", {
      params: payload,
    });
    return response.data;
  },

  createBanner: async (payload) => {
    const response = await api.post("/admin/banners", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  fetchBannerById: async (id) => {
    const response = await api.get(`/admin/banners/${id}`);
    return response.data;
  },

  updateBanner: async (id, payload) => {
    const response = await api.put(`/admin/banners/${id}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  deleteBanner: async (id) => {
    const response = await api.delete(`/admin/banners/${id}`);
    return response.data;
  },
};
