import { useEffect, useState, useCallback, useRef } from 'react';
import { fetchConversations, fetchMessages } from '../services/chat.api';

export const useChat = (socket, user) => {
  const [messages, setMessages] = useState([]);
  const [currentMessagesPage,setCurrentMessagesPage] =useState(0)
  const [totalMessagesPages,setMessagesTotalPages] =useState(1)
  const [conversations, setConversations] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [message, setMessage] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const typingTimeoutRef = useRef(null);
  const [unreadCounts, setUnreadCounts] = useState({});
  const [lastMessages, setLastMessages] = useState({});

  const fetchConversationsData = useCallback(async (searchQuery) => {
    const { data } = await fetchConversations(user, searchQuery);
    setConversations(data.conversations);
    const fetchedLastMessages = {};
    data.conversations.map((conv) => {
      fetchedLastMessages[conv._id] = conv.lastMessage;
    });
    setLastMessages(fetchedLastMessages);
  }, [user]);

  const fetchMessagesData = useCallback(async (chatId,page) => {
    const {data} = await fetchMessages(chatId, page);
    if (chatId=== data.conversationId) {
        setMessages((prev) => {
            const combinedMessages = [...prev, ...data.messages];
            const uniqueMessages = Array.from(
              new Map(combinedMessages.map((message) => [message._id, message])).values()
            );
            return uniqueMessages;
          });
        setCurrentMessagesPage(data.currentPage)
        setMessagesTotalPages(data.totalPages)
    }
  }, []);

  const handleSendMessage = () => {
    if (message.trim() && socket && selectedChat) {
      const receiverId = selectedChat.participants?.find(participant => participant._id !== user.id)?._id;
      socket.emit('message:send', { conversationId: selectedChat._id, content: message, receiverId:receiverId });
      setMessage('');
    }
  };
  

  const handleTyping = () => {
    if (socket && selectedChat) {
      socket.emit('typing:start', selectedChat.id);
      clearTimeout(typingTimeoutRef.current);
      typingTimeoutRef.current = setTimeout(() => {
        socket.emit('typing:stop', selectedChat.id);
      }, 1000);
    }
  };

  const resetChat = ()=>{
    setMessages([])
    setCurrentMessagesPage(0)
    setMessagesTotalPages(1)
    setMessage("")
  }

  // Socket event listeners
  useEffect(() => {
    if (socket) {
      socket.on("conversation:new", (conversation) => {
        setConversations(prev => [conversation, ...prev]);
      });

      socket.on("message:new", (payload) => {
        const { conversationId, message, lastMessage } = payload;
        if (selectedChat?._id === conversationId) {
          setMessages(prev => ([message, ...prev]));
        } else {
          setUnreadCounts(prev => ({
            ...prev,
            [conversationId]: (prev[conversationId] || 0) + 1
          }));

          // Show browser notification if permitted
          if (Notification?.permission === 'granted') {
            const sender = conversations.find(c => c._id === conversationId)?.participants
              ?.find(p => p._id !== user.id)?.name;

            if (sender) {
              new Notification('New Message', {
                body: `${sender}: ${message.content}`,
                icon: '/your-app-icon.png' // Add your app icon path
              });
            }
          }
        }
        
        setLastMessages((prev) => {
          prev[conversationId] = lastMessage;
          return prev;
        });
      });

      socket.on('conversation:typing', ({ conversationId, userId, isTyping }) => {
        if (conversationId === selectedChat?.id) setIsTyping(isTyping);
      });

      return () => {
        socket.off("conversation:new");
        socket.off("message:new");
        socket.off('conversation:typing');
      };
    }
  }, [selectedChat, socket]);

  
  useEffect(() => {
    if (socket) {
      // Get initial unread counts
      socket.emit('get_unread_counts');

      // Listen for unread counts
      socket.on('unread_counts', (counts) => {
        setUnreadCounts(counts);
      });

      return () => {
        socket.off('unread_counts');
      };
    }
  }, [socket]);
  
// When opening a conversation
useEffect(() => {
  if (selectedChat && messages.length > 0) {
    const unreadMessageIds = messages
      .filter(msg => msg.senderId !== user.id && msg.status !== 'read')
      .map(msg => msg._id);

    if (unreadMessageIds.length > 0) {
      setUnreadCounts(prev => ({
        ...prev,
        [selectedChat._id]: 0
      }));
      
      socket.emit('message:read', { conversationId: selectedChat._id });
    }
  }
}, [selectedChat, messages]);

  return {
    conversations,
    messages,
    currentMessagesPage,
    totalMessagesPages,
    selectedChat,
    setSelectedChat,
    fetchConversationsData,
    fetchMessagesData,
    handleSendMessage,
    handleTyping,
    isTyping,
    message,
    setMessage,
    resetChat,
    unreadCounts,
    lastMessages,
  };
};
