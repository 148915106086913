import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Checkbox,
  Typography,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import i18n from 'i18next';
import CustomTable from "../../components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import useCategory from "../../hooks/useCategory";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import { Widgets } from "@mui/icons-material";

// Columns definition

function CategoryPage() {
  const { t } = useTranslation();


  const navigate = useNavigate();
  const {
    fetchCategories,
    categories,
    loading,
    deleteCategory,
    clearCategory,
  } = useCategory();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: categories?.page || 1,
    limit: categories?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: categories?.totalCount,
    totalPages: categories?.totalPages,
  });
  const [search, setSearch] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);

  const columns = [
    {
      id: "id", 
      label: <Checkbox
        size="small"
        color="white"
        checked={(selectedIds.length === categories?.categories?.length && selectedIds.length !== 0)}
        onChange={() => handleSelelctAll()}
      />, 
      align: i18n.language === 'en' ? 'left' : 'right',
      Width:'100px'
    },
    // { id: "icon", label: t('ICON'), align: i18n.language == 'en' ? 'left': 'right' },
    { id: "name", label: t('CATEGORY_LIST'), align: i18n.language === 'en' ? 'left' : 'right', Width:'auto' },
    { id: "action", label: t('ACTION'), align: 'center' },
  ];


  const handleSelelctAll = () => {
    if (selectedIds.length === categories?.categories?.length && selectedIds.length !== 0) {
      setSelectedIds([])
    } else {
      setSelectedIds(categories?.categories.map(item => item._id))

    }
  }

  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
    };
    fetchCategories(payload);
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleDelete = async () => {
    const payload = {
      ids: selectedIds,
    };
    await deleteCategory(payload);
    setPagination({ ...pagination, page: 1 });
    const payload2 = {
      page: pagination?.page + 1,
      limit: pagination?.limit,
      search: "",
    };
    fetchCategories(payload2);
    setSelectedIds([]);
  };

  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: "",
    };
    fetchCategories(payload);
  }, [pagination?.page, pagination?.limit]);
  useEffect(() => {
    if (categories?.categories?.length > 0) {
      setData(
        categories?.categories?.map((category) => ({
          id: (
            <Box sx={{ maxWidth: "10px" }}>
              <Checkbox
                size="small"
                checked={selectedIds.includes(category._id)}
                onChange={() => handleCheckboxChange(category._id)}
              />
            </Box>
          ),
          // icon: (
          //   <img
          //     src={category.iconImage}
          //     alt="icon"
          //     style={{ width: "40px", height: "40px" }}
          //   />
          // ),
          name: (
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {getLocalizedValue(category.name)}
            </Typography>
          ),
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "inherit",
                padding: "0",
                minWidth: "45px",
                fontWeight: "bold"
              }}
              onClick={() => {
                navigate(`/category/${category?._id}`);
              }}
            >
              {t("EDIT")}
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: categories?.totalCount || 0,
      totalPages: categories?.totalPages || 0,
    });
  }, [categories, selectedIds]);
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t("CATEGORY_ITEM_LIST")}
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={() => {
                clearCategory();
                navigate("/category/create");
              }}
            >
              {t("ADD_CATEGORY")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={() => {
                if (!window.confirm("Are you sure you want to delete this?")) {
                  return;
                }
                handleDelete();
              }}
            >
              {t("DELETE")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              placeholder={t('SEARCH_CATEGORY_NAME')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="medium"
              onClick={handleSearch}
            >
              {t("SEARCH")}
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

export default CategoryPage;
