import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Typography,
  Box,
  FormHelperText,
  InputLabel,
  Paper,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../common/CustomSelect";
import AppsIcon from "@mui/icons-material/Apps";
import MapComponent from "./MapComponent";
import NestedCategorySelect from "./NestedCategorySelect";
import CustomInput from "../common/CustomInput";
import useConfig from "../../hooks/useConfig";
import useCreatePost from "../../hooks/useCreatePost";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useTranslation } from "react-i18next";
import CustomCountrySelect from "../common/CustomCountrySelect";

const validationSchema = Yup.object({
  selectedCategory: Yup.object({
    categoryId: Yup.string().required("Category is required"), // Only categoryId is required
    name: Yup.object({
      en: Yup.string(),
      ar: Yup.string(),
    }),
  }).required(),
  selectedCountry: Yup.string().required("Country is required"),
  title: Yup.string().min(5, "Post title must be at least 5 characters long.").trim().required("Post title is required"),
  price: Yup.number()
    .min(1, "Price must be at least 1")
    .required("Price is required"),
  description: Yup.string().min(10, "Post description must be at least 10 characters long.").trim().required("Description is required"),  location: Yup.object({
  locationName: Yup.string().trim().required("Location name is required"), // Only locationName is required
  address: Yup.object(),
  markerPosition: Yup.object({
      lat: Yup.string(),
      lng: Yup.string(),
    }),
  }).required(),
});

function findItemByText(text, items) {
  const searchText = text.trim();
  if (!searchText) {
    return {
      label: "",
      value: "",
      currency: "",
    };
  }
  const filteredItem = items.find((item) => {
    const nameEn = item.name?.en?.toLowerCase() || ""; // English name (lowercased)
    const nameAr = item.name?.ar || ""; // Arabic name (not lowercased)
    return nameEn === searchText.toLowerCase() || nameAr === searchText;
  });

  if (!filteredItem) {
    return {
      label: "",
      value: "",
      currency: "",
    };
  }
  return {
    label: filteredItem.name.en, // Use localized name
    value: filteredItem._id, // Return the _id
    currency: filteredItem.currency.en, // Use localized currency
  };
}

const AdInfo = (props) => {
  const { t } = useTranslation();
  const { handleNext } = props;
  const { fetchCountry, countries, loading } = useConfig();
  const { saveData, stepData } = useCreatePost();

  useEffect(() => {
    fetchCountry();
  }, []);

  // Formik setup
  const formik = useFormik({
    initialValues: stepData.adInfo || {
      breadcrumbs: [],
      selectedCategory: {
        categoryId: "",
        name: { en: "", ar: "" },
      },
      selectedCountry: "",
      currency: "",
      title: "",
      price: "",
      description: "",
      location: {
        locationName: "",
        address: {},
        markerPosition: {},
      },
    },
    validationSchema,
    onSubmit: (values) => {
      saveData("adInfo", { ...values });
      handleNext();
    },
  });

  // Update currency when the country changes
  const handleCountryChange = (value) => {
    formik.setFieldValue("selectedCountry", value.label);
    if (value.currency) {
      formik.setFieldValue("currency", value.currency);
    }
  };

  const handleLocationChange = (value) => {
    formik.setFieldValue("location", value);
  };

  const handleSelectCategory = (value) => {
    if (value) {
      formik.setFieldValue("selectedCategory", {
        categoryId: value._id,
        name: value.name,
      });
    }
  };

  const handleSelectBreadcrumbs = (value) => {
    if (value) {
      formik.setFieldValue("breadcrumbs", value);
    }
  };

  const result = useMemo(
    () =>
      findItemByText(
        formik.values.selectedCountry,
        countries.map((item) => ({
          name: item.name,
          _id: item._id,
          currency: item.currency,
        }))
      ),
    [formik.values.selectedCountry, countries]
  );

  return (
    <Box>
      <Paper elevation={0} style={{marginTop:'10px',padding:3}}>
      <Typography
        variant="h6"
        color="primary"
        style={{
          fontSize: "14px",
          textAlign: "center",
          fontWeight: "bold",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        {t("ENTER_ADVERTISEMENT_INFO")}
      </Typography>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          maxWidth: "600px",
          margin: "auto",
          padding: "16px 20px",
          borderRadius: "8px",
        }}
      >
        {/* Category Selection */}
        <Typography variant="body1" fontWeight="bold">
          {t("SELECT_CATEGORY")}{" "}
          <span
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            *
          </span>
        </Typography>
        <NestedCategorySelect
          initialBreadcrumbs={formik.values.breadcrumbs}
          handleSelectBreadcrumbs={handleSelectBreadcrumbs}
          handleSelectCategory={handleSelectCategory}
        />
        {formik.touched.selectedCategory?.categoryId &&
          formik.errors.selectedCategory?.categoryId && (
            <FormHelperText error>
              {formik.errors.selectedCategory?.categoryId}
            </FormHelperText>
          )}

        {/* Country Selection */}
        <Typography variant="body1" fontWeight="bold">
          {t("SELECT_COUNTRY")}
          <span
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            *
          </span>
        </Typography>
        <CustomCountrySelect
          name="Country"
          icon={AppsIcon}
          isRequired={true}
          options={countries.map((item) => ({
            label: getLocalizedValue(item.name),
            value: item._id,
            currency: getLocalizedValue(item.currency),
            image: item.image,
          }))}
          multiSelect
          key="Country"
          isMultiSelect={false}
          onSelectChange={handleCountryChange}
          value={result}
        />
        {formik.touched.selectedCountry && formik.errors.selectedCountry && (
          <FormHelperText error>{formik.errors.selectedCountry}</FormHelperText>
        )}

        {/* Post Title */}
        <Typography variant="body1" fontWeight="bold">
          {t("POST_TITLE")}
          <span
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            *
          </span>
        </Typography>
        <CustomInput
          fullWidth
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.title && formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          margin="normal"
          placeholder={t("TYPE_TITLE_NAME")}
          sx={{
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
              fontWeight: "bold",
            },
            "& .MuiOutlinedInput-root": {
              border:'2px solid gray',
              borderRadius:'6px',
              marginTop:0,
            },
     
          }}
        />

        {/* Price Input */}
        <Typography variant="body1" fontWeight="bold">
          {t("PRICE")}
          <span
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            *
          </span>
        </Typography>
        <CustomInput
          fullWidth
          type="number"
          name="price"
          value={formik.values.price}
          onChange={formik.handleChange}
          error={Boolean(formik.touched.price && formik.errors.price)}
          helperText={formik.touched.price && formik.errors.price}
          slots={{
            endAdornment: () => (
              <Typography>{formik.values.currency}</Typography>
            ),
          }}
          inputProps={{ min: 1 }} // Disable negative numbers and restrict min to 1
          InputProps={{
            inputMode: "numeric",
            // Disable the step arrows (increment/decrement buttons)
            inputProps: { min: 1 },
            endAdornment: <Typography>{formik.values.currency}</Typography>,
          }}
          margin="normal"
          placeholder="0"
          sx={{
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
              fontWeight: "bold",
            },
          "& .MuiOutlinedInput-root": {
              border:'2px solid gray',
              borderRadius:'6px',
              marginTop:0,
            },
          }}
        />

        {/* Description */}
        <Typography variant="body1" fontWeight="bold">
          {t("DESCRIPTION")}
          <span
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            *
          </span>
        </Typography>
        <CustomInput
          fullWidth
          name="description"
          value={formik.values.description}
          onChange={formik.handleChange}
          error={Boolean(
            formik.touched.description && formik.errors.description
          )}
          helperText={formik.touched.description && formik.errors.description}
          multiline
          rows={4}
          margin="normal"
          placeholder={t("ENTER_TEXT")}
          sx={{
            "& .MuiInputBase-input::placeholder": {
              fontSize: "14px",
              fontWeight: "bold",
            },
           "& .MuiOutlinedInput-root": {
              border:'2px solid gray',
              borderRadius:'6px',
              marginTop:0,
            },
          }}
        />

        {/* Location Selection */}
        <Typography variant="body1" fontWeight="bold">
          {t("SELECT_LOCATION")}
          <span
            style={{
              color: "red",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            *
          </span>
        </Typography>

        <MapComponent
          handleLocationChange={handleLocationChange}
          initialAddress={formik.values.location.address}
          initialLocationName={formik.values.location.locationName}
          initialMarkerPosition={formik.values.location.markerPosition}
          formik={formik}
        />

        {/* Submit Button */}
     
      </Box>
      </Paper>
      <Paper elevation={0}  style={{padding:3,marginTop:'10px'}} >
      <Box display="flex" justifyContent="center" sx={{padding:'10px'}}>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={formik.handleSubmit}
          >
            {t("NEXT")}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default AdInfo;
