import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDashboardCardCount,
  selectDashboardError,
  selectDashboardRecentAds,
  selectDashboardReports,
  selectDashboardTopCustomers,
  setDashboardCardCount,
  setDashboardRecentAds,
  setDashboardReports,
  setDashboardTopCustomers,
} from "../store/dashboardSlice";
import { dashboardService } from "../services/dashboard.api";

const useDashboard = () => {
  const dispatch = useDispatch();
  const dashboardReports = useSelector(selectDashboardReports);
  const topCustomers = useSelector(selectDashboardTopCustomers);
  const recentAds = useSelector(selectDashboardRecentAds);
  const cardCounts = useSelector(selectDashboardCardCount);
  const error = useSelector(selectDashboardError);
  const [loading, setLoading] = useState(false);

  const getDashboardCardData = async () => {
    setLoading(true);
    const data = await dashboardService.fetchCounts();
    if (data.success) {
      dispatch(setDashboardCardCount(data));
    }
    setLoading(false);
    return data;
  };

  const getDashboardTopCustomer = async () => {
    setLoading(true);
    const data = await dashboardService.fetchTopCustomers();
    if (data.success) {
      dispatch(setDashboardTopCustomers(data));
    }
    setLoading(false);
    return data;
  };

  const getDashboardRecentAds = async () => {
    setLoading(true);
    const data = await dashboardService.fetchRecentAds();
    if (data.success) {
      dispatch(setDashboardRecentAds(data));
    }
    setLoading(false);
    return data;
  };

  const getDashboardReports = async (payload) => {
    setLoading(true);
    const data = await dashboardService.fetchReports(payload);
    if (data.success) {
      dispatch(setDashboardReports(data));
    }
    setLoading(false);
    return data;
  };

  return {
    getDashboardTopCustomer,
    getDashboardRecentAds,
    getDashboardCardData,
    getDashboardReports,
    dashboardReports,
    topCustomers,
    cardCounts,
    recentAds,
    loading,
    error,
  };
};

export default useDashboard;
