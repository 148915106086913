import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCategories,
  selectCategoryError,
  setCategories,
  setCategory,
} from "../store/categorySlice";
import { categoryService } from "../services/category.api";

const useCategory = () => {
  const dispatch = useDispatch();
  const categories = useSelector(selectCategories);
  const category = useSelector((state) => state.category.category);
  const error = useSelector(selectCategoryError);
  const [loading, setLoading] = useState(false);

  const fetchCategories = async (payload) => {
    setLoading(true);
    const data = await categoryService.fetchCategories(payload);
    if (data.success) {
      dispatch(setCategories(data));
    }
    setLoading(false);
    return data;
  };

  const getDropdownCategoryList = async () => {
    const data = await categoryService.getDropdownCategoryList();
    return data;
  };

  const createCategory = async (payload) => {
    setLoading(true);
    const data = await categoryService.createCategory(payload);
    setLoading(false);
    return data;
  };

  const updateCategory = async (id, payload) => {
    setLoading(true);
    const data = await categoryService.updateCategory(id, payload);
    setLoading(false);
    return data;
  };

  const deleteCategory = async (payload) => {
    setLoading(true);
    const data = await categoryService.deleteCategory(payload);
    setLoading(false);
    return data;
  };

  const getCategoryById = async (payload) => {
    setLoading(true);
    const data = await categoryService.getCategoryById(payload);
    if (data.success) {
      dispatch(setCategory(data));
    }
    setLoading(false);
    return data;
  };
  const getSubCategories = async (id) => {
    const data = await categoryService.getSubCategories(id);
    return data;
  };

  const updateSubCategory = async (payload) => {
    const data = await categoryService.updateSubCategory(payload);
    return data;
  };

  const clearCategory = () => {
    dispatch(setCategory(null));
  };

  return {
    fetchCategories,
    getDropdownCategoryList,
    categories,
    category,
    getCategoryById,
    createCategory,
    updateCategory,
    deleteCategory,
    clearCategory,
    getSubCategories,
    updateSubCategory,
    loading,
    error,
  };
};

export default useCategory;
