import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Paper, Typography, useTheme } from "@mui/material";
import React from "react";

function CustomPageHeader({ heading = "", path='', buttontext='' }) {
  const theme = useTheme()
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: "0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          width: "100%",
        }}
      >

        <Box
          sx={{
            flex: 1,
            padding: 2,
            maxWidth: { xs: "100%", md: "33%" },
          }}
        >
          {
            buttontext && (<Button
              startIcon={<ArrowBack style={{transform: theme.direction === "rtl" ? "rotate(180deg)" : "none",
                transition: "transform 0.3s ease",}} />}
              sx={{ fontWeight: 'bold', color: '#F06B5B',   textTransform: "inherit",
                // Adjust icon margin based on direction
                '& .MuiButton-startIcon': {
                  marginLeft: theme.direction === 'rtl' ? '8px' : '0px',
                  marginRight: theme.direction === 'ltr' ? '8px' : '0px',
                },
              }}
              href={path}
             
            >
              {buttontext}
            </Button>)
          }
          
        </Box>
        <Box
          sx={{
            flex: 1,
            maxWidth: { xs: "100%", md: "33%" },
            textAlign: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >

          <Typography variant="h5" fontWeight='bold' sx={{ ml: 2, color: '#F06B5B' }}>
            {heading}
          </Typography>
        </Box>


        <Box
          sx={{
            flex: 1,
            padding: 2,
            maxWidth: { xs: "100%", md: "33%" },
            textAlign: "center",
          }}
        ></Box>
      </Box>
    </Paper>
  );
}

export default CustomPageHeader;
