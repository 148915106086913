import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  Button,
  Avatar,
  useTheme,
  useMediaQuery,
  Skeleton,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import AdPreviewMap from "../../components/common/AdPreviewMap";
import { useParams } from "react-router-dom";
import useAd from "../../hooks/useAd";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../utils/formatHelper";
import ImageSlider from "../../components/common/ImageSlider";
import ImageCarousel from "../../components/common/ImageCarousel";
import useSpecification from "../../hooks/useSpecifications";
const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

function generateObjectFromArray(data) {
  const result = {};

  data?.forEach((item) => {
    // Check if the item is not deleted
    if (!item.deleted) {
      // Replace spaces with underscores in the key derived from name.en
      const key = item.name.en.replace(/\s+/g, "_");
      // Use the iconImage as the value
      result[key] = item.iconImage;
    }
  });

  return result;
}
function AdDetails() {
  const theme = useTheme();
  const { t } = useTranslation();
  const { getSpecificationsList } = useSpecification();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { Id } = useParams();
  const { getSelectedAdDetails } = useAd();
  const [loading, setLoading] = useState(false);
  const [adDetails, setAdDetails] = useState({});
  const [specImages, setSpecImages] = useState({});

  const fetchAdDetails = async (Id) => {
    setLoading(true);
    try {
      // Fetch both details and logs in parallel
      const detailsResponse = await getSelectedAdDetails(Id);

      // Handle the responses
      if (detailsResponse?.success) {
        setAdDetails(detailsResponse?.data);
      } else {
        console.error("Failed to fetch ad details:", detailsResponse?.message);
      }
    } catch (error) {
      console.error("Error fetching ad details or logs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Id) {
      fetchAdDetails(Id);
      getSpecificationsList().then((res) => {
        if (res.success) {
          const images = generateObjectFromArray(res?.data?.specifications);
          setSpecImages(images);
        }
      });
    }
  }, [Id]);

  if (loading) {
    return <AdDetailsSkeleton />;
  }

  return (
    <Box>
      <CustomPageHeader
        heading="Ad Details"
        buttontext="back to Ad Info"
        path={`/ad-info/${Id}`}
      />

      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 1.5,
          marginTop: "20px",
        }}
      >
        <Box sx={{ flex: 1 ,maxWidth:'380px', height:'auto' }}>
          <Paper elevation={0} sx={{ mb: 3 }}>
            <Box
              sx={{
                aspectRatio: 1 / 1,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {/* <ImageCarousel images={adDetails?.images || []} /> */}
              <ImageSlider images={adDetails?.images || []} />
            </Box>
          </Paper>
        </Box>

        <Box sx={{ flex: 2 }}>
          <Paper elevation={0} sx={{ p: 1.7, mb: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: 5,
              }}
            >
              <Box sx={{ flex: 2, padding: "2rem" }}>
                <Typography variant="h6">{adDetails?.title}</Typography>
                <Typography
                  variant="h6"
                  fontWeight="bold"
                  sx={{ color: "#F06B5B" }}
                >
                  {adDetails?.price} {adDetails?.currency}
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <LocationOn fontSize="small" sx={{ color: "#F06B5B" }} />
                  <Typography fontWeight="bold">
                    {adDetails?.country}
                  </Typography>
                </Box>
                <Typography fontWeight="bold">
                  {t("CATEGORY")}:{" "}
                  <span style={{ color: "#F06B5B" }}>
                    {getLocalizedValue(adDetails?.category?.name)}
                  </span>
                </Typography>
                <Typography color="text.secondary" fontWeight="bold">
                  ID: {adDetails?.soldId}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography fontWeight="bold" sx={{ color: "#F06B5B" }}>
                  {t("LOCATION")}
                </Typography>
                <Typography gutterBottom fontWeight="bold">
                  {adDetails?.country}
                </Typography>
                <Box sx={{ mb: 2, display: "flex", justifyContent: "center" }}>
                  {loading ? (
                    <Skeleton variant="rectangular" width={300} height={200} />
                  ) : (
                    <AdPreviewMap
                      apiKey={googleMapsApiKey}
                      latitude={
                        adDetails?.location?.markerPosition?.lat || 10.011145
                      }
                      longitude={
                        adDetails?.location?.markerPosition?.lng || 76.364964
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Paper>

          <Paper
            elevation={0}
            sx={{
              p: 3,
              mb: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar
                sx={{ width: 56, height: 56 }}
                src={adDetails?.postedBy?.profilePicture}
              >
                ZA
              </Avatar>
              <Box>
                <Typography variant="subtitle1">
                  {adDetails?.postedBy?.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  {t("MEMBER_SINCE")}:{" "}
                  {formatTimestamp(adDetails?.postedBy?.createdAt, false)}
                </Typography>
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#F06B5B",
                textTransform: "none",
                "&:hover": { bgcolor: "#e05a4a" },
              }}
            >
              {t("GO_TO_USER")}
            </Button>
          </Paper>
        </Box>
      </Box>
      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 2,
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Paper elevation={0} sx={{ p: 3, mb: 2 }}>
            <Typography
              variant="h6"
              sx={{ color: "#F06B5B", mb: 2, textAlign: "center" }}
            >
              {t("SPECIFICATIONS")}
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                gap: 2, // Add spacing between boxes
                paddingX: "3rem",
              }}
            >
              {adDetails?.specifications &&
                Object?.keys(adDetails?.specifications)?.map((key, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      py: 2, // Add padding inside each box
                      bgcolor: "rgba(240, 107, 91, 0.1)", // Background color for boxes
                      borderRadius: 1,
                      maxHeight: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        padding: ".5rem",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={specImages[key]}
                        alt="#"
                        style={{
                          height: "20px",
                          width: "30px",
                          objectFit: "contain",
                        }}
                      />
                    </Box>
                    <Typography variant="body2" fontWeight="bold">
                      {adDetails?.specifications[key]?.label ||
                        adDetails?.specifications[key]}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Paper>
        </Box>

        <Box sx={{ flex: 1 }}>
          {
           adDetails?.additionalDetails &&  Object?.keys(adDetails?.additionalDetails).length >0 && (
              <Paper elevation={0} sx={{ p: 3, mb: 2, paddingX: 8 }}>
              <Typography
                variant="h6"
                sx={{ color: "#F06B5B", mb: 2, textAlign: "center" }}
              >
                {t("ADDITIONAL_INFO")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  bgcolor: "rgba(240, 107, 91, 0.1)",
                }}
              >
                {adDetails?.additionalDetails &&
                  Object?.keys(adDetails?.additionalDetails)?.map(
                    (key, index, array) => (
                      <React.Fragment key={index}>
                        <Box
                          sx={{
                            px: 0.5,
                            py: 0.5,
                          }}
                        >
                          <Typography variant="body2" fontWeight="bold">
                            {key}
                          </Typography>
                        </Box>
                        {index < array.length - 1 && (
                          <Typography
                            variant="body2"
                            sx={{
                              mx: 1,
                              color: "primary.main",
                              fontWeight: "bold",
                            }}
                          >
                            •
                          </Typography>
                        )}
                      </React.Fragment>
                    )
                  )}
              </Box>
            </Paper>
            )
          }
       

          <Paper elevation={0} sx={{ p: 3, paddingX: 8 }}>
            <Typography
              variant="h6"
              sx={{ color: "#F06B5B", mb: 2, textAlign: "center" }}
            >
              {t("DESCRIPTION")}
            </Typography>
            <Box
              sx={{
                padding: ".5rem",
                bgcolor: "rgba(240, 107, 91, 0.1)",
                borderRadius: 1,
              }}
            >
              <Typography variant="body2" fontWeight="bold">
                {adDetails?.description}
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}
export default AdDetails;

function AdDetailsSkeleton() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme?.breakpoints.down("md"));

  return (
    <Box sx={{ maxWidth: 1200, mx: "auto", p: 3 }}>
      {/* Page Header Skeleton */}
      <Skeleton variant="rectangular" height={40} sx={{ mb: 3 }} />

      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 3,
          marginTop: "30px",
        }}
      >
        {/* Left Column */}
        <Box sx={{ flex: 1 }}>
          {/* Image Section */}
          <Paper sx={{ mb: 3, p: 2 }}>
            <Skeleton variant="rectangular" height={300} />
          </Paper>
        </Box>

        {/* Right Column */}
        <Box sx={{ flex: 2 }}>
          {/* Car Details */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Skeleton variant="text" height={40} width="60%" sx={{ mb: 2 }} />
            <Skeleton variant="text" height={30} width="40%" sx={{ mb: 2 }} />
            <Skeleton variant="text" height={20} width="80%" sx={{ mb: 1 }} />
            <Skeleton variant="text" height={20} width="50%" />
          </Paper>

          {/* User Profile */}
          <Paper
            sx={{
              p: 3,
              mb: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Skeleton variant="circular" width={56} height={56} />
              <Box>
                <Skeleton variant="text" height={20} width={100} />
                <Skeleton variant="text" height={15} width={150} />
              </Box>
            </Box>
            <Skeleton variant="rectangular" width={100} height={40} />
          </Paper>
        </Box>
      </Box>

      {/* Bottom Content */}
      <Box
        sx={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: 3,
          marginTop: "30px",
        }}
      >
        {/* Left Column */}
        <Box sx={{ flex: 1 }}>
          {/* Specifications */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Skeleton variant="text" height={30} width="30%" sx={{ mb: 2 }} />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(150px, 1fr))",
                gap: 2,
              }}
            >
              {[...Array(6)].map((_, index) => (
                <Skeleton key={index} variant="rectangular" height={50} />
              ))}
            </Box>
          </Paper>
        </Box>

        {/* Right Column */}
        <Box sx={{ flex: 1 }}>
          {/* Additional Info */}
          <Paper sx={{ p: 3, mb: 3 }}>
            <Skeleton variant="text" height={30} width="30%" sx={{ mb: 2 }} />
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
              {[...Array(6)].map((_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  width={100}
                  height={30}
                />
              ))}
            </Box>
          </Paper>

          {/* Description */}
          <Paper sx={{ p: 3 }}>
            <Skeleton variant="text" height={30} width="30%" sx={{ mb: 2 }} />
            <Skeleton variant="text" height={20} width="100%" sx={{ mb: 1 }} />
            <Skeleton variant="text" height={20} width="90%" sx={{ mb: 1 }} />
            <Skeleton variant="text" height={20} width="95%" />
          </Paper>
        </Box>
      </Box>
    </Box>
  );
}