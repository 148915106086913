import { useEffect } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import CustomTable from "../../components/common/CustomTable";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import usePaymentPlans from "../../hooks/usePaymentPlans";
import getLocalizedValue from "../../utils/getLocalizedValue";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { useDispatch } from "react-redux";
import i18n from "i18next";
import { setPayment } from "../../store/paymentPlanSlice";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const initialBackgroundColor = "#eb6953";

function PaymentPlansEditPage() {
  const { t } = useTranslation();
  const columns = [
    { id: "id", label: "", align: i18n.language == "en" ? "left" : "right" },
    {
      id: "name",
      label: t("LIST_ITEMS"),
      align: i18n.language == "en" ? "left" : "right",
    },
    { id: "validity", label: t("VALIDITY"), align: "center" },
    { id: "amount", label: t("AMOUNT"), align: "center" },
    { id: "publish", label: t("PUBLISH"), align: "center" },
    { id: "display_order", label: t("DISPLAY_ORDER"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { updated } = location.state || {};
  const {
    getPaymentPlanById,
    paymentPlan,
    loading,
    createPaymentPlan,
    updatePaymentPlan,
    deletePaymentPlans,
  } = usePaymentPlans();
  const [value, setValue] = React.useState(0);
  const [list, setList] = React.useState([]);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [displayData, setDisplayData] = React.useState([]); // Data limited to current page
  const [pagination, setPagination] = React.useState({
    page: 1,
    limit: 10,
    totalCount: 0,
    totalPages: 1,
  });

  const handleColorCodeChange = (event) => {
    dispatch(
      setPayment({
        data: {
          ...paymentPlan,
          titleBackgroundColor: event.target.value,
        },
      })
    );
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleDelete = (id) => {
    if (id) {
      if (!window.confirm("Are you sure you want to delete this?")) {
        return;
      }
      deletePaymentPlans({ ids: [id] }).then((res) => {
        if (res.success) {
          dispatch(setPayment({ data: {} }));
          navigate("/payment-plan");
        }
      });
    } else {
      alert("Payment plan id is missing!");
    }
  };
  const handleSave = () => {
    if (!paymentPlan?.paymentPlanName?.en) {
      alert("Payment Plan Name (English) is required.");
      return;
    }

    if (!paymentPlan?.items?.length > 0) {
      alert("Please add payment plan items.");
      return;
    }
    const sanitizedItems = paymentPlan?.items?.map((item) => {
      if (item?._id && item?._id?.startsWith("temp_")) {
        const { _id, deleted, createdAt, updatedAt, __v, ...rest } = item;
        return rest;
      }
      const { deleted, createdAt, updatedAt, __v, ...rest } = item;
      return rest;
    });

    const sanitizedPaymentPlan = {
      ...paymentPlan,
      titleBackgroundColor:
        paymentPlan?.titleBackgroundColor || initialBackgroundColor,
      items: sanitizedItems,
    };
    if (id) {
      const { _id, deleted, createdAt, updatedAt, __v, ...rest } =
        sanitizedPaymentPlan;
      updatePaymentPlan({ id, payload: rest }).then((res) => {
        if (res.success) {
          dispatch(setPayment({ data: {} }));
          navigate("/payment-plan");
        } else {
          alert(res.message || res.errorMessage || "something went wrong");
        }
      });
    } else {
      createPaymentPlan(sanitizedPaymentPlan).then((res) => {
        if (res.success) {
          dispatch(setPayment({ data: {} }));
          navigate("/payment-plan");
        } else {
          alert(res.message || res.errorMessage || "something went wrong");
        }
      });
    }
  };

  const handlePaginationChange = (newPage, newLimit) => {
    setPagination((prev) => ({
      ...prev,
      page: newPage,
      limit: newLimit,
      totalPages: Math.ceil(prev.totalCount / newLimit),
    }));
  };

  useEffect(() => {
    const startIndex = (pagination.page - 1) * pagination.limit;
    const endIndex = startIndex + pagination.limit;

    setDisplayData(list.slice(startIndex, endIndex));
  }, [list, pagination]);

  useEffect(() => {
    if (id && !updated) {
      getPaymentPlanById(id);
    }
  }, [id]);

  useEffect(() => {
    if (paymentPlan?.items?.length) {
      setList(
        paymentPlan?.items?.map((item, index) => ({
          id: (
            <Box sx={{ width: "25px" }}>
              <Checkbox
                checked={selectedIds.includes(item._id)}
                onChange={() => handleCheckboxChange(item._id)}
              />
            </Box>
          ),
          name: parse(
            getLocalizedValue(item?.description) || item?.description
          ),
          validity:
            i18n.language === "en"
              ? `${item.validityDays} days`
              : `${item.validityDays} أيام`,

          amount:
            i18n.language === "en" ? `${item.price} KWD` : `${item.price} د.ك`,
          publish: item?.isEnabled ? (
            <DoneIcon
              fontSize="small"
              sx={{ color: "green", fontWeight: "bold" }}
            />
          ) : (
            <CloseIcon
              fontSize="small"
              sx={{ color: "red", fontWeight: "bold" }}
            />
          ),
          display_order: index + 1,
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "inherit",
                padding: "0",
                minWidth: "35px",
                fontWeight: "bold",
              }}
              onClick={() =>
                navigate(
                  id
                    ? `/payment-plan/${id}/item/${item._id}`
                    : `/payment-plan/create/item/${item._id}`,
                  { state: { item } }
                )
              }
            >
              {t("EDIT")}
            </Button>
          ),
        }))
      );
      setPagination((prev) => ({
        ...prev,
        totalCount: paymentPlan?.items.length,
        totalPage: Math.ceil(paymentPlan?.items.length / prev.limit),
      }));
    } else if (paymentPlan?.items && paymentPlan?.items?.length === 0) {
      setPagination({
        page: 0,
        limit: 10,
        totalCount: 0,
        totalPages: 0,
      });
    }
  }, [paymentPlan, selectedIds]);

  return loading ? (
    <LoadingSkeleton />
  ) : (
    <Box sx={{ width: "100%" }}>
      <CustomPageHeader
        heading={
          paymentPlan.type === "PROMOTIONAL_PAYMENT_OPTION"
            ? t("ADD_PROMOTIONAL_PLAN_HEADER")
            : t("ADD_PAYMENT_PLAN_HEADER")
        }
        buttontext={t("BACK_TO_PAYMENT_PLAN_LIST")}
        path="/payment-plan"
      />

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
            pb={3}
          >
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={paymentPlan?.isEnabled === true}
                      onChange={() =>
                        dispatch(
                          setPayment({
                            data: {
                              ...paymentPlan,
                              isEnabled: true,
                            },
                          })
                        )
                      }
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("ENABLE")}
                  labelPlacement="end"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={paymentPlan?.isEnabled === false}
                      onChange={(e) =>
                        dispatch(
                          setPayment({
                            data: {
                              ...paymentPlan,
                              isEnabled: false,
                            },
                          })
                        )
                      }
                    />
                  }
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                  label={t("DISABLE")}
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>
          </Box>
        </Container>
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%" }}>
            <Button
              variant={value === 0 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              z
              onClick={() => handleChange(0)}
            >
              {t("ENGLISH")}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(1)}
            >
              {t("ARABIC")}
            </Button>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Typography variant="body1" fontWeight="bold">
              {t("PAYMENT_PLAN_NAME")}
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={paymentPlan?.paymentPlanName?.en}
              onChange={(event) => {
                const updatedEnValue = event.target.value;
                dispatch(
                  setPayment({
                    data: {
                      ...paymentPlan,
                      paymentPlanName: {
                        en: updatedEnValue,
                        ar: paymentPlan?.paymentPlanName?.ar,
                      },
                    },
                  })
                );
              }}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1} dir="rtl">
            <Typography variant="body1" fontWeight="bold">
              {t("PAYMENT_PLAN_NAME")}
            </Typography>
            <TextField
              fullWidth
              size="small"
              value={paymentPlan?.paymentPlanName?.ar}
              onChange={(event) => {
                const updatedArValue = event.target.value;
                dispatch(
                  setPayment({
                    data: {
                      ...paymentPlan,
                      paymentPlanName: {
                        ar: updatedArValue,
                        en: paymentPlan?.paymentPlanName?.en,
                      },
                    },
                  })
                );
              }}
            />
          </CustomTabPanel>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "inline-block",
              }}
            >
              <TextField
                value={
                  paymentPlan?.titleBackgroundColor || initialBackgroundColor
                }
                onChange={handleColorCodeChange}
                type="color"
                fullWidth
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  zIndex: -1,
                  opacity: 0,
                }}
                id="colorPicker"
              />
              <Typography variant="body2" fontWeight="bold">
                {t("TITEL_BACKGROUND_COLOR")}
              </Typography>
              <TextField
                value={
                  paymentPlan?.titleBackgroundColor || initialBackgroundColor
                }
                onClick={() => document.querySelector("#colorPicker").click()}
                size="small"
                readOnly
                style={{
                  cursor: "pointer",
                }}
              />
            </Box>
            <Box>
              <Typography variant="body2" fontWeight="bold">
                {t("COLOR")}
              </Typography>
              <Box
                sx={{
                  height: "38px",
                  width: "38px",
                  backgroundColor: `${
                    paymentPlan?.titleBackgroundColor || initialBackgroundColor
                  }`,
                  // borderRadius: "10px",
                }}
              ></Box>
            </Box>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px", p: 3 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t("PAYMENT_ITEMS")}
        </Typography>

        <CustomTable
          data={displayData || []}
          columns={columns}
          pagination={pagination}
          setPagination={({ page, limit }) =>
            handlePaginationChange(page, limit)
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              mx: "5px",
              textTransform: "inherit",
              minWidth: "150px",
              fontWeight: "bold",
            }}
            size="large"
            onClick={() =>
              navigate(
                !id ? "/payment-plan/create/item" : `/payment-plan/${id}/item`
              )
            }
          >
            {t("ADD_PAYMENT_ITEMS")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mx: "5px",
              textTransform: "inherit",
              minWidth: "150px",
              fontWeight: "bold",
            }}
            size="large"
            onClick={() => {
              if (selectedIds?.length) {
                if (
                  window.confirm(
                    `Are you sure you want to delete this ${
                      selectedIds.length > 1 ? "items" : "item"
                    }?`
                  )
                ) {
                  setList(
                    list.filter((item) => !selectedIds.includes(item.id))
                  );
                  dispatch(
                    setPayment({
                      data: {
                        ...paymentPlan,
                        items: paymentPlan.items.filter(
                          (item) => !selectedIds.includes(item._id)
                        ),
                      },
                    })
                  );
                }
              } else {
                alert("Please select at least one item to delete");
              }
            }}
          >
            {t("DELETE_PAYMENT_ITEMS")}
          </Button>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => handleSave()}
            >
              {id ? t("UPDATE") : t("SAVE")}
            </Button>
            {id && (
              <Button
                variant="contained"
                color="secondary"
                sx={{
                  mx: "5px",
                  textTransform: "inherit",
                  minWidth: "150px",
                  fontWeight: "bold",
                }}
                size="large"
                onClick={() => handleDelete(id)}
              >
                {t("DELETE_PAYMENT_PLAN")}
              </Button>
            )}
          </Box>
        </Container>
      </Paper>
    </Box>
  );
}

export default PaymentPlansEditPage;

const LoadingSkeleton = () => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* CustomPageHeader Skeleton */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Skeleton variant="text" width="60%" height={40} />
      </Paper>

      {/* Paper for checkboxes */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{ display: "flex", width: "100%", justifyContent: "center" }}
            pb={3}
          >
            <Skeleton
              variant="rectangular"
              width={100}
              height={40}
              sx={{ marginRight: 2 }}
            />
            <Skeleton variant="rectangular" width={100} height={40} />
          </Box>
        </Container>
      </Paper>

      {/* Paper for language buttons */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box sx={{ display: "flex", width: "100%" }}>
            <Skeleton
              variant="rectangular"
              width="50%"
              height={50}
              sx={{ marginRight: 1 }}
            />
            <Skeleton variant="rectangular" width="50%" height={50} />
          </Box>
        </Container>
      </Paper>

      {/* CustomTabPanel Skeleton for Payment Plan Name */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="body1" fontWeight="bold">
              <Skeleton width="50%" />
            </Typography>
            <Skeleton variant="rectangular" width="100%" height={40} />
          </Box>
        </Container>
      </Paper>

      {/* Paper for Color Picker */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 5,
            }}
          >
            <Box sx={{ position: "relative", display: "inline-block" }}>
              <Skeleton variant="rectangular" width={100} height={40} />
              <Skeleton variant="rectangular" width={100} height={40} />
            </Box>
            <Box>
              <Skeleton variant="circle" width={38} height={38} />
            </Box>
          </Box>
        </Container>
      </Paper>

      {/* Payment Items Table Skeleton */}
      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px", p: 3 }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          <Skeleton width="50%" />
        </Typography>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={200}
          sx={{ marginTop: 3 }}
        />
      </Paper>

      {/* Action Buttons Skeleton */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rectangular"
              width="150px"
              height={50}
              sx={{ marginRight: 2 }}
            />
            <Skeleton variant="rectangular" width="150px" height={50} />
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};
