import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  IconButton,
  TextField,
  Stack,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Delete, Edit, Add } from "@mui/icons-material";
import useBanner from "../../hooks/useBannerManagement";
import { useTranslation } from "react-i18next";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { Label } from "recharts";

const BannerManagement = () => {
  const { getBannerList, updateBanner, deleteBanner, addBanner, loading } =
    useBanner();
  const { t, i18n } = useTranslation(); // for translation
  const [banners, setBanners] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingBanner, setEditingBanner] = useState(null);
  const [newBanner, setNewBanner] = useState({
    bannerImage: "",
    redirectionUrl: "",
    redirectionType: "internal",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const handleAddBanner = () => {
    if (
      !newBanner.bannerImage ||
      !newBanner.redirectionUrl ||
      !newBanner.redirectionType
    ) {
      setSnackbarMessage(t("ERROR_REQUIRED_FIELDS"));
      setSnackbarOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append("bannerImage", newBanner.bannerImage);
    formData.append("redirectionUrl", newBanner.redirectionUrl);
    formData.append("redirectionType", newBanner.redirectionType);

    addBanner(formData)
      .then((res) => {
        if (res.success) {
          setBanners((prev) => [...prev, res.data?.banner]);
          setSnackbarMessage(t("SUCCESS_ADD_BANNER"));
          setSnackbarOpen(true);
          setOpenDialog(false);
          setNewBanner({
            bannerImage: "",
            redirectionUrl: "",
            redirectionType: "external",
          });
        } else {
          setSnackbarMessage(t("FAIL_ADD_BANNER"));
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error adding banner:", error);
        setSnackbarMessage(t("ERROR_ADD_BANNER"));
        setSnackbarOpen(true);
      });
  };

  const handleEditBanner = () => {
    if (
      !newBanner.bannerImage ||
      !newBanner.redirectionUrl ||
      !newBanner.redirectionType
    ) {
      setSnackbarMessage(t("ERROR_REQUIRED_FIELDS"));
      setSnackbarOpen(true);
      return;
    }
    const formData = new FormData();
    formData.append("bannerImage", newBanner.bannerImage);
    formData.append("redirectionUrl", newBanner.redirectionUrl);
    formData.append("redirectionType", newBanner.redirectionType);
    updateBanner(editingBanner._id, formData)
      .then((res) => {
        if (res.success) {
          setBanners((prev) => {
            return prev.map((banner) =>
              banner._id === res.data._id ? { ...banner, ...res.data } : banner
            );
          });
          setSnackbarMessage(t("SUCCESS_UPDATE_BANNER"));
          setSnackbarOpen(true);
          setOpenDialog(false);
          setEditingBanner(null);
          setNewBanner({
            bannerImage: "",
            redirectionUrl: "",
            redirectionType: "external",
          });
        } else {
          setSnackbarMessage(t("FAIL_UPDATE_BANNER"));
          setSnackbarOpen(true);
        }
      })
      .catch((error) => {
        console.error("Error updating banner:", error);
        setSnackbarMessage(t("ERROR_UPDATE_BANNER"));
        setSnackbarOpen(true);
      });
  };

  const handleDeleteBanner = (id) => {
    if (window.confirm(t("CONFIRM_DELETE_BANNER"))) {
      deleteBanner(id).then((res) => {
        if (res.success) {
          setBanners((prev) => prev.filter((banner) => banner._id !== id));
          setSnackbarMessage(t("SUCCESS_DELETE_BANNER"));
          setSnackbarOpen(true);
        }
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files;
    if (file) {
      setNewBanner({ ...newBanner, bannerImage: file[0] });
    }
  };

  const handleDialogOpen = (banner) => {
    setEditingBanner(banner);
    setNewBanner(
      banner || {
        bannerImage: "",
        redirectionUrl: "",
        redirectionType: "external",
      }
    );
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setEditingBanner(null);
    setNewBanner({
      bannerImage: "",
      redirectionUrl: "",
      redirectionType: "external",
    });
    setOpenDialog(false);
  };

  useEffect(() => {
    getBannerList().then((res) => {
      if (res.success) {
        setBanners(res.data?.banners);
      }
    });
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <CustomPageHeader
        heading={t("BANNER_MANAGEMENT")}
        buttontext={t("BACK_TO_SETTINGS")}
        path="/settings"
      />
      <Box sx={{ marginTop: "20px", textAlign: "end" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleDialogOpen(null)}
          startIcon={<Add />}
          sx={{
            marginBottom: 2,
            textTransform: "none",
            fontWeight: "bold",
            "& .MuiButton-startIcon": {
              marginRight: i18n.dir() === "rtl" ? "0px" : "8px",
              marginLeft: i18n.dir() === "rtl" ? "8px" : "0px",
            },
          }}
        >
          {t("ADD_BANNER")}
        </Button>

        {loading ? (
          <div
            style={{
              minHeight: "250px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={24} />
          </div>
        ) : (
          <Grid container spacing={2}>
            {banners?.map((banner) => (
              <Grid item xs={12} sm={6} md={4} key={banner.id}>
                <Card sx={{ display: "flex", flexDirection: "column" }}>
                  <img
                    src={banner.bannerImage}
                    alt="Banner"
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: "cover",
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6">
                      {banner.redirectionUrl}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {t("TYPE")}: {banner.redirectionType}
                    </Typography>
                  </CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      p: 2,
                    }}
                  >
                    <IconButton onClick={() => handleDialogOpen(banner)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteBanner(banner._id)}>
                      <Delete />
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}

        {/* Add/Edit Banner Dialog */}
        <Dialog open={openDialog} onClose={handleDialogClose} fullWidth>
          <DialogTitle
            variant="h5"
            color="primary"
            textAlign="center"
            fontWeight="bold"
          >
            {editingBanner ? t("EDIT_BANNER") : t("ADD_BANNER")}
          </DialogTitle>
          <DialogContent>
            <div style={{ marginTop: 16 }}>
              <Button
                variant="outlined"
                component="label"
                fullWidth
                sx={{ marginBottom: 2 }}
              >
                {t("UPLOAD_BANNER_IMAGE")}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileChange}
                />
              </Button>
              {newBanner.bannerImage && (
                <img
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "10px",
                    objectFit: "contain",
                    marginBottom: 4,
                  }}
                  src={
                    typeof newBanner.bannerImage === "string"
                      ? newBanner.bannerImage
                      : URL.createObjectURL(newBanner.bannerImage)
                  }
                />
              )}
            </div>
            <Typography variant="body2" fontWeight="medium">
              {t("REDIRECTION_URL")}
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              value={newBanner.redirectionUrl}
              onChange={(e) =>
                setNewBanner({ ...newBanner, redirectionUrl: e.target.value })
              }
              sx={{ marginBottom: 2 }}
            />
            <Typography variant="body2" fontWeight="medium">
              {t("REDIRECTION_TYPE")}
            </Typography>
            <Select
              variant="outlined"
              fullWidth
              value={newBanner.redirectionType}
              onChange={(e) =>
                setNewBanner({ ...newBanner, redirectionType: e.target.value })
              }
              sx={{ marginBottom: 2 }}
            >
              <MenuItem value="internal">{t("INTERNAL")}</MenuItem>
              <MenuItem value="external">{t("EXTERNAL")}</MenuItem>
            </Select>
          </DialogContent>
          <DialogActions sx={{ gap: 1 }}>
            <Button
              sx={{ fontWeight: "bold", textTransform: "none" }}
              onClick={handleDialogClose}
              color="secondary"
              variant="outlined"
            >
              {t("CANCEL")}
            </Button>
            <Button
              onClick={editingBanner ? handleEditBanner : handleAddBanner}
              color="primary"
              variant="contained"
              sx={{ fontWeight: "bold", textTransform: "none" }}
            >
              {editingBanner ? t("UPDATE") : t("ADD")}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for success messages */}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
        />
      </Box>
    </Box>
  );
};

export default BannerManagement;
