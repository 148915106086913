import { useNavigate } from "react-router-dom";
import { Box, Button, Paper, Typography } from "@mui/material";
import React from "react";
import CustomPageHeader from "../common/CustomPageHeader";
import { useTranslation } from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function CreatePostSuccessPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Box sx={{ bgcolor: "#f5f5f5", minHeight: "100vh" }}>
      <CustomPageHeader
        heading={t("CREATE_ADVERTISEMENT")}
        buttontext={t("BACK_TO_AD_LIST")}
        path="/ads"
      />
      <Paper
        elevation={0}
        sx={{
          marginTop: "20px",
          p: 3,
          bgcolor: "#fff",
          borderRadius: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box>
          <Box
            sx={{
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckCircleIcon sx={{ fontSize: 100 }} color="primary" />
          </Box>
          <Typography
            variant="h5"
            sx={{
              mt: 2,
              textAlign: "center",
              color: "primary.main",
              fontWeight: "bold",
            }}
          >
            {t("CONGRATULATIONS")}
          </Typography>
          <Typography
            variant="body2"
            sx={{ mt: 2, textAlign: "center", fontWeight: "bold" }}
          >
            {t("YOU_SUCCESSFULLY_PUBLISHED_YOUR_AD")}
          </Typography>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          marginTop: "20px",
          p: 3,
          bgcolor: "#fff",
          borderRadius: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Button
          sx={{ textTransform: "none", fontWeight: "bold", minWidth: "150px" }}
          variant="contained"
          onClick={() => navigate("/ads")}
        >
          {t("CONTINUE")}
        </Button>
      </Paper>
    </Box>
  );
}

export default CreatePostSuccessPage;
