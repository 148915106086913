import * as Yup from "yup";

export const signUpValidationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  countryCode: Yup.string().required("Phone number is required"),
  mobile: Yup.string()
  .required("Phone number is required")
  .matches(/^[0-9]+$/, "Phone number must be a valid number") 
  .min(7, "Phone number must be at least 7 digits") 
  .max(11, "Phone number must be at most 11 digits"), 
  // password: Yup.string()
  //   .min(6, "Password must be at least 6 characters")
  //   .required("Password is required"),
  // confirmPassword: Yup.string()
  //   .oneOf([Yup.ref("password"), null], "Passwords must match")
  //   .required("Confirm password is required"),
});

export const loginValidationSchema = Yup.object().shape({
  countryCode: Yup.string().required("Phone number is required"),
  mobile: Yup.string()
    .required("Phone number is required")
    .matches(/^[0-9]+$/, "Phone number must be a valid number") 
    .min(7, "Phone number must be at least 7 digits") 
    .max(11, "Phone number must be at most 11 digits"), 
  password: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required"),
});

export const forgotValidationSchema = Yup.object().shape({
  countryCode: Yup.string().required("Phone number is required"),
  mobile: Yup.string()
  .required("Phone number is required")
  .matches(/^[0-9]+$/, "Phone number must be a valid number") 
  .min(7, "Phone number must be at least 7 digits") 
  .max(11, "Phone number must be at most 11 digits"), 
});
