import React from "react";
import {
  Slider,
  Box,
  Typography,
  Grid,
  FormControl,
  TextField,
} from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";

const CustomInputRange = ({
  name,
  icon: Icon,
  isRequired,
  onInputChange,
  value,
  range
}) => {
  const handleChange = (event, newValue) => {
    const numericValue = Number(event.target.value); // Explicitly convert to number
    if (numericValue >= range.min && numericValue <= range.max) {
        onInputChange(numericValue); // Pass the numeric value
    }
};

  return (
    <Box
      sx={{
        backgroundColor: "white",
        cursor: "pointer",
        p: 1,
        borderRadius: "10px",
        my: 1,
        border:'2px solid gray',
      }}
    >
      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {Icon && <Icon color="primary" />}
            <Typography variant="body1" fontWeight="bold">
              {name}
              {isRequired && (
                <Typography component="span" color="red">
                  *
                </Typography>
              )}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField size="small" type="number" 
            onChange={(e)=>handleChange(e)}
            value={value} fullWidth />
          </FormControl>
        </Grid>
        {/* <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField  
            size="small" 
            type="number" 
            onChange={(e)=>handleChange('max',e)}   
            value={value?.max}
            fullWidth />
          </FormControl>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default CustomInputRange;
