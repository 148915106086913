import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectUserError,
  selectUserItems,
  setUser,
  setUserItems,
} from "../store/userSlice";
import { userService } from "../services/user.api";

const useUser = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectUserItems);
  const user = useSelector(selectUser);
  const error = useSelector(selectUserError);
  const [loading, setLoading] = useState(false);

  const getUserList = async (payload) => {
    setLoading(true);
    const data = await userService.fetchUsers(payload);
    if (data.success) {
      dispatch(setUserItems(data));
    }
    setLoading(false);
    return data;
  };

  const getUserById = async (id) => {
    setLoading(true);
    const data = await userService.fetchUserById(id);
    if (data.success) {
      dispatch(setUser(data));
    }
    setLoading(false);
    return data;
  };

  const createUser = async (payload) => {
    // setLoading(true);
    const data = await userService.createUser(payload);
    // setLoading(false);
    return data;
  };

  const deleteUsers = async (payload) => {
    setLoading(true);
    const data = await userService.deleteUsers(payload);
    setLoading(false);
    return data;
  };

  const updateUser = async (id, payload) => {
    setLoading(true);
    const data = await userService.updateUser(id, payload);
    setLoading(false);
    return data;
  };

  return {
    getUserList,
    users,
    getUserById,
    deleteUsers,
    updateUser,
    createUser,
    user,
    loading,
    error,
  };
};

export default useUser;
