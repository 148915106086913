import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { adService } from "../services/ad.api";
import { selectAd, selectAdError, selectAdItems, setAd, setAdItems } from "../store/adSlice";

const useAd = () => {
  const dispatch = useDispatch();
  const adList = useSelector(selectAdItems);
  const ad = useSelector(selectAd);
  const error = useSelector(selectAdError);
  const [loading, setLoading] = useState(false);
  const [historyList, setHistoryList] = useState([]);

  const getAdList = async (payload) => {
    setLoading(true);
    const data = await adService.fetchAds(payload);
    if (data.success) {
      dispatch(setAdItems(data));
    }
    setLoading(false);
    return data;
  };

  const getAdById = async (id) => {
    setLoading(true);
    const data = await adService.fetchSpecificationById(id);
    if (data.success) {
      dispatch(setAd(data));
    }
    setLoading(false);
    return data;
  };

  const createAd = async (payload) => {
    setLoading(true);
    const data = await adService.createAd(payload);
    setLoading(false);
    return data;
  };

  const updateAd = async (id, payload) => {
    setLoading(true);
    const data = await adService.updateAd(id, payload);
    setLoading(false);
    return data;
  };

  const updateAdStatus = async (id, payload) => {
    setLoading(true);
    const data = await adService.updateAdStatus(id, payload);
    if (data.success) {
      dispatch(setAd(data));
    }
    setLoading(false);
    return data;
  };

  const deleteAd = async (payload) => {
    setLoading(true);
    const data = await adService.deleteAds(payload);
    setLoading(false);
    return data;
  };

  const getSelectedAdDetails = async (id)=>{
    const data = await adService.getSelectedAdDetails(id);
    if (data.success) {
      dispatch(setAd(data));
    }
    return data;
  }

  const getSelectedAdLog = async (id)=>{
    const data = await adService.getSelectedAdLog(id);
    return data;
  }
  const getSelectedAdHistory = async (payload)=>{
    setLoading(true);
    const data = await adService.getSelectedAdHistory(payload);
    if(data.success){
      setHistoryList(data?.data)
    }
    setLoading(false);
    return data;
  }

  return {
    getAdList,
    adList,
    createAd,
    getAdById,
    updateAd,
    deleteAd,
    ad,
    loading,
    error,
    historyList,
    getSelectedAdDetails,
    getSelectedAdLog,
    getSelectedAdHistory,
    updateAdStatus
  };
};

export default useAd;
