import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useTranslation } from "react-i18next";

export default function CustomeMultiSelectDialog({
  open = false,
  setOpen,
  title = "",
  selectedOptions = [],
  options = [],
  handleAddItems,
  isMultiple = true,
  loading = false,
}) {
  const { t } = useTranslation();
  const [value, setValue] = useState(selectedOptions);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValue([...new Set([...selectedOptions])]);
  }, [selectedOptions]);

  const handleChange = (event, newValue, reason) => {
    if (reason === "clear") {
      setValue([]);
    } else {
      setValue(
        newValue.filter(
          (option, index, self) =>
            index === self.findIndex((o) => o._id === option._id)
        )
      );
    }
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          textAlign="center"
          variant="h5"
          fontWeight="bold"
          color="primary"
        >
          {`${t("SELECT")} ${title}`}
        </DialogTitle>
        <DialogContent style={{ height: "300px" }}>
          <Autocomplete
            multiple={isMultiple}
            limitTags={3}
            id="fixed-tags-demo"
            size="small"
            value={value}
            loading={loading}
            onChange={handleChange}
            options={options}
            getOptionLabel={(option) => getLocalizedValue(option.name)}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={getLocalizedValue(option.name)}
                    {...tagProps}
                  />
                );
              })
            }
            style={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} placeholder={t("SELECT_ITEM")} />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              textTransform: "none",
              fontWeight: "bold",
            }}
            onClick={() => {
              setValue([]);
              handleClose();
            }}
            fontWeight="bold"
          >
            {t("CLEAR")}
          </Button>
          <Button
            sx={{
              textTransform: "none",
              fontWeight: "bold",
            }}
            variant="contained"
            color="primary"
            onClick={() => handleAddItems(value, title)}
          >
            {t("ADD_ITEMS")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
