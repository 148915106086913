import {
  Box,
  Button,
  Checkbox,
  Container,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useReport from "../../hooks/useReport";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../../utils/formatHelper";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import i18n from "i18next";

function Reports() {
  const navigate = useNavigate();
  const { getReportList, reports, deleteReports, loading } = useReport();
  const [search, setSearch] = useState("");
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({
    page: reports?.page || 1,
    limit: reports?.limit || 10,
  });
  const { t } = useTranslation();
  // Columns definition
  const columns = [
    { id: "_id", label: "", align: i18n.language == "en" ? "left" : "right" },
    {
      id: "reportedBy",
      label: t("REPORTED_BY"),
      align: i18n.language == "en" ? "left" : "right",
    },
    { id: "date", label: t("DATE"), align: "center" },
    { id: "reportedOn", label: t("REPORTED_ON"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];
  const [pageData, setPageData] = useState({
    totalCount: reports?.totalCount || 0,
    totalPages: reports?.totalPages || 0,
  });
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  const handleDelete = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one user to delete.");
      return;
    }
    const message =
      selectedIds.length > 1
        ? "Are you sure you want to delete these reports?"
        : "Are you sure you want to delete this report?";
    if (window.confirm(message)) {
      deleteReports({ ids: [...selectedIds] })
        .then((res) => {
          if (res.success) {
            alert("Reports deleted successfully!");
            setSelectedIds([]);
            const payload = {
              page: 1,
              limit: pagination?.limit,
              search: "",
            };
            getReportList(payload);
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {
          alert(
            `Error deleting reports: ${err.message || "Something went wrong"}`
          );
        });
    }
  };

  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
    };
    getReportList(payload);
  };

  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: "",
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getReportList(payload);
  }, [pagination?.page, pagination?.limit, filter]);
  useEffect(() => {
    if (reports?.reports?.length > 0) {
      const items = reports?.reports;
      setData(
        items?.map((item) => ({
          _id: (
            <Box sx={{ maxWidth: "15px" }}>
              <Checkbox
                checked={selectedIds.includes(item._id)}
                onChange={() => handleCheckboxChange(item._id)}
              />
            </Box>
          ),
          reportedBy: item?.reportedBy?.name,
          date: formatTimestamp(item.createdAt, false),
          reportedOn: item.reportType,
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "inherit",
                padding: "0",
                minWidth: "45px",
                fontWeight: "bold",
              }}
              onClick={() => {
                navigate(`/report/${item?._id}`);
              }}
            >
              {t("VIEW")}
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: reports?.totalCount || 0,
      totalPages: reports?.totalPages || 0,
    });
  }, [reports, selectedIds]);
  return (
    <>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t("REPORTS")}
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => handleDelete()}
            >
              {t("DELETE_REPORTS")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              placeholder="Search Reported By"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                fontWeight: "bold",
                textTransform: "inherit",
                minWidth: "150px",
              }}
              size="medium"
              onClick={handleSearch}
            >
              {t("SEARCH")}
            </Button>
          </Box>
        </Container>
        <Container maxWidth="md">
          <DateFilter setFilter={setFilter} />
        </Container>
      </Paper>
      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </>
  );
}

function DateFilter({ setFilter }) {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { t } = useTranslation();

  const handleFilter = () => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (fromDate && toDate) {
      if (endDate < startDate) {
        alert("End date must be greater than start date.");
        return;
      }
    }

    const payload = {
      startDate: fromDate ? format(new Date(startDate), "yyyy-MM-dd") : null,
      endDate: toDate ? format(new Date(endDate), "yyyy-MM-dd") : null,
    };

    setFilter(payload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="center"
        gap={2}
        p={2}
        sx={{
          margin: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="body2" fontWeight="bold" textAlign='center'>
            {t("START_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="body2" fontWeight="bold" textAlign='center'>
            {t("END_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          fullWidth
          sx={{ maxWidth: { sm: 120 }, fontWeight: "bold" }}
        >
          {t("FILTER")}
        </Button>
      </Box>
    </LocalizationProvider>
  );
}

export default Reports;
