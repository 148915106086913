import React from "react";
import Slider from "react-slick";
import { Box, Skeleton } from "@mui/material";
import "./ImageSlider.css";

const ImageSlider = ({ images = [], loading = false }) => {
  const settings = {
    dots: images.length > 1, // Show dots only if there are more than 1 image
    infinite: images.length > 1, // Disable infinite scrolling if only 1 image
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // for tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // for mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box className="slider-container" sx={{ aspectRatio: "1/1", px: 2 }}>
      {loading ? (
        <Box
          className="slider-item"
          sx={{ width: "100%", height: "100%", aspectRatio: "1/1" }}
        >
          <Skeleton
            className="slider-image"
            variant="rectangular"
            width="100%"
            height="100%"
          />
        </Box>
      ) : (
        <Slider {...settings}>
          {images?.map((image, index) => (
            <Box
              key={index}
              className="slider-item"
              sx={{
                aspectRatio: "1/1",
                backgroundColor: "white",
                border: "none",
                outline: "none",
              }}
            >
              <img
                src={image}
                alt={`Slide ${index}`}
                onError={(e) => {
                  e.target.onerror = null; // Prevents infinite loop if placeholder also fails
                  e.target.src = "https://via.placeholder.com/150"; // Fallback to placeholder if image fails to load
                }}
                className="slider-image"
                style={{ objectFit: "contain" }}
              />
            </Box>
          ))}
        </Slider>
      )}
    </Box>
  );
};

export default ImageSlider;
