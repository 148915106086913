import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Typography,
  Paper,
  Stack,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { ArrowBack, CalendarMonth } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { useTranslation } from "react-i18next";
import getLocalizedValue from "../../utils/getLocalizedValue";
import useAd from "../../hooks/useAd";
import { useParams } from "react-router-dom";

const options = [
  { value: "Pending", label: { en: "Pending", ar: "معلق" } },
  { value: "Active", label: { en: "Active", ar: "نشط" } },
  { value: "Inactive", label: { en: "Inactive", ar: "غير نشط" } },
  { value: "Sold", label: { en: "Sold", ar: "مباع" } },
  { value: "Archived", label: { en: "Archived", ar: "أرشف" } },
];

export default function UpdateStatus() {
  const { t } = useTranslation();
  const { Id } = useParams();

  const { ad, getSelectedAdDetails, updateAdStatus, updateAd } = useAd();
  const [status, setStatus] = useState("Pending");
  const [timeFrameType, setTimeFrameType] = useState("userNumber");
  const [isRepostChecked, setIsRepostChecked] = useState(false);
  const [data, setData] = useState();

  const handleCheckboxChange = (event) => {
    setIsRepostChecked(event.target.checked);
  };

  const handleUpdateStatus = () => {
    if (status === ad.status) {
      alert(`Status already ${status}`);
      return;
    } else {
      updateAdStatus(Id, { status: status }).then((res) => {
        if (res.success) {
          setStatus(res?.data?.status);
          setData(res?.data);
          alert("Ad status updated successfully");
        } else {
          alert("Something went wrong");
        }
      });
    }
  };
  const publishAdHandler = () => {
    const payload = {
      ...data,
      advertisementTimeFrame: {
        ...(timeFrameType === "userNumber"
          ? { validityDays: data.advertisementTimeFrame?.validityDays }
          : {
              startDate: data.advertisementTimeFrame?.startDate,
              endDate: data.advertisementTimeFrame?.endDate,
            }),
      },
    };
    if (Id) {
      updateAd(Id, payload)
        .then((response) => {
          if (response.success) {
            alert("Ad updated successfully");
            setData(response?.data);
          } else {
            alert("Something went wrong");
          }
        })
        .catch((error) => {
          console.error("Error updating ad:", error);
          alert(error?.message || "Something went wrong");
        });
    } else {
      alert("Ad ID is missing. Cannot update ad.");
    }
  };
  useEffect(() => {
    if (!ad?.status && Id) {
      getSelectedAdDetails(Id).then((res) => {
        if (res.success) {
          setStatus(res?.data?.status);
          setData(res?.data);
        }
      });
    }
    if (ad) {
      setStatus(ad?.status);
      setData(ad);
    }
  }, [Id]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box sx={{ mx: "auto" }}>
        {/* Header */}
        <CustomPageHeader
          heading={t("UPDATE_STATUS")}
          buttontext={t("BACK_TO_AD_INFO")}
          path="/ads"
        />

        <Stack spacing={2} style={{ marginTop: "15px" }}>
          {/* Status Selection */}
          <Paper
            elevation={0}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              p: 3,
            }}
          >
            <FormControl sx={{ minWidth: 200, mb: 2 }}>
              <Select
                value={status}
                size="small"
                onChange={(e) => setStatus(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#F06B5B",
                  },
                }}
              >
                {options.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    disabled={item.value === "Pending"}
                  >
                    {getLocalizedValue(item.label)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#F06B5B",
                textTransform: "none",
                "&:hover": { bgcolor: "#e05a4a" },
                width: 150,
              }}
              onClick={handleUpdateStatus}
            >
              {t("UPDATE")}
            </Button>
          </Paper>

          {/* Re-Post/Extend Ad Section */}
          <Paper
            elevation={0}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRepostChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label={t("REPOST/EXTEND_AD")}
                sx={{
                  color: "primary.main",
                  marginBottom: "1rem",
                  "& .MuiFormControlLabel-label": {
                    fontWeight: "bold",
                    fontSize: "20px",
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!data?.advertisementSettings?.enableStandardAds}
                    onChange={() =>
                      setData({
                        ...data,
                        advertisementSettings: {
                          ...data.advertisementSettings,
                          enableStandardAds:
                            !data.advertisementSettings.enableStandardAds,
                        },
                      })
                    }
                  />
                }
                label={t("ENABLE_AS_STANDARD_AD")}
                disabled={!isRepostChecked}
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !!data?.advertisementSettings?.enableCategoryFeaturedAds
                    }
                    onChange={() =>
                      setData({
                        ...data,
                        advertisementSettings: {
                          ...data.advertisementSettings,
                          enableCategoryFeaturedAds:
                            !data.advertisementSettings
                              .enableCategoryFeaturedAds,
                        },
                      })
                    }
                  />
                }
                label={t("ENABLE_AS_CATEGORY_FEATURED_AD")}
                disabled={!isRepostChecked}
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !!data?.advertisementSettings?.enableHomepageFeaturedAds
                    }
                    onChange={() =>
                      setData({
                        ...data,
                        advertisementSettings: {
                          ...data.advertisementSettings,
                          enableHomepageFeaturedAds:
                            !data.advertisementSettings
                              .enableHomepageFeaturedAds,
                        },
                      })
                    }
                  />
                }
                label={t("ENABLE_AS_HOME_FEATURED_AD")}
                disabled={!isRepostChecked}
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      !!data?.advertisementSettings?.enableMainHomepageAds
                    }
                    onChange={() =>
                      setData({
                        ...data,
                        advertisementSettings: {
                          ...data.advertisementSettings,
                          enableMainHomepageAds:
                            !data.advertisementSettings.enableMainHomepageAds,
                        },
                      })
                    }
                  />
                }
                label={t("ENABLE_AS_MAIN_HOME_PAGE_AD")}
                disabled={!isRepostChecked}
                sx={{
                  "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                }}
              />
            </Stack>

            <Typography
              sx={{
                mt: 3,
                mb: 2,
                color: !isRepostChecked ? "#9e9e9e" : "primary.main",
                fontWeight: "bold",
              }}
            >
              {t("SELECT_ADVERTISEMENT_TIME_FRAME")}
            </Typography>

            <RadioGroup
              value={timeFrameType}
              onChange={(e) => setTimeFrameType(e.target.value)}
              sx={{ width: "100%" }}
            >
              <Box
                display="flex"
                justifyContent="center"
                gap="300px"
                width="100%"
              >
                <FormControlLabel
                  value="userNumber"
                  control={<Radio />}
                  label={t("FIXED_NUMBER_OF_DAYS")}
                  disabled={!isRepostChecked}
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                />
                <FormControlLabel
                  value="extendedNumber"
                  control={<Radio />}
                  label={t("EXTENDED_NUMBER_OF_DAYS")}
                  disabled={!isRepostChecked}
                  sx={{
                    "& .MuiFormControlLabel-label": { fontWeight: "bold" },
                  }}
                />
              </Box>
            </RadioGroup>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                gap: "200px",
                mt: 3,
                width: "100%",
              }}
              gap={3}
            >
              <Box>
                <Typography
                  sx={{
                    mb: "0px",
                    fontWeight: "bold",
                    color:
                      !isRepostChecked || timeFrameType === "extendedNumber"
                        ? "#9e9e9e"
                        : "black",
                  }}
                >
                  {t("ENTER_VALIDITY")}
                </Typography>
                <TextField
                  fullWidth
                  type="number"
                  inputProps={{ min: 0 }}
                  value={data?.advertisementTimeFrame?.validityDays}
                  onChange={(e) =>
                    setData({
                      ...data,
                      advertisementTimeFrame: {
                        ...data.advertisementTimeFrame,
                        validityDays: Number(e.target.value),
                      },
                    })
                  }
                  disabled={
                    !isRepostChecked || timeFrameType === "extendedNumber"
                  }
                />
              </Box>
              <Stack spacing={1}>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      mb: "0px",
                      minWidth: "90px",
                      fontWeight: "bold",
                      color:
                        !isRepostChecked || timeFrameType !== "extendedNumber"
                          ? "#9e9e9e"
                          : "black",
                    }}
                  >
                    {t("START_DATE")}
                  </Typography>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={
                      data?.advertisementTimeFrame?.startDate
                        ? new Date(data.advertisementTimeFrame.startDate)
                        : null
                    }
                    onChange={(newValue) =>
                      setData({
                        ...data,
                        advertisementTimeFrame: {
                          ...data.advertisementTimeFrame,
                          startDate: newValue ? newValue.toISOString() : null,
                        },
                      })
                    }
                    slots={{
                      openPickerIcon: CalendarMonth,
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    disabled={
                      !isRepostChecked || timeFrameType !== "extendedNumber"
                    }
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{
                      mb: "0px",
                      minWidth: "90px",
                      fontWeight: "bold",
                      color:
                        !isRepostChecked || timeFrameType !== "extendedNumber"
                          ? "#9e9e9e"
                          : "black",
                    }}
                  >
                    {t("END_DATE")}
                  </Typography>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={
                      data?.advertisementTimeFrame?.endDate
                        ? new Date(data.advertisementTimeFrame.endDate)
                        : null
                    }
                    onChange={(newValue) =>
                      setData({
                        ...data,
                        advertisementTimeFrame: {
                          ...data.advertisementTimeFrame,
                          endDate: newValue ? newValue.toISOString() : null,
                        },
                      })
                    }
                    slots={{
                      openPickerIcon: CalendarMonth,
                    }}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                      },
                    }}
                    disabled={
                      !isRepostChecked || timeFrameType !== "extendedNumber"
                    }
                  />
                </Box>
              </Stack>
            </Box>
          </Paper>

          {/* Publish Button */}
          <Paper sx={{ p: 2 }} elevation={0}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                disabled={!isRepostChecked}
                onClick={publishAdHandler}
                sx={{
                  bgcolor: "#F06B5B",
                  "&:hover": { bgcolor: "#e05a4a" },
                  width: 200,
                  fontWeight: "bold",
                  textTransform: "none",
                }}
              >
                {t("PUBLISH_AD")}
              </Button>
            </Box>
          </Paper>
        </Stack>
      </Box>
    </LocalizationProvider>
  );
}
