import React, { useEffect, useState } from 'react'
import CustomPageHeader from '../../components/common/CustomPageHeader'
import { Box, Button, Container, Paper, Stack, TextField, Typography } from '@mui/material'
import CustomTabPanel from '../../components/common/CustomTabPanel'
import useSettings from '../../hooks/useSettings';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AddAFAQ() {
    const [value, setValue] = useState(0);
    const {id} = useParams()
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [faq, setFaq] = useState({ question: { en: '', ar: "" }, answer: { en: '', ar: "" } })
    const { createFaq,getFaq,updateFaq } = useSettings()
    const navigate = useNavigate()

    const handleInputChnage = (event, field, lang) => {
        const value = event.target.value;

        setFaq(prevFaq => ({
            ...prevFaq, // Copy the existing state
            [field]: {
                ...prevFaq[field], // Copy the existing inner object
                [lang]: value, // Update the specific language field
            },
        }));
    };

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const handleSubmit = async () => {
        if (!faq.question.en.trim() || !faq.answer.en.trim()) {
            alert('Please provide at least an English question and answer.');
            return;
        }

        try {
            setLoading(true)
            let respose
            if(id){
                respose = await updateFaq(id,faq)
            }else{
                respose = await createFaq(faq)
            }
            if(respose.success){
                setLoading(false)
                navigate('/settings/faq')
            }
            setLoading(false)

        } catch (error) {
            setLoading(false)
            console.error(error)
        }
    }
    const fetchFAQ =async(id) =>{

        try {
            const response = await getFaq(id)
            if(response.success){
                const {data} = response
                setFaq({question:data.question,answer:data.answer})
            }
        } catch (error) {
            console.error(error)
        }   
    }
    useEffect(()=>{
        if(id){
            fetchFAQ(id)
        }
    },[id])


    return (
        <div>
            <CustomPageHeader heading={id ? t('EDIT_A_FAQ')  :t('ADD_A_FAQ')} buttontext='back to FAQ' path='/settings/faq' />
            <Paper elevation={0} style={{ padding: '50px', marginTop: '20px' }}>
                <Container maxWidth="sm">
                    <Box style={{ display: "flex", width: "100%" }}>
                        <Button
                            variant={value === 0 ? "contained" : "text"}
                            style={{
                                borderRadius: "0px",
                                width: "50%",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                            onClick={() => handleChange(0)}
                        >
                            English
                        </Button>
                        <Button
                            variant={value === 1 ? "contained" : "text"}
                            style={{
                                borderRadius: "0px",
                                width: "50%",
                                fontSize: "20px",
                                fontWeight: "bold",
                            }}
                            onClick={() => handleChange(1)}
                        >
                            Arabic
                        </Button>
                    </Box>
                </Container>
                <Container maxWidth="sm">
                    <CustomTabPanel value={value} index={0}>
                        <Stack>

                            <Typography variant="body1" fontWeight="bold">
                            {t('ENTER_QUESTION')}
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={5}
                                defaultValue="Default Value"
                                style={{ marginBottom: '20px' }}
                                value={faq.question.en}
                                onChange={(event) => handleInputChnage(event, 'question', 'en')}
                            />
                            <Typography variant="body1" fontWeight="bold">
                            {t('ENTER_ANSWER')}
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={5}
                                defaultValue="Default Value"
                                value={faq.answer.en}
                                onChange={(event) => handleInputChnage(event, 'answer', 'en')}
                            />
                        </Stack>

                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={1}>
                        <Stack>

                            <Typography variant="body1" fontWeight="bold" align="right">
                                
                                {t('ENTER_QUESTION')}
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={5}
                                defaultValue="Default Value"
                                style={{ marginBottom: "20px", textAlign: "right" }}
                                inputProps={{ style: { textAlign: "right" } }}
                                value={faq.question.ar}
                                onChange={(event) => handleInputChnage(event, 'question', 'ar')}

                            />
                            <Typography variant="body1" fontWeight="bold" align="right">
                                {t('ENTER_ANSWER')}
                            </Typography>
                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={5}
                                defaultValue="Default Value"
                                style={{ marginBottom: "20px", textAlign: "right" }}
                                inputProps={{ style: { textAlign: "right" } }}
                                value={faq.answer.ar}
                                onChange={(event) => handleInputChnage(event, 'answer', 'ar')}

                            />
                        </Stack>

                    </CustomTabPanel>
                </Container>
            </Paper>
            <Paper
                elevation={0}
                sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
            >
                <Container maxWidth="sm">
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="secondary"
                            sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
                            size="large"
                            onClick={() => window.history.back()}
                        >
                            {t('BACK')}
                            
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
                            size="large"
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {id ?t('UPDATE') :t('CREATE')}
                        </Button>
                    </Box>
                </Container>
            </Paper>

        </div>
    )
}

export default AddAFAQ