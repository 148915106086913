import api from "../utils/API";

export const adService = {
  createAd: async (payload) => {
    const response = await api.post("/admin/ad", payload);
    return response.data;
  },
  fetchAds: async (payload) => {
    const response = await api.get("/admin/ad", {
      params: payload,
    });
    return response.data;
  },
  getSelectedAdDetails: async (id) => {
    const response = await api.get(`/admin/ad/${id}`);
    return response.data;
  },
  getSelectedAdLog: async (id) => {
    const response = await api.get(`/log/count/${id}`);
    return response.data;
  },
  getSelectedAdHistory: async (payload) => {
    const response = await api.get(`/payment/history`, {
      params: payload, // Send as query parameters
    });
    return response.data;
  },
  updateAd: async (id, payload) => {
    const response = await api.put(`/admin/ad/${id}`, payload);
    return response.data;
  },
  updateAdStatus: async (id, payload) => {
    const response = await api.put(`/admin/ad/status/${id}`, payload);
    return response.data;
  },
  deleteAds: async (payload) => {
    const response = await api.delete("/admin/ad/delete", {
      data: payload,
    });
    return response?.data;
  },
};
