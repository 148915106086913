import { createSlice } from "@reduxjs/toolkit";

// Create a slice for categories
const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {
    reports: [],
    cardCount: [],
    topCustomers: [],
    recentAds: [],
    loading: false,
    error: null,
  },
  reducers: {
    setDashboardReports: (state, action) => {
      state.reports = action.payload.data || [];
      state.loading = false;
    },
    setDashboardCardCount: (state, action) => {
      state.cardCount = action.payload.data || [];
      state.loading = false;
    },
    setDashboardTopCustomers: (state, action) => {
      state.topCustomers = action.payload.data || [];
      state.loading = false;
    },
    setDashboardRecentAds: (state, action) => {
      state.recentAds = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the loading and error selectors if needed
export const selectDashboardLoading = (state) => state.dashboard.loading;
export const selectDashboardError = (state) => state.dashboard.error;
export const selectDashboardReports = (state) => state.dashboard.reports;
export const selectDashboardCardCount = (state) => state.dashboard.cardCount;
export const selectDashboardTopCustomers = (state) =>
  state.dashboard.topCustomers;
export const selectDashboardRecentAds = (state) => state.dashboard.recentAds;

// Export the reducer to include in the store
export const {
  setDashboardReports,
  setDashboardCardCount,
  setDashboardTopCustomers,
  setDashboardRecentAds,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
