import React, { useEffect, useState } from "react";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Paper,
  Typography,
  Skeleton,
} from "@mui/material";
import CustomTable from "../../components/common/CustomTable";
import useSettings from "../../hooks/useSettings";
import getLocalizedValue from "../../utils/getLocalizedValue";
import DoneIcon from "@mui/icons-material/Done";
import { useTranslation } from "react-i18next";

// Columns definition

function AdSettings() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const { deleteCountry } = useSettings();
  const [countries, setCountries] = useState({
    limit: 10,
    page: 1,
    totalCount: 3,
    totalPages: 1,
    countries: [],
  });

  const columns = [
    { id: "id", label: "", align: "left" },
    { id: "flags", label: t("FLAGS"), align: "left" },
    { id: "country", label: t("COUNTRIES"), align: "left" },
    { id: "publish", label: t("PUBLISH"), align: "left" },
    { id: "currency", label: t("CURRENCY"), align: "left" },
    { id: "phoneCode", label: t("PHONE_CODE"), align: "left" },
  ];

  const handleSelectCountry = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const deleteCountries = () => {
    if (selectedIds.length === 0) {
      alert("Please select at least one country to delete.");
      return;
    }
    const message =
      selectedIds.length > 1
        ? "Are you sure you want to delete these countries?"
        : "Are you sure you want to delete this country?";
    if (window.confirm(message)) {
      deleteCountry({ ids: [...selectedIds] })
        .then((res) => {
          if (res.success) {
            alert("Countries deleted successfully!");
            setSelectedIds([]);
            fetchInitialData();
          } else {
            alert(res.message);
          }
        })
        .catch((err) => {
          alert(
            `Error deleting countries: ${err.message || "Something went wrong"}`
          );
        });
    }
  };
  const [adSettings, setAdSettings] = useState({
    enableAutoApprovalForAds: false,
    enableReportingOfAdsandUsers: false,
  });
  const { getAllCountry, getAdSettings, updateAdSettings } = useSettings();

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const [countryListResponse, settingsResponse] = await Promise.all([
        getAllCountry(),
        getAdSettings(),
      ]);

      if (countryListResponse.success) {
        const { data } = countryListResponse;
        const countries = data?.countries?.map((country) => ({
          id: (
            <Checkbox
              checked={selectedIds.includes(country._id)}
              onChange={() => handleSelectCountry(country._id)}
            />
          ),
          flags: (
            <img
              src={country.image}
              alt="icon"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "https://via.placeholder.com/150";
              }}
              style={{ width: "40px" }}
            />
          ),
          country: getLocalizedValue(country.name),
          publish: <DoneIcon color="success" fontSize="large" />,
          currency: getLocalizedValue(country.currency),
          phoneCode: country.phoneCode,
        }));
        setCountries({ ...data, countries });
      }
      if (settingsResponse.success) {
        const { data } = settingsResponse;
        setAdSettings({
          enableAutoApprovalForAds: data.enableAutoApprovalForAds,
          enableReportingOfAdsandUsers: data.enableReportingOfAdsandUsers,
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching initial data:", error);
    }
  };

  const handleCheckboxChange = (event, field) => {
    setAdSettings((prevSettings) => ({
      ...prevSettings,
      [field]: event.target.checked,
    }));
  };

  const handleUpdateAdSettings = async () => {
    updateAdSettings(adSettings);
  };

  useEffect(() => {
    fetchInitialData();
    return () => {};
  }, [selectedIds]);

  return loading ? (
    <SettingsSkeleton />
  ) : (
    <div>
      <CustomPageHeader
        heading={t("AD_SETTINGS")}
        buttontext={t("BACK_TO_SETTINGS")}
        path="/settings"
      />

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "30px",
            }}
            color="primary"
          >
            {t("SELECT_COUNTRIES")}
          </Typography>
        </Container>
        <CustomTable
          data={countries.countries}
          columns={columns}
          hidePagination={true}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              mx: "5px",
              textTransform: "inherit",
              minWidth: "150px",
              fontWeight: "bold",
            }}
            size="large"
            onClick={deleteCountries}
          >
            {t("REMOVE")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{
              mx: "5px",
              textTransform: "inherit",
              minWidth: "150px",
              fontWeight: "bold",
            }}
            size="large"
            href={"/settings/country/create"}
          >
            {t("ADD_A_COUNTRY")}
          </Button>
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "30px",
            }}
            color="primary"
          >
            {t("SELECT_ADVERTISEMENT_SETTINGS")}
          </Typography>
        </Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={adSettings.enableAutoApprovalForAds}
                onChange={(event) =>
                  handleCheckboxChange(event, "enableAutoApprovalForAds")
                }
              />
            }
            label={t("ENABLE_AUTO_APPROVAL_FOR_ADS")}
            style={{ width: "33%" }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={adSettings.enableReportingOfAdsandUsers}
                onChange={(event) =>
                  handleCheckboxChange(event, "enableReportingOfAdsandUsers")
                }
              />
            }
            label={t("ENABLE_REPORTING_OF_ADS_AND_USERS")}
            style={{ width: "33%" }}
          />
        </Box>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => window.history.back()}
            >
              {t("BACK")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={handleUpdateAdSettings}
            >
              {t("UPDATE")}
            </Button>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}

export default AdSettings;

const SettingsSkeleton = () => {
  return (
    <div>
      {/* Skeleton for Page Header */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Skeleton variant="text" width="30%" height={40} />
      </Paper>

      {/* Skeleton for First Paper */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Skeleton variant="text" width="50%" height={40} sx={{ mb: 3 }} />
        </Container>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={150}
          sx={{ mb: 3 }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Skeleton
            variant="rectangular"
            width={150}
            height={50}
            sx={{ mx: 1 }}
          />
          <Skeleton
            variant="rectangular"
            width={150}
            height={50}
            sx={{ mx: 1 }}
          />
        </Box>
      </Paper>

      {/* Skeleton for Second Paper */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Skeleton variant="text" width="50%" height={40} sx={{ mb: 3 }} />
        </Container>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height={50}
            sx={{ mb: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height={50}
            sx={{ mb: 2 }}
          />
        </Box>
      </Paper>

      {/* Skeleton for Third Paper */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={150}
              height={50}
              sx={{ mx: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={150}
              height={50}
              sx={{ mx: 1 }}
            />
          </Box>
        </Container>
      </Paper>
    </div>
  );
};
