import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { useState, useEffect } from "react";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useTranslation } from "react-i18next";
import {
  Box,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// import { Box } from "@mui/system";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import TwoWheelerIcon from "@mui/icons-material/TwoWheeler";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import BuildIcon from "@mui/icons-material/Build";

export default function CustomeSelectDialog({
  open = false,
  setOpen,
  title = "",
  options = [],
  handleAddItems,
}) {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleSearch = () => {
    // Update and sort options only when the search button is clicked
    const updatedOptions = options
      .filter((option) =>
        getLocalizedValue(option.name)
          .toLowerCase()
          .includes(searchInput.toLowerCase())
      )
      .sort((a, b) =>
        getLocalizedValue(a.name).localeCompare(getLocalizedValue(b.name))
      );
    setFilteredOptions(updatedOptions);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogContent>
          <Box>
            <Sidebar options={options} handleAddItems={handleAddItems} />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

function Sidebar({ options, handleAddItems }) {
  const [searchTerm, setSearchTerm] = useState(""); // State for search input
  const [filteredCategories, setFilteredCategories] = useState(options); // State for filtered categories

  // Handle search input change
  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    if (value === "") {
      setFilteredCategories(options);
      return;
    }
  };

  // Handle search button click (optional, keeps same functionality as on input)
  const handleSearchClick = () => {
    const filtered = filteredCategories.filter((category) =>
      category.name.en.toLowerCase().includes(searchTerm)
    );
    setFilteredCategories(filtered);
  };

  return (
    <Box>
      {/* Search Bar */}
      <Box sx={{ display: "flex", gap: "8px", marginBottom: "16px" }}>
        <TextField
          variant="outlined"
          size="small"
          fullWidth
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearchChange} // Update state on input
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ textTransform: "none" }}
          onClick={handleSearchClick} // Optional: Filter on button click
        >
          Search
        </Button>
      </Box>

      {/* Categories List */}
      <List
        sx={{
          maxHeight: "400px", // Set max height for the scrollable list
          overflowY: "auto", // Enable vertical scroll
          "&::-webkit-scrollbar": {
            width: "8px", // Set the width of the scrollbar
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#eb6953", // Set the scrollbar thumb color
            borderRadius: "10px", // Add rounded corners to the scrollbar thumb
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#f0f0f0", // Set the scrollbar track color
          },
        }}
      >
        {filteredCategories.length > 0 ? (
          filteredCategories.map((category, index) => (
            <React.Fragment key={getLocalizedValue(category.name)}>
              <ListItem
                button
                sx={{
                  py: 1, // Reduce vertical padding for smaller row height
                  backgroundColor: index % 2 === 0 ? "#f7f7f7" : "#ffffff", // Alternating row colors
                }}
                onClick={() => handleAddItems(category)}
              >
                <ListItemIcon>
                  <img
                    style={{
                      height: "30px",
                      width: "40px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    src={
                      typeof category?.iconImage === "string"
                        ? category?.iconImage
                        : URL.createObjectURL(category?.iconImage)
                    }
                    alt="icon preview"
                  />
                </ListItemIcon>
                <ListItemText primary={getLocalizedValue(category.name)} />
              </ListItem>
            </React.Fragment>
          ))
        ) : (
          <ListItem
            sx={{
              py: 1, // Reduce vertical padding
              backgroundColor: "#ffffff", // Ensure consistent background for "No results"
            }}
          >
            <ListItemText primary="No results found" />
          </ListItem>
        )}
      </List>
    </Box>
  );
}
