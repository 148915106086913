import { createSlice } from "@reduxjs/toolkit";

// Create a slice for paymentPlan
const paymentPlanSlice = createSlice({
  name: "payment",
  initialState: {
    paymentPlanItems: [],
    payment: {
      isEnabled: true,
    },
    loading: false,
    error: null,
  },
  reducers: {
    setPaymentItems: (state, action) => {
      state.paymentPlanItems = action.payload.data || [];
      state.loading = false;
    },
    setPayment: (state, action) => {
      state.payment = action.payload.data || {};
      state.loading = false;
    },
  },
});

// Export the loading and error selectors if needed
export const selectPaymentLoading = (state) => state.payment.loading;
export const selectPaymentError = (state) => state.payment.error;
export const selectPaymentItems = (state) => state.payment.paymentPlanItems;
export const selectPayment = (state) => state.payment.payment;

// Export the reducer to include in the store
export const { setPayment, setPaymentItems } = paymentPlanSlice.actions;
export default paymentPlanSlice.reducer;
