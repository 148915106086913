import React from "react";
import {
  Pagination,
  Select,
  MenuItem,
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const PaginationSection = ({
  loading,
  totalItems,
  itemsPerPage,
  currentPage,
  totalPages,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
        alignItems: "center",
        justifyContent: { xs: "center", sm: "space-between" },
        gap: 2,
        mt: 3,
      }}
    >
      {/* Left-aligned on desktop, center-aligned on mobile */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          justifyContent: { xs: "center", sm: "flex-start" },
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={onPageChange}
          color="primary"
          sx={{
            "& .MuiPaginationItem-root": {
              borderRadius: "1px", // Make buttons square
              border: "1px solid rgba(0, 0, 0, 0.23)", // Add a border
              margin: 0,
              padding: 0,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.08)", // Hover effect
              },
            },
            "& .Mui-selected": {
              backgroundColor: "primary", // Selected background color
              color: "#fff", // Selected text color
              borderColor: "primary", // Border color for selected
            },
          }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 2,
          justifyContent: { xs: "center", sm: "flex-end" },
          width: { xs: "100%", sm: "auto" },
        }}
      >
        <>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {t("PAGINATION_SECTIONS.SHOW")}
          </Typography>
          <Select
            value={itemsPerPage}
            onChange={onItemsPerPageChange}
            variant="outlined"
            size="small"
            style={{ fontWeight: "bold" }}
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
          </Select>
          <Typography variant="body2" style={{ fontWeight: "bold" }}>
            {t("PAGINATION_SECTIONS.ITEMS")}
          </Typography>
        </>
      </Box>

      <Typography
        variant="body2"
        sx={{
          display: { xs: "none", sm: "block" },
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        {`${(currentPage - 1) * itemsPerPage + 1}-${Math.min(
          currentPage * itemsPerPage,
          totalItems
        )} ${t("PAGINATION_SECTIONS.OF")} ${totalItems} ${t(
          "PAGINATION_SECTIONS.ITEMS"
        )}`}
      </Typography>
    </Box>
  );
};

export default PaginationSection;
