import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authService } from "../services/auth.api";
import { loginSuccess, logout, setLoginDetiles } from "../store/authSlice";

const useAuth = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const { accessToken, user } = useSelector((state) => state.auth);

  const login = async (data) => {
    setLoading(true);
    try {
      dispatch(setLoginDetiles(data));
      const response = await authService.login(data);
      if (response.success) {
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        dispatch(
          loginSuccess({
            user: response.data.user,
            accessToken: response.data.accessToken,
            refreshToken: response.data.refreshToken,
          })
        );
      }
      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  const logoutUser = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    dispatch(logout());
  };

  const isAuthenticated = !!accessToken; // Check if accessToken exists

  const executeIfLoggedIn = (callback) => {
    if (isAuthenticated) {
      callback();
    } else {
      console.warn("User is not logged in. Access denied.");
      // Optionally, redirect the user to a login page or show a message
    }
  };

  const resetPassword = async (payload) => {
    setLoading(true);
    try {
      const response = await authService.resetPassword(payload);
      setLoading(false);
      return response;
    } catch (err) {
      setLoading(false);
      setError(err);
    }
  };

  return {
    login,
    logout: logoutUser,
    executeIfLoggedIn,
    loading,
    error,
    isAuthenticated,
    resetPassword,
    user,
    accessToken,
  };
};

export default useAuth;
