import React from 'react';
import { TextField, InputAdornment, Box, Grid, Typography, FormControl } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';

const CustomInputWithLabel = ({ name, icon: Icon, isRequired, onInputChange,value,inputProps }) => {
  
    const handleChange = (event, newValue) => {
        onInputChange(event.target.value);
    };
    return (
        <Box sx={{ backgroundColor: 'white', cursor: 'pointer', p: 1, borderRadius: '10px', my: 1,        border:'2px solid gray',
        }}>
        <Grid container justifyContent="space-between"  gap={1}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {Icon && <Icon color="primary" />}
                    <Typography variant="body1" fontWeight="bold">
                        {name}
                        {isRequired && (
                            <Typography component="span" color="red">
                                *
                            </Typography>
                        )}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField size='small' value={value} type={inputProps || 'text'} onChange={handleChange} fullWidth />
                </FormControl>
            </Grid>
        </Grid>
    </Box>
    );
};

export default CustomInputWithLabel;
