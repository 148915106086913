import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import AdminLoginForm from "../../components/AdminLogin/AdminLoginForm";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import FacebookIcon from "@mui/icons-material/Facebook";
import useConfig from "../../hooks/useConfig";
import useAuth from "../../hooks/useAuth";

const AdminLogin = () => {
  const theme = useTheme(); // Access the theme for colors
  const { config, fetchConfig } = useConfig();
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    fetchConfig();
    if (isAuthenticated) {
      window.location.href = "/dashboard";
    }
  }, []);
  return (
    <Box
      sx={{
        minHeight: "100vh",
        backgroundColor: theme.palette.primary.main,
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        padding: "1rem",
        color: "white",
      }}
    >
      {/* Logo  Heading and Form */}
      <AdminLoginForm />

      {/* Footer Text */}
      <Box
        component="footer"
        sx={{
          position: "absolute",
          bottom: 0,
          textAlign: "center",
          width: "100%",
          color: "white",
        }}
      >
        {/* <Box display="flex" justifyContent="center" gap="1rem" mb="1rem">
          <FacebookIcon />
          <InstagramIcon />
          <XIcon />
        </Box> */}
        <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }} mt={4}>
          {config?.socialMediaAccounts?.map((item) => {
            return (
              <Box component="a" href={item.url} target="_blank">
                {" "}
                <img src={item?.icon} width="30px" alt="sold" />
              </Box>
            );
          })}
        </Box>
        <Typography variant="body2" mb="1rem">
          © SOLD 2024. All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default AdminLogin;
