// src/components/EmptyChatPlaceholder.js
import { Box, Typography } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { useTranslation } from 'react-i18next';

export default function EmptyChatPlaceholder() {
  const {t} = useTranslation()
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'text.secondary',
        backgroundColor:'background.white',
        textAlign: 'center',
        padding: 4,
      }}
    >
      <ChatBubbleOutlineIcon sx={{ fontSize: 80, mb: 2 }} />
      <Typography variant="h6" gutterBottom>
        {t('NO_CHAT_SELECTED')}
      </Typography>
      <Typography variant="body2">
      {t('SELECT_CONVERSATION_PROMPT')}
      </Typography>
    </Box>
  );
}
