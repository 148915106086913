import api from "../utils/API";

// Base URL for your API
export const settingsService = {
  fetchSettigns: async (payload) => {
    const response = await api.get("/admin/settings", {
      params: payload,
    });
    return response.data;
  },
  updateSettigns: async (payload) => {
    const response = await api.put("/admin/settings", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  },

  fetchFaqlist: async () => {
    const response = await api.get("/admin/settings/faq");
    return response.data;
  },
  
  getFaq: async (id) => {
    const response = await api.get(`/admin/settings/faq/${id}`);
    return response.data;
  },

  createFaq: async (payload) =>{
    const response = await api.post("/admin/settings/faq",payload);
    return response.data;
  },

  updateFaq: async (id,payload) =>{
    const response = await api.put(`/admin/settings/faq/${id}`,payload);
    return response.data;
  },
  
  deleteFaq: async (id) =>{
    const response = await api.delete(`/admin/settings/faq/${id}`);
    return response.data;
  },

  getAdSettings: async () =>{
    const response = await api.get(`/admin/settings/ad`);
    return response.data;
  },
  updateAdSettings: async (payload) =>{
    const response = await api.put(`/admin/settings/ad`,payload);
    return response.data;
  },
  getAllCountry: async () =>{
    const response = await api.get(`/admin/country`);
    return response.data;
  },
  createCountry: async (payload) =>{
    const response = await api.post(`/admin/country`,payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  },
  deleteCountry: async (payload) =>{
    const response = await api.delete("/admin/country/delete", {data: payload});
    return response.data;
  },
};
