import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomTable from "../../components/common/CustomTable";
import useAd from "../../hooks/useAd";
import { formatTimestamp } from "../../utils/formatHelper";
import getLocalizedValue from "../../utils/getLocalizedValue";
import CustomPageHeader from "../../components/common/CustomPageHeader";

function PaymentHistory() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const columns = [
    { id: "_id", label: "", align: i18n.language == "en" ? "left" : "right" },
    {
      id: "adName",
      label: t("AD_NAME"),
      align: i18n.language == "en" ? "left" : "right",
    },
    { id: "date", label: t("DATE"), align: "left" },
    { id: "status", label: t("STATUS"), align: "center" },
    { id: "plan", label: t("PLAN"), align: "center" },
    { id: "amount", label: t("AMOUNT"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];
  const { getSelectedAdHistory, historyList, loading } = useAd();
  const [listToDisplay, setListToDisplay] = useState([]);
  const [pagination, setPagination] = useState({
    page: historyList?.page || 1,
    limit: historyList?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: historyList?.total || 0,
    totalPages: historyList?.totalPages || 0,
  });
  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      type: "all",
    };
    getSelectedAdHistory(payload);
  }, [pagination?.page, pagination?.limit]);
  useEffect(() => {
    (async () => {
      const { success, data } = await getSelectedAdHistory({
        type: "all",
      });
      if (success) {
        setPageData({
          totalCount: data?.total,
          totalPages: data?.totalPages,
        });
        setListToDisplay(
          data?.payments?.map((item) => ({
            ...item,
            adName: item?.adName,
            date: formatTimestamp(item?.date, false),
            status: (
              <span
                style={{
                  color:
                    item.status === "Paid"
                      ? "green"
                      : item.status === "Failed"
                      ? "red"
                      : item.status === "Pending"
                      ? "orange"
                      : "black",
                }}
              >
                {item?.status}
              </span>
            ),
            plan: getLocalizedValue(item?.plan),
            amount: item?.amount,
            action: (
              <Button
                size="small"
                variant="outlined"
                color="black"
                sx={{
                  padding: "2px 5px",
                  textTransform: "inherit",
                  minWidth: "45px",
                  fontWeight: "bold",
                }}
                onClick={() => navigate(`/ad-info/${item?.postId}`)} // Bind remove function here
              >
                {t("AD_INFO")}
              </Button>
            ),
          }))
        );
      }
    })();
  }, []);
  return (
    <div>
      <CustomPageHeader
        heading={t("PAYMENT_HISTORY")}
        buttontext={t("BACK_TO_SETTINGS")}
        path="/settings"
      />
      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={listToDisplay || []}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

export default PaymentHistory;
