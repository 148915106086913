import { useDispatch, useSelector } from "react-redux";
import {
  saveAllStepData,
  saveStepData,
  setCurrentStep,
} from "../store/createPostSlice";
import { createPostService } from "../services/createPost.api";
import { useImageContext } from "../utils/ImageContext";
import { adService } from "../services/ad.api";

const useCreatePost = () => {
  const dispatch = useDispatch();
  const postState = useSelector((state) => state.createPost);
  const { images = [] } = useImageContext() || {}; // Use the context

  const saveData = (step, data) => {
    dispatch(saveStepData({ step, data }));
  };
  const saveAllData = (data) => {
    dispatch(saveAllStepData(data));
  };

  const fetchAdDetails = async (id) => {
    try {
      let data = await adService.getSelectedAdDetails(id);
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const formatFetchedData = (data) => {
    const adInfo = {
      breadcrumbs: data?.breadcrumbs || [],
      selectedCategory: {
        categoryId: data?.category?.categoryId || "",
        name: {
          en: data?.category?.name.en || "",
          ar: data?.category?.name.ar || "",
        },
      },
      selectedCountry: data?.country || "",
      currency: data?.currency || "",
      title: data?.title || "",
      price: data?.price || "",
      description: data?.description || "",
      location: {
        locationName: data?.location?.locationName || "",
        address: data?.location?.address || {},
        markerPosition: {
          lat: data?.location?.markerPosition?.lat || -3.745,
          lng: data?.location?.markerPosition?.lng || -38.523,
        },
      },
    };

    const images = data?.images.map((item) => ({ src: item, file: {} })) || [];
    let specifications = {};
    if (data?.specifications && Object.keys(data?.specifications).length >= 0) {
      for (const [key, value] of Object.entries(data.specifications)) {
        const processedKey = key.replace(/_/g, " "); // Reverse underscores to spaces
        specifications[processedKey] = value;
      }
    }
    let optionalSpecifications = {};
    if (
      data?.optionalSpecifications &&
      Object.keys(data?.optionalSpecifications).length >= 0
    ) {
      for (const [key, value] of Object?.entries(
        data?.optionalSpecifications
      )) {
        const processedKey = key?.replace(/_/g, " "); // Reverse underscores to spaces
        optionalSpecifications[processedKey] = value;
      }
    }
    let additionalDetails = {};
    if (
      data?.additionalDetails &&
      Object.keys(data?.additionalDetails).length >= 0
    ) {
      for (const [key, value] of Object.entries(data.additionalDetails)) {
        const processedKey = key.replace(/_/g, " "); // Reverse underscores to spaces
        additionalDetails[processedKey] = value;
      }
    }

    const { advertisementSettings, advertisementTimeFrame } = data;

    if (advertisementTimeFrame.startDate) {
      advertisementTimeFrame.type = "Scheduled";
    }

    const formValue = {
      specification: specifications,
      optionalSpecifications: optionalSpecifications,
      additionalDetails: additionalDetails,
    };

    return {
      adInfo,
      images,
      formValue,
      advertisementSettings,
      advertisementTimeFrame,
    };
  };

  const ganerateFormData = () => {
    const formData = new FormData();

    const {
      adInfo,
      formValue,
      paymentPlan,
      advertisementTimeFrame,
      advertisementSettings,
    } = postState.stepData;
    // Ad Info
    formData.append("title", adInfo.title);
    formData.append("price", adInfo.price);
    formData.append("description", adInfo.description);
    formData.append("currency", adInfo.currency);

    // Selected Category - Add entire object
    formData.append("category[categoryId]", adInfo.selectedCategory.categoryId);
    formData.append("category[name][en]", adInfo.selectedCategory.name.en);
    formData.append("category[name][ar]", adInfo.selectedCategory.name.ar);
    formData.append("country", adInfo.selectedCountry);

    // Breadcrumbs
    adInfo.breadcrumbs.forEach((breadcrumb, index) => {
      formData.append(`breadcrumbs[${index}][_id]`, breadcrumb._id);
      formData.append(`breadcrumbs[${index}][name][en]`, breadcrumb.name.en);
      formData.append(`breadcrumbs[${index}][name][ar]`, breadcrumb.name.ar);
      formData.append(`breadcrumbs[${index}][iconImage]`, breadcrumb.iconImage);
    });

    // Location as object
    formData.append("location[locationName]", adInfo.location.locationName);
    formData.append(
      "location[markerPosition][lat]",
      adInfo.location.markerPosition.lat
    );
    formData.append(
      "location[markerPosition][lng]",
      adInfo.location.markerPosition.lng
    );

    // Address (as object with all fields)
    const { address } = adInfo.location;
    for (const [key, value] of Object.entries(address)) {
      formData.append(`location[address][${key}]`, value);
    }

    if (Object.keys(formValue.specification).length === 0) {
      // formData.append('specifications', '{}'); // Append empty object if no specifications
    } else {
      for (const [key, value] of Object.entries(formValue.specification)) {
        const processedKey = key.replace(/\s+/g, "_"); // Replace spaces with underscores
        if (typeof value === "string") {
          formData.append(`specifications[${processedKey}]`, value);
        } else {
          formData.append(
            `specifications[${processedKey}][label]`,
            value.label
          );
          formData.append(
            `specifications[${processedKey}][value]`,
            value.value
          );
        }
      }
    }

    // Optional Specifications
    if (Object.keys(formValue.optionalSpecifications).length === 0) {
      // formData.append('optionalSpecifications',null); // Append empty object if no optional specifications
    } else {
      for (const [key, value] of Object.entries(
        formValue.optionalSpecifications
      )) {
        const processedKey = key.replace(/\s+/g, "_"); // Replace spaces with underscores

        if (typeof value === "string") {
          formData.append(`optionalSpecifications[${processedKey}]`, value);
        } else {
          formData.append(
            `optionalSpecifications[${processedKey}][label]`,
            value.label
          );
          formData.append(
            `optionalSpecifications[${processedKey}][value]`,
            value.value
          );
        }
      }
    }

    // Additional Details
    if (Object.keys(formValue.additionalDetails).length === 0) {
      // formData.append('additionalDetails', null); // Append empty object if no additional details
    } else {
      for (const [key, value] of Object.entries(formValue.additionalDetails)) {
        const processedKey = key.replace(/\s+/g, "_"); // Replace spaces with underscores

        formData.append(`additionalDetails[${processedKey}]`, value);
      }
    }
    if (paymentPlan?._id) {
      formData.append("paymentPlanItemId", paymentPlan._id);
    }

    if (images.length > 0) {
      images?.forEach((fileDetails) => {
        if (fileDetails.src?.startsWith("http")) {
          formData.append("images[]", fileDetails.src);
        } else {
          formData.append("files", fileDetails.file);
        }
      });
    }

    // advertisementSettings
    if (Object.keys(advertisementSettings).length === 0) {
    } else {
      for (const [key, value] of Object.entries(advertisementSettings)) {
        formData.append(`advertisementSettings[${key}]`, value);
      }
    }

    if (advertisementTimeFrame.type === "Fixed") {
      formData.append(
        "advertisementTimeFrame[type]",
        advertisementTimeFrame.type
      );
      formData.append(
        "advertisementTimeFrame[validityDays]",
        advertisementTimeFrame.validityDays || 10
      );
    } else {
      formData.append(
        "advertisementTimeFrame[type]",
        advertisementTimeFrame.type
      );
      formData.append(
        "advertisementTimeFrame[startDate]",
        advertisementTimeFrame.startDate
      );
      formData.append(
        "advertisementTimeFrame[endDate]",
        advertisementTimeFrame.endDate
      );
    }

    return formData;
  };

  const createPost = async (formData) => {
    // const formData = ganerateFormData();
    const response = await createPostService.createPost(formData);
    return response.data;
  };

  const updatePost = async (id, formData) => {
    const response = await createPostService.updatePost(id, formData);
    return response.data;
  };

  const goToStep = (step) => {
    dispatch(setCurrentStep(step));
  };

  return {
    saveData,
    goToStep,
    createPost,
    updatePost,
    ganerateFormData,
    formatFetchedData,
    saveAllData,
    fetchAdDetails,
    ...postState,
  };
};

export default useCreatePost;
