import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Divider,
  IconButton,
  Collapse,
  Paper,
  Container,
  CircularProgress,
} from "@mui/material";
import {
  Add,
  SubdirectoryArrowRight,
  Delete,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import ToyotaIcon from "@mui/icons-material/DirectionsCar"; // Replace with your icons
import { useNavigate, useParams } from "react-router-dom";
import CustomeSelectDialog from "../../components/common/CustomeSelectDialog";
import getLocalizedValue from "../../utils/getLocalizedValue";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import useCategory from "../../hooks/useCategory";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";

const EditSubCategory = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categorieSet, setCategorieSet] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [openSubCategories, setOpenSubCategories] = useState({});

  const {
    getDropdownCategoryList,
    getCategoryById,
    getSubCategories,
    updateSubCategory,
  } = useCategory();

  const handleAddOrSubItem = (parentId) => {
    setParentId(parentId);
    setIsModalOpen(true);
  };
  const handleSave = async () => {
    const payload = categorieSet.map((item) => ({
      _id: item._id,
      parentId: item.parentId || null,
    }));
    const response = await updateSubCategory(payload);
    if (response.success) {
      alert(response.message);
      navigate(`/menu/${id}`);
    }
  };
  const handleAddCategory = (value) => {
    setCategorieSet((pre) => [...pre, { ...value, parentId: parentId }]);
    handleCollapseClick(parentId, false);
    setParentId(null);
    setIsModalOpen(false);
  };
  const handleDeleteCategory = (id) => {
    const updatedCategories = categorieSet.map((item) => {
      if (item._id === id) {
        item.parentId = null;
      }
      return item;
    });
    setCategorieSet(updatedCategories);
  };

  const handleCollapseClick = async (id, isOpen) => {
    if (!isOpen) {
      const response = await getSubCategories(id);
      if (response.success) {
        const categories = response.data.map((item) => {
          const { _id, name, iconImage } = item;
          return { _id, name, iconImage, parentId: id };
        });
        setCategorieSet((prev) => {
          const updatedCategories = [...prev, ...categories];
          // Separate categories into two arrays
          const categoriesWithoutParentId = updatedCategories.filter(
            (category) => category.parentId === null
          );
          const categoriesWithParentId = updatedCategories.filter(
            (category) => category.parentId !== null
          );

          // Remove duplicates based on _id for categoriesWithoutParentId
          const uniqueCategoriesWithoutParentId =
            categoriesWithoutParentId.reduce((acc, category) => {
              if (
                !acc.some(
                  (existingCategory) => existingCategory._id === category._id
                )
              ) {
                acc.push(category);
              }
              return acc;
            }, []);

          // Remove duplicates based on _id for categoriesWithParentId
          const uniqueCategoriesWithParentId = categoriesWithParentId.reduce(
            (acc, category) => {
              if (
                !acc.some(
                  (existingCategory) => existingCategory._id === category._id
                )
              ) {
                acc.push(category);
              }
              return acc;
            },
            []
          );

          return [
            ...uniqueCategoriesWithoutParentId,
            ...uniqueCategoriesWithParentId,
          ];
        });
      }
    }
    setOpenSubCategories((pre) => ({ ...pre, [id]: !isOpen }));
  };

  const fetchAll = async (id) => {
    try {
      setLoading(true);
      const [categoriesResponse, categoryByIdResponse] = await Promise.all([
        getDropdownCategoryList(),
        getCategoryById(id),
      ]);

      if (categoriesResponse.success) {
        let categoriesList = categoriesResponse.data;
        setCategoriesList(categoriesList || []);
      }

      if (categoryByIdResponse.success) {
        let { _id, name, iconImage } = categoryByIdResponse.data;
        setCategorieSet([{ _id, name, iconImage }]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAll(id);
    }
  }, [id]);

  const options = useMemo(() => {
    return categoriesList.filter((category) => {
      const isCategoryInSet = categorieSet.some((existingCategory) => {
        const isMatch =
          existingCategory._id === category._id &&
          (existingCategory.parentId !== null || "");
        return isMatch;
      });
      return !isCategoryInSet;
    });
  }, [categorieSet, categoriesList, handleDeleteCategory]);

  // Recursive function to render categorieSet and subcategorieSet
  const renderCategory = (category, level = 1, parentId = null) => {
    if (category.parentId === "") return null;
    const isOpen = openSubCategories[category._id] || false;

    return (
      <Box
        key={category.id}
        sx={{ ml: level < 5 ? 5 : 1, mt: 1, minWidth: "400px" }}
      >
        <ListItem
          sx={{
            backgroundColor: "#d4d4d4",
            py: 1,
            pl: 2,
            fontSize: "0.875rem",
          }}
        >
          <ListItemIcon>
            {category?.iconImage ? (
              <img
                style={{
                  height: "30px",
                  width: "40px",
                  borderRadius: "10px",
                  objectFit: "contain",
                }}
                src={
                  typeof category?.iconImage === "string"
                    ? category?.iconImage
                    : URL.createObjectURL(category?.iconImage)
                }
                alt="icon preview"
              />
            ) : (
              <ToyotaIcon color="error" />
            )}
          </ListItemIcon>
          <ListItemText primary={getLocalizedValue(category.name)} />
          {id !== category._id && (
            <Button
              color="black"
              startIcon={<Add color="primary" fontSize="20" />}
              onClick={() => handleAddOrSubItem(category.parentId)}
              style={{ textTransform: "initial", fontWeight: "bold" }}
              sx={{
                // Adjust icon margin based on direction
                "& .MuiButton-startIcon": {
                  marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                  marginRight: theme.direction === "ltr" ? "8px" : "0px",
                },
              }}
            >
              {t("ADD")}
            </Button>
          )}

          <Button
            color="black"
            startIcon={<SubdirectoryArrowRight color="primary" fontSize="20" />}
            onClick={() => handleAddOrSubItem(category._id)}
            style={{ textTransform: "initial", fontWeight: "bold" }}
            sx={{
              // Adjust icon margin based on direction
              "& .MuiButton-startIcon": {
                marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                marginRight: theme.direction === "ltr" ? "8px" : "0px",
              },
            }}
          >
            {t("SUB_ITEM")}
          </Button>
          {id !== category._id && (
            <Button
              color="black"
              startIcon={<Delete color="primary" fontSize="20" />}
              onClick={() => handleDeleteCategory(category._id)}
              style={{ textTransform: "initial", fontWeight: "bold" }}
              sx={{
                // Adjust icon margin based on direction
                "& .MuiButton-startIcon": {
                  marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                  marginRight: theme.direction === "ltr" ? "8px" : "0px",
                },
              }}
            >
              {t("DELETE")}
            </Button>
          )}

          <IconButton onClick={() => handleCollapseClick(category._id, isOpen)}>
            {isOpen ? (
              <ExpandLess color="primary" />
            ) : (
              <ExpandMore color="primary" />
            )}
          </IconButton>
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {categorieSet
              ?.filter((item) => item.parentId === category._id)
              ?.map((subCategory) =>
                renderCategory(subCategory, level + 1, category._id)
              )}
          </List>
        </Collapse>
      </Box>
    );
  };

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <CustomPageHeader
        heading={t("ADD_EDIT_SUB_CATEGORY_ITEMS")}
        buttontext={t("BACK_TO_ADD_EDIT_MENU_ITEM")}
        path={`/menu/${id}`}
      />
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", minHeight: "60vh" }}
      >
        <Box sx={{ p: 3 }}>
          <Typography
            variant="h6"
            fontWeight="bold"
            align="center"
            color="primary"
            gutterBottom
          >
            {t("SUB_CATEGORY_SELECTION")}
          </Typography>
          <Divider />
          {!loading ? (
            <List>
              {categorieSet.length === 0 && (
                <Box sx={{ ml: 5, mt: 1 }}>
                  <ListItem
                    sx={{
                      backgroundColor: "#d4d4d4",
                      py: 1,
                      pl: 2,
                      fontSize: "0.875rem",
                    }}
                  >
                    <ListItemText primary={t("SELECT_FROM_LIST")} />
                    <Button
                      color="black"
                      startIcon={<Add color="primary" fontSize="20" />}
                      onClick={() => handleAddOrSubItem(id)}
                      style={{ textTransform: "initial", fontWeight: "bold" }}
                      sx={{
                        // Adjust icon margin based on direction
                        "& .MuiButton-startIcon": {
                          marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                          marginRight:
                            theme.direction === "ltr" ? "8px" : "0px",
                        },
                      }}
                    >
                      {t("ADD")}
                    </Button>
                    <Button
                      color="black"
                      startIcon={
                        <SubdirectoryArrowRight color="primary" fontSize="20" />
                      }
                      onClick={() => handleAddOrSubItem(id)}
                      style={{ textTransform: "initial", fontWeight: "bold" }}
                      sx={{
                        // Adjust icon margin based on direction
                        "& .MuiButton-startIcon": {
                          marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                          marginRight:
                            theme.direction === "ltr" ? "8px" : "0px",
                        },
                      }}
                    >
                      {t("SUB_ITEM")}
                    </Button>

                    <IconButton>
                      <ExpandMore color="primary" />
                    </IconButton>
                  </ListItem>
                </Box>
              )}
              {/* {categorieSet.map((category) =>
                renderCategory(category, 0, category?._id)
              )} */}
              {categorieSet
                ?.filter((item) => item._id === id)
                ?.map((subCategory) =>
                  renderCategory(subCategory, 1, subCategory._id)
                )}
            </List>
          ) : (
            <div
              style={{
                minHeight: "250px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress size={24} />
            </div>
          )}
          {/* Modal for Adding/Sub Item */}
          <CustomeSelectDialog
            open={isModalOpen}
            setOpen={setIsModalOpen}
            options={options}
            handleAddItems={handleAddCategory}
            title={t("CATEGORY")}
          />
        </Box>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={handleSave}
            >
              {t("SAVE")}
            </Button>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};

export default EditSubCategory;
