import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import i18n from 'i18next';
import CustomTable from "../../components/common/CustomTable";
import getLocalizedValue from "../../utils/getLocalizedValue";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import useAdditionalDetails from "../../hooks/useAdditionalDetails";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";

function AdditionalDetails() {
  const { t } = useTranslation();
  // Columns definition
  const columns = [
    { id: "id", label: "", align: i18n.language == 'en' ? 'left': 'right' },
    { id: "name", label: t("ADDITIONAL_DETAILS_LIST"), align: i18n.language == 'en' ? 'left': 'right' },
    { id: "action", label: t("ACTION"), align: "center" },
  ];

  const {
    getAdditionalDetails,
    additionalDetails,
    loading,
    createAdditionalDetails,
    updateAdditionalDetails,
    deleteAdditionalDetails,
  } = useAdditionalDetails();

  const [data, setData] = useState([]);
  const [dialogTabValue, setDialogTabValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [pagination, setPagination] = useState({
    page: additionalDetails?.page || 1,
    limit: additionalDetails?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: additionalDetails?.totalCount,
    totalPages: additionalDetails?.totalPages,
  });
  const [search, setSearch] = useState("");
  const [item, setItem] = useState({});
  const [editId, setEditId] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
    };
    getAdditionalDetails(payload);
  };

  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: "",
    };
    getAdditionalDetails(payload);
  }, [pagination?.page, pagination?.limit]);

  useEffect(() => {
    if (additionalDetails?.additionalDetails?.length > 0) {
      setData(
        additionalDetails?.additionalDetails?.map((item) => ({
          id: (
            <Box sx={{ maxWidth: "10px" }}>
              <Checkbox
                checked={selectedIds.includes(item._id)}
                onChange={() => handleCheckboxChange(item._id)}
              />
            </Box>
          ),
          name: getLocalizedValue(item.name),
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "inherit",
                padding: "0",
                minWidth: "45px",
                fontWeight: "bold"
              }}
              onClick={() => {
                setItem(item.name);
                setEditId(item._id);
                setOpen(true);
              }}
            >
              {t('EDIT')}
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: additionalDetails?.totalCount || 0,
      totalPages: additionalDetails?.totalPages || 0,
    });
  }, [additionalDetails, selectedIds]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    if (!item.en || item.en.trim() === "") {
      alert("The English name (en) is required.");
      return;
    }
    if (item.en || item.ar) {
      const payload = {
        name: item,
      };
      setPagination({ ...pagination, page: 1 });
      if (editId) {
        await updateAdditionalDetails(editId, payload);
      } else {
        await createAdditionalDetails(payload);
      }
      const payload2 = {
        page: pagination?.page,
        limit: pagination?.limit,
        search: "",
      };
      getAdditionalDetails(payload2);
      setItem({});
      setOpen(false);
    }
  };

  const handleDelete = async () => {
    const payload = {
      ids: selectedIds,
    };
    await deleteAdditionalDetails(payload);
    setPagination({ ...pagination, page: 1 });
    const payload2 = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: "",
    };
    getAdditionalDetails(payload2);
    setSelectedIds([]);
  };
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t('ADDITIONAL_DETAILS_LIST')}
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={() => {
                setItem({ en: "", ar: "" });
                setOpen(true);
                setEditId(null);
              }}
            >
              {t('ADD_ITEM')}
            </Button>
            <Dialog
              open={open}
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle color="primary" align="center" fontWeight="bold">
                {`${editId ? t('EDIT') : t('ADD')} ${t('ADDITIONAL_DETAILS')}`}
              </DialogTitle>
              <DialogContent sx={{ width: "500px" }}>
                <Container>
                  <Box style={{ display: "flex", width: "100%" }}>
                    <Button
                      variant={dialogTabValue === 0 ? "contained" : "text"}
                      style={{
                        borderRadius: "0px",
                        width: "50%",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDialogTabValue(0)}
                    >
                      {t('ENGLISH')}
                    </Button>
                    <Button
                      variant={dialogTabValue === 1 ? "contained" : "text"}
                      style={{
                        borderRadius: "0px",
                        width: "50%",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                      onClick={() => setDialogTabValue(1)}
                    >
                      {t('ARABIC')}
                    </Button>
                  </Box>
                  <CustomTabPanel value={dialogTabValue} index={0}>
                    <Typography variant="body1" fontWeight="bold">
                      {t('ADDITIONAL_DETAIL_NAME')}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="name.en"
                      value={item.en}
                      onChange={(e) => {
                        setItem({ ...item, en: e.target.value });
                      }}
                    />
                  </CustomTabPanel>
                  <CustomTabPanel value={dialogTabValue} index={1} dir="rtl">
                    <Typography variant="body1" fontWeight="bold">
                      {t('ADDITIONAL_DETAIL_NAME')}
                    </Typography>
                    <TextField
                      fullWidth
                      size="small"
                      name="name.ar"
                      value={item.ar}
                      onChange={(e) => setItem({ ...item, ar: e.target.value })}
                    />
                  </CustomTabPanel>
                </Container>
              </DialogContent>
              <DialogActions>
                <Button
                  sx={{
                    textTransform: "inherit",
                    fontWeight: "bold"
                  }}
                  onClick={handleClose}
                >{t('CANCEL')}</Button>
                <Button
                  sx={{
                    textTransform: "inherit",
                    fontWeight: "bold"
                  }}
                  onClick={handleSave}
                >{t('SAVE')}</Button>
              </DialogActions>
            </Dialog>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={() => {
                if (selectedIds.length === 0) {
                  alert("Please select at least one item to delete.");
                  return;
                }
                if (!window.confirm("Are you sure you want to delete this?")) {
                  return;
                }
                handleDelete();
              }}
            >
              {t('DELETE')}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              placeholder={t('SEARCH_ITEM_NAME')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="medium"
              onClick={handleSearch}
            >
              {t('SEARCH')}
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

export default AdditionalDetails;
