import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Paper,
  Stack,
} from "@mui/material";
import { ArrowBack, CalendarMonth } from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TextEditor from "../common/TextEditor";
import useCreatePost from "../../hooks/useCreatePost";
import { useNavigate, useParams } from "react-router-dom";
import CustomPageHeader from "../common/CustomPageHeader";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";

export default function AdvertisementSettings(props) {
  const { handleBack, handleNext } = props;
  const { t } = useTranslation();
  const { Id } = useParams();
  const navigate = useNavigate();
  const { stepData, createPost, updatePost, ganerateFormData } =
    useCreatePost();
  const [advertisementSettings, setAdvertisementSettings] = useState(
    stepData.advertisementSettings || {}
  );
  const [advertisementTimeFrame, setAdvertisementTimeFrame] = useState(
    { ...stepData.advertisementTimeFrame, validityDays: "" } || {}
  );
  const handleChangeTimeFrame = (value) => {
    setAdvertisementTimeFrame((pre) => ({ ...pre, type: value }));
  };

  const handleAdvertisementTimeFrameChange = (field, value) => {
    setAdvertisementTimeFrame((prev) => ({
      ...prev,
      [field]: value || null,
    }));
  };

  const handleSubmit = async () => {
    if (
      (advertisementTimeFrame.type === "Fixed" &&
        advertisementTimeFrame?.validityDays === "") ||
      (advertisementTimeFrame.type === "Scheduled" &&
        (!advertisementTimeFrame?.startDate ||
          !advertisementTimeFrame?.endDate))
    ) {
      alert("sellect advertisementTimeFrame");
      return;
    }

    const formData = ganerateFormData();
    // Append advertisementSettings to formData
    const setFormData = (key, value) => {
      if (formData.has(key)) {
        formData.delete(key); // Delete the existing key
      }
      formData.append(key, value); // Append the new value
    };

    // Append advertisementSettings to formData
    setFormData(
      "advertisementSettings[enableStandardAds]",
      advertisementSettings.enableStandardAds
    );
    setFormData(
      "advertisementSettings[enableCategoryFeaturedAds]",
      advertisementSettings.enableCategoryFeaturedAds
    );
    setFormData(
      "advertisementSettings[enableHomepageFeaturedAds]",
      advertisementSettings.enableHomepageFeaturedAds
    );
    setFormData(
      "advertisementSettings[enableMainHomepageAds]",
      advertisementSettings.enableMainHomepageAds
    );

    // Append the time frame fields based on type
    if (advertisementTimeFrame.type === "Scheduled") {
      setFormData("advertisementTimeFrame[type]", advertisementTimeFrame.type);
      setFormData(
        "advertisementTimeFrame[startDate]",
        advertisementTimeFrame.startDate
      );
      setFormData(
        "advertisementTimeFrame[endDate]",
        advertisementTimeFrame.endDate
      );
      setFormData("advertisementTimeFrame[validityDays]", ""); // Empty validityDays for Scheduled
    } else if (advertisementTimeFrame.type === "Fixed") {
      setFormData("advertisementTimeFrame[type]", advertisementTimeFrame.type);
      setFormData("advertisementTimeFrame[startDate]", null);
      setFormData("advertisementTimeFrame[endDate]", null);
      setFormData(
        "advertisementTimeFrame[validityDays]",
        advertisementTimeFrame?.validityDays
      );
    }

    let data;
    if (Id) {
      data = await updatePost(Id, formData);
    } else {
      data = await createPost(formData);
    }
    console.log(data);

    if (data.success) {
      navigate("/ads/create/success");
    }
  };

  return (
    <Box sx={{ mx: "auto" }}>
      <Stack spacing={4} mt={3}>
        {/* Re-Post/Extend Ad Section */}
        <Paper
          elevation={0}
          sx={{
            p: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h6"
            sx={{ mb: 2, color: "#F06B5B", fontWeight: "bold" }}
          >
            {t("SELECT_ADVERTISEMENT_SETTINGS")}
          </Typography>
          <Stack>
            <FormControlLabel
              control={
                <Checkbox
                  checked={advertisementSettings.enableStandardAds}
                  onChange={(e) =>
                    setAdvertisementSettings((prevSettings) => ({
                      ...prevSettings,
                      enableStandardAds: e.target.checked,
                    }))
                  }
                />
              }
              label={t("ENABLE_AS_STANDARD_ADS")}
              sx={{
                "& .MuiFormControlLabel-label": { fontWeight: "bold" },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={advertisementSettings.enableCategoryFeaturedAds}
                  onChange={(e) =>
                    setAdvertisementSettings((prevSettings) => ({
                      ...prevSettings,
                      enableCategoryFeaturedAds: e.target.checked,
                    }))
                  }
                />
              }
              label={t("ENABLE_AS_CATEGORY_FEATURED_ADS")}
              sx={{
                "& .MuiFormControlLabel-label": { fontWeight: "bold" },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={advertisementSettings.enableHomepageFeaturedAds}
                  onChange={(e) =>
                    setAdvertisementSettings((prevSettings) => ({
                      ...prevSettings,
                      enableHomepageFeaturedAds: e.target.checked,
                    }))
                  }
                />
              }
              label={t("ENABLE_AS_HOME_FEATURED_ADS")}
              sx={{
                "& .MuiFormControlLabel-label": { fontWeight: "bold" },
              }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={advertisementSettings.enableMainHomepageAds}
                  onChange={(e) =>
                    setAdvertisementSettings((prevSettings) => ({
                      ...prevSettings,
                      enableMainHomepageAds: e.target.checked,
                    }))
                  }
                />
              }
              label={t("ENABLE_AS_MAIN_HOME_PAGE_ADS")}
              sx={{
                "& .MuiFormControlLabel-label": { fontWeight: "bold" },
              }}
            />
          </Stack>
        </Paper>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Paper
            elevation={0}
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h6"
              sx={{ mb: 2, color: "#F06B5B", fontWeight: "bold" }}
            >
              {t("SELECT_ADVERTISEMENT_TIME_FRAME")}
            </Typography>

            <RadioGroup
              value={advertisementTimeFrame.type}
              onChange={(e) => handleChangeTimeFrame(e.target.value)}
            >
              <Box spacing={3}>
                <FormControlLabel
                  value="Fixed"
                  control={<Radio />}
                  label={t("USER_NUMBER_OF_DAYS")}
                />
                <FormControlLabel
                  value="Scheduled"
                  control={<Radio />}
                  label={t("SCHEDULED_NUMBER_OF_DAYS")}
                />
              </Box>
            </RadioGroup>

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              gap={3}
            >
              <Box>
                <Typography sx={{ mb: "0px" }}>
                  {t("ENTER_VALIDITY")}
                </Typography>
                <TextField
                  fullWidth
                  value={advertisementTimeFrame.validityDays}
                  disabled={advertisementTimeFrame.type !== "Fixed"}
                  onChange={(e) =>
                    handleAdvertisementTimeFrameChange(
                      "validityDays",
                      e.target.value
                    )
                  }
                  type="number"
                  size="small"
                />
              </Box>
              <Stack>
                <Box>
                  <Typography sx={{ mb: "0px" }}> {t("START_DATE")}</Typography>
                  <DatePicker
                    value={new Date(advertisementTimeFrame.startDate)}
                    onChange={(newValue) => {
                      const formattedValue = newValue.toISOString();
                      handleAdvertisementTimeFrameChange(
                        "startDate",
                        formattedValue
                      );
                    }}
                    slots={{
                      openPickerIcon: CalendarMonth,
                    }}
                    disabled={advertisementTimeFrame.type !== "Scheduled"}
                    format="dd-MM-yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        disabled: advertisementTimeFrame.type !== "Scheduled",
                        size: "small",
                      },
                    }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ mb: "0px", mt: "20px" }}>
                    {t("END_DATE")}
                  </Typography>
                  <DatePicker
                    value={new Date(advertisementTimeFrame.endDate)}
                    onChange={(newValue) => {
                      const formattedValue = newValue.toISOString();
                      handleAdvertisementTimeFrameChange(
                        "endDate",
                        formattedValue
                      );
                    }}
                    slots={{
                      openPickerIcon: CalendarMonth,
                    }}
                    disabled={advertisementTimeFrame.type !== "Scheduled"}
                    format="dd-MM-yyyy"
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        disabled: advertisementTimeFrame.type !== "Scheduled",
                        size: "small",
                      },
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          </Paper>
        </LocalizationProvider>
        {/* Publish Button */}
        <Paper sx={{ p: 2 }} elevation={0}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={handleBack}
            >
              {t("BACK")}
            </Button>

            <Button
              variant="contained"
              sx={{
                bgcolor: "primary.main",
                textTransform: "inherit",
                fontWeight: "bold",
                width: 200,
              }}
              onClick={handleSubmit}
            >
              {t("PUBLISH")}
            </Button>
          </Box>
        </Paper>
      </Stack>
    </Box>
  );
}
