import { useDispatch, useSelector } from "react-redux";
import { paymentPlansService } from "../services/paymentPlans.api";
import {
  selectPayment,
  selectPaymentError,
  selectPaymentItems,
  setPayment,
  setPaymentItems,
} from "../store/paymentPlanSlice";
import { useState } from "react";

const usePaymentPlans = () => {
  const dispatch = useDispatch();
  const paymentPlanList = useSelector(selectPaymentItems);
  const paymentPlan = useSelector(selectPayment);
  const error = useSelector(selectPaymentError);
  const [loading, setLoading] = useState(false);

  const getPaymentPlans = async (payload) => {
    setLoading(true);
    const data = await paymentPlansService.fetchPaymentPlans(payload);
    if (data.success) {
      dispatch(setPaymentItems(data.data));
    }
    setLoading(false);
    return data;
  };

  const getPaymentPlanById = async (id) => {
    setLoading(true);
    const data = await paymentPlansService.fetchPaymentPlanById(id); 
    if (data.success) {
      dispatch(setPayment(data));
    }
    setLoading(false);
    return data;
  };

  const createPaymentPlan = async (payload) => {
    setLoading(true);
    const data = await paymentPlansService.createPaymentPlan(payload);
    setLoading(false);
    return data;
  };

  const updatePaymentPlan = async ({id, payload}) => {
    setLoading(true);
    const data = await paymentPlansService.updatePaymentPlan(id, payload);
    setLoading(false);
    return data;
  };

  const deletePaymentPlans = async (payload) => {
    setLoading(true)
    const data = await paymentPlansService.deletePaymentPlans(payload);
    setLoading(false)
    return data;
  };

  return {
    getPaymentPlans,
    getPaymentPlanById,
    createPaymentPlan,
    updatePaymentPlan,
    deletePaymentPlans,
    paymentPlanList,
    paymentPlan,
    error,
    loading,
  };
};

export default usePaymentPlans;
