import React, { useCallback, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { LoadScript } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import AdInfo from "../../components/createpost/AdInfo";
import AdSpecifications from "../../components/createpost/AdSpecifications";
import SelectPlan from "../../components/createpost/SelectPlan";
import ConfirmPayment from "../../components/createpost/ConfirmPayment";
import UpdateConfirm from "../../components/createpost/UpdateConfirm";
import ImageUploadStep from "../../components/createpost/ImageUploadStep";
import { ImageProvider, useImageContext } from "../../utils/ImageContext";
import useCreatePost from "../../hooks/useCreatePost";
import AdvertisementSettings from "../../components/createpost/AdvertisementSettings";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { useTranslation } from "react-i18next";

const steps = [
  "Ad Info",
  "Upload Images",
  "Ad Specifications",
  "Select Plan",
  "Confirm Payment",
];

const CreatePostForm = () => {
  const { Id } = useParams();
  const { formatFetchedData, saveAllData, fetchAdDetails } = useCreatePost();
  const { addImages } = useImageContext();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleNext = useCallback(() => {
    setActiveStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  }, []);

  // Function to move to the previous step
  const handleBack = useCallback(() => {
    setActiveStep((prevStep) => Math.max(prevStep - 1, 0));
  }, []);

  const getStepContent = useCallback(
    (step) => {
      switch (step) {
        case 0:
          return <AdInfo handleNext={handleNext} handleBack={handleBack} />;
        case 1:
          return (
            <ImageUploadStep handleNext={handleNext} handleBack={handleBack} />
          );
        case 2:
          return (
            <AdSpecifications handleNext={handleNext} handleBack={handleBack} />
          );
        case 3:
          return (
            <AdvertisementSettings
              handleNext={handleNext}
              handleBack={handleBack}
            />
          );
        default:
          return <Typography>Unknown step</Typography>;
      }
    },
    [handleNext, handleBack, Id]
  );

  const fetchAddDetailsById = useCallback(async (Id) => {
    setLoading(true);
    try {
      const data = await fetchAdDetails(Id);
      if (data.success) {
        const {
          adInfo,
          images,
          formValue,
          advertisementTimeFrame,
          advertisementSettings,
        } = formatFetchedData(data.data);
        console.log(
          adInfo,
          formValue,
          advertisementTimeFrame,
          advertisementSettings
        );
        saveAllData({
          adInfo,
          formValue,
          advertisementTimeFrame,
          advertisementSettings,
        });
        addImages(images);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (Id) {
      fetchAddDetailsById(Id);
    }
  }, [Id]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "background.main",
      }}
    >
      <Box sx={{ width: "100%" }} maxWidth="lg">
        <Box>
          {loading && (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  height: "90vh",
                }}
              >
                <CircularProgress />
              </Box>
            </>
          )}
          <CustomPageHeader
            heading={t("CREATE_ADVERTISEMENT")}
            buttontext={t("BACK_TO_AD_LIST")}
            path="/ads"
          />
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            loading="async"
          >
            {!loading && getStepContent(activeStep)}
          </LoadScript>
        </Box>
      </Box>
    </Box>
  );
};
const CreatePost = () => {
  return (
    <>
      <ImageProvider>
        <CreatePostForm />{" "}
      </ImageProvider>
    </>
  );
};

export default CreatePost;
