import api from "../utils/API";

// Base URL for your API
export const categoryService = {
  fetchCategories: async (payload) => {
    const response = await api.get("/admin/category", { params: payload });
    return response.data;
  },

  createCategory: async (payload) => {
    const response = await api.post("/admin/category", payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  },

  updateCategory: async (id, payload) => {
    const response = await api.put(`/admin/category/${id}`, payload, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  },

  deleteCategory: async (payload) => {
    const response = await api.delete("/admin/category/delete", {
      data: payload,
    });
    return response.data;
  },

  getCategoryById: async (id) => {
    const response = await api.get(`/admin/category/${id}`);
    return response.data;
  },

  getSubCategories: async (categoryId) => {
    const response = await api.get(`/category/sub-categories/${categoryId}`);
    return response.data;
  },

  updateSubCategory: async ( payload) => {
    const response = await api.patch("/admin/category/update-categories", {menu:payload});
    return response.data;
  },


  getDropdownCategoryList: async () => {
    const response = await api.get(`/category/getCategory/list`);
    return response.data;
  },

};
