import api from "../utils/API";

// Base URL for your API
export const paymentPlansService = {
  fetchPaymentPlans: async (payload) => {
    const response = await api.get("/admin/payment-plan", {
      params: payload,
    });
    return response.data;
  },

  fetchPaymentPlanById: async (id) => {
    const response = await api.get(`/admin/payment-plan/${id}`);
    return response.data;
  },

  createPaymentPlan: async (payload) => {
    const response = await api.post("/admin/payment-plan", payload);
    return response.data;
  },

  updatePaymentPlan: async (id, payload) => {
    const response = await api.put(`/admin/payment-plan/${id}`, payload);
    return response.data;
  },

  deletePaymentPlans: async (payload) => {
    const response = await api.delete("/admin/payment-plan/delete", {
      data: payload,
    });
    return response.data;
  },
};
