import api from "../utils/API";

// Base URL for your API
export const reportService = {
  fetchReportItems: async (payload) => {
    const response = await api.get("/admin/report", {
      params: payload,
    });
    return response.data;
  },

  fetchReportById: async (id) => {
    const response = await api.get(`/admin/report/${id}`);
    return response.data;
  },

  deleteReports: async (payload) => {
    const response = await api.delete("/admin/report/delete", {
      data: payload,
    });
    return response.data;
  },
};
