import React, { useEffect, useState } from "react";
import TextEditor from "../../components/common/TextEditor";
import {
  Box,
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";

function PrivacyPolicy() {
  const [editorContent, setEditorContent] = useState({
    en: "<p>Welcome!</p>",
    ar: "<p>Welcome!</p>",
  });
  const [value, setValue] = useState(0);
  const { getSettingsData, updateSettingsData, loading } = useSettings();
  const { t } = useTranslation();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const handleEditorChange = (content, key) => {
    setEditorContent((value) => ({ ...value, [key]: content }));
  };

  const updateAboutUs = () => {
    updateSettingsData({ privacyPolicyText: editorContent });
  };

  useEffect(() => {
    const fetchAboutUs = async () => {
      try {
        const respomse = await getSettingsData({ type: "" });
        const { data } = respomse;
        const { privacyPolicyText } = data;
        setEditorContent({
          en: privacyPolicyText.en || "",
          ar: privacyPolicyText.ar || "",
        });
      } catch (error) {
        console.errr(error);
      }
    };
    fetchAboutUs();
    return () => {};
  }, []);

  return loading ? (
    <PrivacyPolicySkeleton />
  ) : (
    <div>
      <CustomPageHeader
        heading={t("PRIVACY_POLICY")}
        buttontext={t("BACK_TO_SETTINGS")}
        path="/settings"
      />
      <Paper elevation={0} style={{ padding: "30px", marginTop: "20px" }}>
        <Container maxWidth="sm">
          <Typography
            style={{
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "30px",
            }}
            color="primary"
          >
            {t("ENTER_PRIVACY_TEXT")}
          </Typography>
        </Container>
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%" }}>
            <Button
              variant={value === 0 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(0)}
            >
              English
            </Button>
            <Button
              variant={value === 1 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(1)}
            >
              Arabic
            </Button>
          </Box>
        </Container>
        <Container maxWidth="md">
          <CustomTabPanel value={value} index={0}>
            <Typography variant="body1" fontWeight="bold">
              English
            </Typography>
            <TextEditor
              value={editorContent.en}
              onChange={(value) => handleEditorChange(value, "en")}
              language={"en"}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Typography
              variant="body1"
              style={{ textAlign: "end" }}
              fontWeight="bold"
            >
              Arabic
            </Typography>
            <TextEditor
              value={editorContent.ar}
              onChange={(value) => handleEditorChange(value, "ar")}
              language={"ar"}
            />
          </CustomTabPanel>
        </Container>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => window.history.back()}
            >
              {t("BACK")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={updateAboutUs}
            >
              {t("UPDATE")}
            </Button>
          </Box>
        </Container>
      </Paper>
    </div>
  );
}

export default PrivacyPolicy;

const PrivacyPolicySkeleton = () => {
  return (
    <div>
      {/* Skeleton for Page Header */}
      <Paper elevation={0} style={{ padding: "30px", marginTop: "20px" }}>
        <Skeleton variant="text" width="30%" height={40} />
      </Paper>

      {/* Skeleton for Paper containing About Us content */}
      <Paper elevation={0} style={{ padding: "30px", marginTop: "20px" }}>
        <Container maxWidth="sm">
          <Skeleton variant="text" width="50%" height={40} sx={{ mb: 3 }} />
        </Container>

        {/* Skeleton for Language Selection Buttons */}
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%", marginBottom: "10px" }}>
            <Skeleton
              variant="rectangular"
              width="50%"
              height={50}
              sx={{ mx: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width="50%"
              height={50}
              sx={{ mx: 1 }}
            />
          </Box>
        </Container>

        {/* Skeleton for TextEditor section */}
        <Container maxWidth="md">
          <Skeleton
            variant="rectangular"
            width="100%"
            height={200}
            sx={{ mb: 3 }}
          />
          <Skeleton variant="rectangular" width="100%" height={200} />
        </Container>
      </Paper>

      {/* Skeleton for Paper containing Back and Update buttons */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Skeleton
              variant="rectangular"
              width={150}
              height={50}
              sx={{ mx: 1 }}
            />
            <Skeleton
              variant="rectangular"
              width={150}
              height={50}
              sx={{ mx: 1 }}
            />
          </Box>
        </Container>
      </Paper>
    </div>
  );
};
