import React, { useState } from "react";
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useTheme,
  useMediaQuery,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Input,
  InputAdornment,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BuildIcon from "@mui/icons-material/Build";
import DescriptionIcon from "@mui/icons-material/Description";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PeopleIcon from "@mui/icons-material/People";
import ChatIcon from "@mui/icons-material/Chat";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PaymentIcon from "@mui/icons-material/Payment";
import SettingsIcon from "@mui/icons-material/Settings";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";
import SubjectOutlinedIcon from "@mui/icons-material/SubjectOutlined";
import GridViewIcon from "@mui/icons-material/GridView";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import LanguageSelector from "./LanguageSelector";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import i18n from "i18next";
import { useChat } from "../hooks/useChat";
import { useSocket } from "../context/SocketContext";
import logo from "../assets/logo-white.png";
const drawerWidth = 300;

const navigation = [
  { name: "DASHBOARD", icon: DashboardIcon, path: "/" },

  {
    name: "CATEGORIES",
    icon: GridViewIcon,
    path: "/item",
    subItems: [
      { name: "ITEM_LIST", icon: EventNoteOutlinedIcon, path: "/category" },
      { name: "SPECIFICATIONS", icon: BuildIcon, path: "/specification" },
      {
        name: "ADDITIONAL_DETAILS",
        icon: DescriptionIcon,
        path: "/additional-details",
      },
      { name: "MENU", icon: SubjectOutlinedIcon, path: "/menu" },
    ],
  },
  { name: "AD_MANAGEMENT", icon: LocalOfferIcon, path: "/ads" },
  { name: "USERS", icon: PeopleIcon, path: "/users" },
  {
    name: "NOTIFICATIONS",
    icon: NotificationsIcon,
    path: "/notifications",
    subItems: [
      { name: "CHATS", icon: ChatIcon, path: "/chat" },
      { name: "REPORTS", icon: AssessmentIcon, path: "/reports" },
    ],
  },
  { name: "PAYMENT_PLANS", icon: PaymentIcon, path: "/payment-plan" },
  { name: "SETTINGS", icon: SettingsIcon, path: "/settings" },
];

export default function MuiDashboardLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout, user } = useAuth();
  const socket = useSocket();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { fetchConversationsData } = useChat(socket, user);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    if (event.target.value === "" && location.pathname === "/chat") {
      fetchConversationsData("");
    }
  };
  // const handleSearch = () => {
  //   if (location.pathname !== "/chat") {
  //     navigate("/chat");
  //     setTimeout(() => {
  //       fetchConversationsData(searchQuery);
  //     }, 500);
  //   } else {
  //     fetchConversationsData(searchQuery);
  //   }
  // };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const drawer = (
    <div>
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: ".5rem",
            marginBottom: ".5rem",
          }}
        >
          <img
            src={logo}
            width={150}
            alt="Sold logo"
            onError={(e) => {
              e.target.onerror = "../logout-white.png";
              e.target.src = "../logout-white.png";
            }}
          />
        </Box>
      </Toolbar>
      <List>
        {navigation.map((item) => {
          const isActive = location.pathname === item.path;
          return item.name !== "NOTIFICATIONS" && item.name !== "CATEGORIES" ? (
            <ListItem
              key={item.name}
              disablePadding
              sx={{ padding: ".2rem" }}
              component={Link}
              to={item.path}
            >
              <ListItemButton
                sx={{
                  backgroundColor: isActive ? "#fff" : "#f5f5f538", // Active tab color
                  "&:hover": {
                    backgroundColor: isActive ? "#fff" : "#e5e5e5", // Hover effect for active item
                  },
                }}
              >
                <ListItemIcon
                  sx={{ color: isActive ? "primary.main" : "white" }}
                >
                  <item.icon />
                </ListItemIcon>
                <ListItemText
                  primary={t(item.name)}
                  sx={{
                    color: isActive ? "primary.main" : "white",
                    textAlign: "start",
                  }}
                />
              </ListItemButton>
            </ListItem>
          ) : item.name === "CATEGORIES" ? (
            <ListItem
              disablePadding
              sx={{ padding: ".2rem" }}
              component={Link}
              key="category"
            >
              <Accordion
                expanded={categoryOpen} // This controls if the accordion is expanded
                onChange={() => setCategoryOpen(!categoryOpen)} // Toggles open state
                sx={{
                  width: "100%",
                  backgroundColor: "#f5f5f500",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                  sx={{
                    height: "10px !important",
                    backgroundColor: isActive ? "#fff" : "#f5f5f538",
                    "&:hover": {
                      backgroundColor: isActive ? "#fff" : "#e5e5e5",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{ color: isActive ? "primary.main" : "white" }}
                  >
                    <GridViewIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("CATEGORIES")}
                    sx={{
                      color: isActive ? "primary.main" : "white",
                      textAlign: "start",
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: 0,
                    marginRight: "-1rem",
                    backgroundColor: "inherit",
                  }}
                >
                  <List component="div" disablePadding>
                    {item?.subItems?.map((cat) => {
                      const isSubActive = location.pathname === cat.path;
                      return (
                        <ListItem
                          key={cat.name} // Ensure each item has a unique key
                          component={Link}
                          to={cat.path}
                          sx={{ marginBottom: "-.5rem" }}
                        >
                          <ListItemButton
                            sx={{
                              gap: "1rem",
                              backgroundColor: isSubActive
                                ? "#fff"
                                : "#f5f5f538",
                              color: isSubActive ? "primary.main" : "white",
                              "&:hover": {
                                backgroundColor: isSubActive
                                  ? "#fff"
                                  : "#e5e5e5",
                              },
                            }}
                          >
                            <cat.icon />
                            <ListItemText
                              primary={t(cat.name)}
                              sx={{ textAlign: "start" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          ) : (
            <ListItem
              disablePadding
              sx={{ padding: ".2rem" }}
              component={Link}
              key="notifications"
            >
              <Accordion
                expanded={notificationsOpen} // This controls if the accordion is expanded
                onChange={() => setNotificationsOpen(!notificationsOpen)} // Toggles open state
                sx={{
                  width: "100%",
                  backgroundColor: "#f5f5f500",
                  boxShadow: "none",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
                  sx={{
                    height: "10px !important",
                    backgroundColor: isActive ? "#fff" : "#f5f5f538",
                    "&:hover": {
                      backgroundColor: isActive ? "#fff" : "#e5e5e5",
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{ color: isActive ? "primary.main" : "white" }}
                  >
                    <NotificationsIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("NOTIFICATIONS")}
                    sx={{
                      color: isActive ? "primary.main" : "white",
                      textAlign: "start",
                    }}
                  />
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    padding: 0,
                    marginRight: "-1rem",
                    backgroundColor: "inherit",
                  }}
                >
                  <List component="div" disablePadding>
                    {item?.subItems?.map((itm) => {
                      const isSubActive = location.pathname === itm.path;
                      return (
                        <ListItem
                          key={itm.name} // Ensure each item has a unique key
                          component={Link}
                          to={itm.path}
                          sx={{ marginBottom: "-.5rem" }}
                        >
                          <ListItemButton
                            sx={{
                              gap: "1rem",
                              backgroundColor: isSubActive
                                ? "#fff"
                                : "#f5f5f538",
                              color: isSubActive ? "primary.main" : "white",
                              "&:hover": {
                                backgroundColor: isSubActive
                                  ? "#fff"
                                  : "#e5e5e5",
                              },
                            }}
                          >
                            <itm.icon />
                            <ListItemText
                              primary={t(itm.name)}
                              sx={{ textAlign: "start" }}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          );
        })}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml:
            theme.direction === "ltr" ? { lg: `${drawerWidth}px` } : undefined,
          mr:
            theme.direction === "rtl" ? { lg: `${drawerWidth}px` } : undefined,
          bgcolor: "#e5e5e5",
          color: "text.primary",
          boxShadow: "none",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={
              i18n.language == "en"
                ? { mr: 2, display: { lg: "none" } }
                : { ml: 2, display: { lg: "none" } }
            }
          >
            <MenuIcon />
          </IconButton>
          <Input
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            placeholder="Search"
            disableUnderline
            // value={searchQuery}
            // onChange={handleSearchChange}
            // onKeyPress={(event) => {
            //   if (event.key === "Enter" && event.target.value.length >= 3) {
            //     handleSearch();
            //   }
            // }}
            sx={{
              width: "400px",
              height: "30px",
              padding: "0.5rem",
              borderRadius: "4px",
              backgroundColor: "#f5f5f5",
            }}
          />
          <Box sx={{ flexGrow: 1 }} />
          <NotificationsIcon />
          <Button
            color="inherit"
            onClick={handleLogout}
            // startIcon={<ExitToAppIcon />}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              "& .MuiButton-startIcon": {
                marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                marginRight: theme.direction === "ltr" ? "8px" : "0px",
              },
            }}
          >
            {t("LOGOUT")}
          </Button>
          <LanguageSelector />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}
      >
        <Drawer
          anchor={theme.direction === "ltr" ? "left" : "right"}
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? mobileOpen : true}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              bgcolor: "primary.main",
              padding: ".7rem",
              overflowX: "hidden",
            },
            "& .MuiDrawer-paper::-webkit-scrollbar": {
              width: ".1px", // Width of the scrollbar
            },
            "& .MuiDrawer-paper::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 0, 0, 0.1)", // Scroll thumb styling
              borderRadius: ".1px",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          backgroundColor: "background.main",
        }}
      >
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
