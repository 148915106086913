import { Navigate } from 'react-router-dom';
import { getAccessToken } from '../../utils/auth';

const PrivateRoute = (props) => {
  const accessToken = getAccessToken()

  return  accessToken ? props.children : <Navigate to="/login" />;
};


export default PrivateRoute;
