import { useNavigate } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import { useFormik } from "formik";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import * as Yup from "yup";
import useSettings from "../../hooks/useSettings";
import { useTranslation } from "react-i18next";

function CreateCountry() {
  const navigate = useNavigate();
  const {t} = useTranslation()
  const [value, setValue] = React.useState(0);
  const [iconImage, setIconImage] = React.useState(null);
  const { createCountry } = useSettings();
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const formik = useFormik({
    initialValues: {
      name: {
        en: "",
        ar: "",
      },
      currency: {
        en: "",
        ar: "",
      },
      phoneCode: "",
    },
    validationSchema: Yup.object({
      phoneCode: Yup.string()
        .matches(
          /^\+[0-9]{1,5}$/,
          'Phone code must start with "+" followed by 1 to 5 digits.'
        )
        .required("Phone code is required."),
    }),
    onSubmit: async (values) => {
      try {
        const formData = prepareFormData(values);
        const response =  await createCountry(formData);

        if (response.success) {
          formik.resetForm(); // Reset form after successful submission
          navigate("/settings/ad-settings");
        } else {
          alert("Oops.. Something went wrong.");
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Oops.. Something went wrong.");
      }
    },
  });

  const prepareFormData = (values) => {
    if (!values.name || !values.name.en || values.name.en.trim() === "") {
      alert("Name (English) is required!");
      return null;
    }
    const formData = new FormData();
    if (iconImage) {
      formData.append("image", iconImage[0]);
    }
    formData.append("name", JSON.stringify(values.name));
    formData.append("currency", JSON.stringify(values.currency));
    formData.append("phoneCode", values.phoneCode);
    return formData;
  };

  const handleFileChange = (event) => {
    const file = event.target.files;
    if (file) {
      setIconImage([...file]);
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <CustomPageHeader heading={t('ADD_COUNTRY')} buttontext={t('BACK_TO_SETTINGS')} path='/settings' />

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%" }}>
            <Button
              variant={value === 0 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(0)}
            >
              English
            </Button>
            <Button
              variant={value === 1 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "20px",
                fontWeight: "bold",
              }}
              onClick={() => handleChange(1)}
            >
              Arabic
            </Button>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Typography variant="body1" fontWeight="bold">
            {t('NAME')}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name.en"
              value={formik?.values?.name?.en}
              onChange={formik.handleChange}
            />
            <Typography
              variant="body1"
              fontWeight="bold"
              style={{ marginTop: "30px" }}
            >
              {t('CURRENCY')}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="currency.en"
              value={formik?.values?.currency?.en}
              onChange={formik.handleChange}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div dir="rtl">
              <Typography variant="body1" fontWeight="bold">
              {t('NAME')}
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="name.ar"
                value={formik?.values?.name?.ar}
                onChange={formik.handleChange}
              />
              <Typography
                variant="body1"
                fontWeight="bold"
                style={{ marginTop: "30px" }}
              >
                {t('CURRENCY')}
              </Typography>
              <TextField
                fullWidth
                size="small"
                name="currency.ar"
                value={formik?.values?.currency?.ar}
                onChange={formik.handleChange}
              />
            </div>
          </CustomTabPanel>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <Typography variant="body1" fontWeight="bold">
            {t('PHONE_CODE')}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="phoneCode"
              value={formik.values.phoneCode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.phoneCode && Boolean(formik.errors.phoneCode)
              }
              helperText={formik.touched.phoneCode && formik.errors.phoneCode}
            />
          </Box>
        </Container>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography fontWeight="bold" variant="body2">
              {t('FLAG_IMAGE')}
              </Typography>
              <Box
                sx={{
                  height: "70px",
                  width: "70px",
                  backgroundColor: "primary.main",
                  borderRadius: "10px",
                }}
              >
                {iconImage && (
                  <img
                    style={{
                      height: "70px",
                      width: "70px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    src={
                      typeof iconImage === "string"
                        ? iconImage
                        : URL.createObjectURL(iconImage[0])
                    }
                    alt="icon preview"
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{ textTransform: "none", fontWeight: "bold"}}
              >
                {t('UPLOAD_IMAGE')}
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="imageUploadInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  multiple
                />
              </Button>
              <Typography fontWeight="bold" variant="body2">
              {t('SIZE_100_X_100PX')}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={formik.handleSubmit}
            > 
              {t('CREATE_COUNTRY')}
            </Button>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
}

export default CreateCountry;
