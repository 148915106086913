import { createSlice } from "@reduxjs/toolkit";

// Create a slice for banner
const bannerSlice = createSlice({
  name: "banner",
  initialState: {
    bannerItems: [],
    banner: {},
    loading: false,
    error: null,
  },
  reducers: {
    setBannerItems: (state, action) => {
      state.bannerItems = action.payload.data || [];
      state.loading = false;
    },
    setBanner: (state, action) => {
      state.banner = action.payload.data || [];
      state.loading = false;
    },
  },
});

// Export the loading and error selectors if needed
export const selectBannerLoading = (state) => state.banner.loading;
export const selectBannerError = (state) => state.banner.error;
export const selectBannerItems = (state) => state.banner.bannerItems;
export const selectBanner = (state) => state.banner.banner;

// Export the reducer to include in the store
export const { setBanner, setBannerItems } = bannerSlice.actions;
export default bannerSlice.reducer;
