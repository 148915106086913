import React, { useEffect, useState, useRef } from "react";
import {
  Checkbox,
  Paper,
  Box,
  Button,
  Container,
  InputAdornment,
  IconButton,
  Typography,
  TextField,
  Link,
  MenuItem,
  Grid,
  Alert,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import { useFormik } from "formik";
import useSettings from "../../hooks/useSettings";
import CustomTable from "../../components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import useUser from "../../hooks/useUser";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { formatTimestamp } from "../../utils/formatHelper";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format, set } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import i18n from "i18next";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { signUpValidationSchema } from "../../services/auth.validation";

function UsersList() {
  const { t } = useTranslation();
  const columns = [
    { id: "_id", label: "", align: i18n.language == "en" ? "left" : "right" },
    {
      id: "name",
      label: t("NAME"),
      align: i18n.language == "en" ? "left" : "right",
    },
    { id: "joinedDate", label: t("JOINED_DATE"), align: "left" },
    { id: "status", label: t("STATUS"), align: "center" },
    { id: "totalAds", label: t("TOTAL_ADS"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];

  const navigate = useNavigate();
  const { getUserList, users, deleteUsers, createUser, loading } = useUser();
  const { getAllCountry, countries } = useSettings();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    page: users?.page || 1,
    limit: users?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: users?.totalCount || 0,
    totalPages: users?.totalPages || 0,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [filter, setFilter] = useState({
    startDate: "",
    endDate: "",
  });
  const [creaetLoader, setCreateLoader] = useState(false)
  const [open, setOpen] = useState(false);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      countryCode: "+965",
      isAdmin: false,
      email: "",
    },
    validationSchema: signUpValidationSchema,
    onSubmit: async (values) => {
      setCreateLoader(true)
      const { confirmPassword, ...rest } = values;
      const response = await createUser({ ...rest });
      if (response.success) {
        setSignUpErrorMessage("");
        setOpen(false);
        formik.resetForm();
        const payload = {
          page: pagination?.page,
          limit: pagination?.limit,
          search: search,
          filter:
            (filter.startDate || filter?.endDate) && JSON.stringify(filter),
        };
        getUserList(payload);
      } else {
        setSignUpErrorMessage(response.errorMessage);
      }
      setCreateLoader(false)
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSignUpErrorMessage("");
    formik.resetForm();
    setOpen(false);
  };
  const handleCheckboxChange = (id) => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((item) => item !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getUserList(payload);
  };

  const handleDelete = () => {
    if (selectedIds.length === 0) {
      alert(t("PLEASE_SELECT_AT_LEAST_ONE_USER_TO_DELETE"));
      return;
    }
    const message =
      selectedIds.length > 1
        ? t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THESE_USERS")
        : t("ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_USER");
    if (window.confirm(message)) {
      deleteUsers({ ids: [...selectedIds] })
        .then((res) => {
          if (res.success) {
            alert(t("USER_DELETED_SUCCESSFULLY"));
            setSelectedIds([]);
            const payload = {
              page: 1,
              limit: pagination?.limit,
              search: search,
              filter:
                (filter.startDate || filter?.endDate) && JSON.stringify(filter),
            };
            getUserList(payload);
          } else {
            alert(
              t("ERROR_DELETING_USERS", {
                message: res.message || t("SOMETHING_WENT_WRONG"),
              })
            );
          }
        })
        .catch((err) => {
          alert(
            t("ERROR_DELETING_USERS", {
              message: err.message || t("SOMETHING_WENT_WRONG"),
            })
          );
        });
    }
  };
  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: search,
      filter: (filter.startDate || filter?.endDate) && JSON.stringify(filter),
    };
    getUserList(payload);
  }, [pagination?.page, pagination?.limit, filter]);
  useEffect(() => {
    if (users?.users?.length > 0) {
      const items = users?.users;
      setData(
        items?.map((item) => ({
          _id: (
            <Box sx={{ maxWidth: "15px" }}>
              <Checkbox
                checked={selectedIds.includes(item._id)}
                onChange={() => handleCheckboxChange(item._id)}
              />
            </Box>
          ),
          name: item.name,
          joinedDate: formatTimestamp(item.createdAt, false),
          status: (
            <span
              style={{
                color:
                  item.status === "active"
                    ? "green"
                    : item.status === "inactive"
                    ? "red"
                    : item.status === "not_verified"
                    ? "orange"
                    : "black",
              }}
            >
              {item.status
                ?.replace(/_/g, " ")
                .toLowerCase()
                .replace(/(^\w|\s\w)/g, (match) => match.toUpperCase())}{" "}
            </span>
          ),
          totalAds: item.totalAds,
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "inherit",
                padding: "0",
                minWidth: "45px",
                fontWeight: "bold",
              }}
              onClick={() => {
                navigate(`/user/${item?._id}`);
              }}
            >
              {t("VIEW")}
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: users?.totalCount || 0,
      totalPages: users?.totalPages || 0,
    });
  }, [users, selectedIds]);
  useEffect(() => {
    getAllCountry();
  }, []);
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t("USERS_LIST")}
        </Typography>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={() => handleClickOpen()}
            >
              {t("CREATE_USER")}
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle
                id="alert-dialog-title"
                textAlign="center"
                fontWeight="bold"
                color="primary"
              >
                {t("CREATE_USER")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Grid style={{ width: "100%" }}>
                    {signUpErrorMessage ? (
                      <Alert
                        severity="error"
                        style={{ width: "100%", marginBottom: "20px" }}
                      >
                        {signUpErrorMessage}
                      </Alert>
                    ) : (
                      <></>
                    )}
                  </Grid>
                  <SignUpForm
                    formik={formik}
                    t={t}
                    loading={creaetLoader}
                    countries={countries}
                  />
                </DialogContentText>
              </DialogContent>
            </Dialog>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={handleDelete}
            >
              {t("DELETE")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              placeholder="Search name"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="medium"
              onClick={handleSearch}
            >
              {t("SEARCH")}
            </Button>
          </Box>
        </Container>
        <Container maxWidth="md">
          <DateFilter setFilter={setFilter} />
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

function DateFilter({ setFilter }) {
  const { t } = useTranslation();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFilter = () => {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);

    if (fromDate && toDate) {
      if (endDate < startDate) {
        alert("End date must be greater than start date.");
        return;
      }
    }

    const payload = {
      startDate: fromDate ? format(new Date(startDate), "yyyy-MM-dd") : null,
      endDate: toDate ? format(new Date(endDate), "yyyy-MM-dd") : null,
    };

    setFilter(payload);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems="center"
        justifyContent="center"
        gap={2}
        p={2}
        sx={{
          margin: "auto",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography variant="body2" fontWeight="bold" minWidth="100px">
            {t("START_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={fromDate}
            onChange={(newValue) => setFromDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "1rem" }}>
          <Typography
            variant="body2"
            fontWeight="bold"
            minWidth="100px"
            textAlign="center"
          >
            {t("END_DATE")}
          </Typography>
          <DatePicker
            slotProps={{
              actionBar: {
                actions: ["clear"],
              },
              textField: {
                sx: {
                  "& .MuiInputBase-input": {
                    padding: "6px",
                  },
                },
              },
            }}
            value={toDate}
            onChange={(newValue) => setToDate(newValue)}
            format="dd/MM/yyyy"
            renderInput={(params) => (
              <TextField size="small" {...params} fullWidth />
            )}
          />
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleFilter}
          fullWidth
          sx={{
            maxWidth: { sm: 120 },
            textTransform: "none",
            fontWeight: "bold",
          }}
        >
          {t("FILTER")}
        </Button>
      </Box>
    </LocalizationProvider>
  );
}

export default UsersList;

const SignUpForm = ({ formik, t, loading, countries }) => {
  const handleChange = (event) => {
    const { value } = event.target;
    if (/^\d*$/.test(value)) {
      formik.setFieldValue("mobile", value);
    }
  };
  console.log(formik.values, "sla;dkfjsaljd");

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Name Input */}
      <Box display="flex" justifyContent="flex-start" width="100%">
        <Typography variant="body1" fontWeight="bold">
          {t("NAME")}
        </Typography>
      </Box>
      <TextField
        variant="outlined"
        name="name"
        size="small"
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
        fullWidth
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": { backgroundColor: "background.white" },
        }}
      />

      {/* Mobile Input */}
      <Box display="flex" justifyContent="flex-start" width="100%">
        <Typography variant="body1" fontWeight="bold">
          {t("PHONE")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "top",
          justifyContent: "space-between",
          gap: 1,
        }}
      >
        <Box>
          <TextField
            select
            name="countryCode"
            size="small"
            variant="outlined"
            value={formik.values.countryCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            sx={{
              mb: 2,
              minWidth: "120px",
              "& .MuiOutlinedInput-root": {
                backgroundColor: "background.white",
              },
            }}
          >
            {countries?.countries?.map((country, index) => (
              <MenuItem key={index} value={country?.phoneCode}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  {/* Render Flag */}
                  <img
                    src={country?.image}
                    alt={country.phoneCode}
                    style={{ width: "20px", height: "15px" }}
                  />
                  {/* Display country code */}
                  <span>{country?.phoneCode}</span>
                </div>
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={{ width: "95%" }}>
          <TextField
            variant="outlined"
            name="mobile"
            size="small"
            value={formik.values.mobile}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            helperText={formik.touched.mobile && formik.errors.mobile}
            fullWidth
            sx={{
              mb: 2,
              "& .MuiOutlinedInput-root": {
                backgroundColor: "background.white",
              },
            }}
            InputProps={{
              inputMode: "numeric", // Suggest a numeric keyboard for mobile devices
              pattern: "[0-9]*", // Regex pattern to restrict input to numbers
            }}
            inputProps={{
              minLength: 6, // Minimum length for phone number (example: 10 digits)
              maxLength: 15, // Maximum length for phone number (example: 15 digits)
            }}
          />
        </Box>
      </Box>

      {/* Email Input */}
      <Box display="flex" justifyContent="flex-start" width="100%">
        <Typography variant="body1" fontWeight="bold">
          {t("EMAIL")}
        </Typography>
      </Box>
      <TextField
        name="email"
        size="small"
        variant="outlined"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
        fullWidth
        sx={{
          mb: 2,
          "& .MuiOutlinedInput-root": { backgroundColor: "background.white" },
        }}
      />
      <Box>
        <FormLabel
          id="demo-row-radio-buttons-group-label"
          sx={{ fontWeight: "bold" }}
        >
          {t("USER_TYPE")}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="isAdmin"
          value={formik.values.isAdmin}
          onChange={(event) => {
            const booleanValue = event.target.value === "true"; // Convert the string to boolean
            formik.setFieldValue("isAdmin", booleanValue); // Save it as a boolean
          }}
        >
          <FormControlLabel
            value={false}
            control={<Radio />}
            label={t("USER")}
          />
          <FormControlLabel
            value={true}
            control={<Radio />}
            label={t("ADMIN")}
          />
        </RadioGroup>
      </Box>

      {/* Sign Up Button */}
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mb: 2, textTransform: "none", fontWeight: "bold" }}
        onClick={formik.handleSubmit}
        disabled={loading}
      >
        {t("CREATE_ACCOUNT")}
      </Button>
    </form>
  );
};
