import React, { useState } from "react";
import { Box, Button, Typography, IconButton, Grid, Paper, Container } from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import useCreatePost from "../../hooks/useCreatePost"; // Custom hook for saving Redux state
import { useImageContext } from "../../utils/ImageContext";
import { useTranslation } from "react-i18next";

const ImageUploadStep = (props) => {
  const { handleNext, handleBack } = props;
  const { t } = useTranslation();
  const { images, addImages } = useImageContext(); // Use the context

  const maxImages = 8;

  // Handle file input change
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);

    // Check if no files are selected
    if (files.length === 0) return;

    // Prevent adding more than the allowed number of images
    if (images.length + files.length > maxImages) {
      alert(`You can upload a maximum of ${maxImages} images.`);
      return;
    }

    // Create an array to store new images
    const newImages = [...images];
    let loadedImages = [];
    // Loop through each file and read as Data URL (for preview)
    files.forEach((file, index) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const newImage = { src: reader.result, file }; // Store base64 and file object
        loadedImages.push(newImage);

        // Check if it's the last image to load
        if (loadedImages.length === files.length) {
          // Combine the loaded images with existing images
          const updatedImages = [...newImages, ...loadedImages];

          // Set images state
          addImages(updatedImages);
        }
      };

      reader.readAsDataURL(file); // Start reading file
    });
  };

  // Handle delete image
  const handleDeleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);

    addImages(newImages);
  };

  // Proceed to the next step and save data to Redux
  const goToNext = () => {
    if (images.length > 0) {
      handleNext();
    }
  };

  return (
    <>
      <Paper style={{ marginTop: '10px' }} elevation={0} >
        <Box sx={{ p: 5 }}>
          <Typography variant="body2" color="primary" style={{ textAlign: 'center', fontWeight: 'bold' }}>
            {t('SELECT_ADVERTISEMENT_IMAGES')}
          </Typography>

          <Box sx={{ padding: 3, backgroundColor: 'background.white' }}>
            <Typography variant="body2" gutterBottom style={{ textAlign: 'center', fontSize: '16px', fontWeight: 'bold', marginBottom: '20px' }}>
              {t("SELECT_IMAGES")}
              <span
                style={{
                  color: "red",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                *
              </span>
            </Typography>
            <Container maxWidth='md'> 
            <Grid container spacing={2}>
              {images?.map((image, index) => (
                <Grid item xs={6} sm={4} md={3} key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      aspectRatio: "1 / 1",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 1,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={image.src}
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                      alt={`${index + 1}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = "https://via.placeholder.com/150";
                      }}
                    />
                    <IconButton
                      sx={{ position: "absolute", top: 8, right: 8, color: "white" }}
                      onClick={() => handleDeleteImage(index)}
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>

                    {/* Mark as cover button or label */}
                    {index === 0 ? (
                      <Typography
                        sx={{
                          position: "absolute",
                          bottom: 8,
                          left: 8,
                          backgroundColor: "rgba(0, 0, 0, 0.6)",
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                        }}
                      >
                        Cover Image
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Grid>
              ))}

              {/* Add image button */}
              {images.length < maxImages && (
                <>
                  {
                    images.length === 0 && (<Grid item xs={6} sm={4} md={3}>
                      <Box
                        sx={{
                          position: "relative",
                          aspectRatio: "1 / 1",
                          backgroundColor: "primary.main",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: 1,
                          overflow: "hidden",
                        }}
                      >
                        <IconButton
                          size="large"
                          onClick={() =>
                            document.getElementById("imageUploadInput").click()
                          }
                        >
                          <AddIcon sx={{ color: "white", fontSize: "32px" }} />
                        </IconButton>
                        <input
                          type="file"
                          id="imageUploadInput"
                          accept="image/*"
                          style={{ display: "none" }}
                          multiple
                          onChange={handleFileChange}
                        />
                      </Box>
                    </Grid>)
                  }

                  <Grid item xs={6} sm={4} md={3}>
                    <Box
                      sx={{
                        position: "relative",
                        aspectRatio: "1 / 1",
                        backgroundColor: "grey.300",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 1,
                        overflow: "hidden",
                      }}
                    >
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={() =>
                          document.getElementById("imageUploadInput").click()
                        }
                      >
                        <AddIcon sx={{ fontSize: "32px" }} />
                      </IconButton>
                      <input
                        type="file"
                        id="imageUploadInput"
                        accept="image/*"
                        style={{ display: "none" }}
                        multiple
                        onChange={handleFileChange}
                      />
                    </Box>
                  </Grid>

                </>
              )}
              {Array.from({ length: maxImages - (images.length === 0 ? 2 : images.length + 1) }).map(
                (_, index) => (
                  <Grid item xs={6} sm={4} md={3}>
                    <Box
                      sx={{
                        position: "relative",
                        aspectRatio: "1 / 1",
                        backgroundColor: "grey.300",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 1,
                        overflow: "hidden",
                      }}
                    ></Box>

                  </Grid>
                )
              )}
            </Grid>
            </Container>


          </Box>
        </Box>
      </Paper>
      <Paper style={{marginTop:'10px'}}  elevation={0} >
        <Box sx={{ display: "flex", justifyContent: "center", gap: 5, width: '100%', p:3 }}>
          <Button variant="contained" style={{ backgroundColor: '#606161', textTransform: 'capitalize' }} onClick={handleBack}>
            {t("BACK")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={goToNext}
            style={{ textTransform: 'capitalize', paddingX: 2 }}
            disabled={images.length === 0} // Disable if no image is uploaded
          >
            {t("NEXT")}
          </Button>
        </Box>
      </Paper>

    </>
  );
};

export default ImageUploadStep;
