import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bannerService } from "../services/banner.api";
import {
  selectBanner,
  selectBannerError,
  selectBannerItems,
  setBanner,
  setBannerItems,
} from "../store/bannerSlice";

const useBanner = () => {
  const dispatch = useDispatch();
  const banners = useSelector(selectBannerItems);
  const banner = useSelector(selectBanner);
  const error = useSelector(selectBannerError);
  const [loading, setLoading] = useState(false);

  const addBanner = async (payload) => {
    setLoading(true);
    const data = await bannerService.createBanner(payload);
    setLoading(false);
    return data;
  };

  const getBannerList = async (payload) => {
    setLoading(true);
    const data = await bannerService.fetchBannerItems(payload);
    if (data.success) {
      dispatch(setBannerItems(data));
    }
    setLoading(false);
    return data;
  };

  const getBannerById = async (id) => {
    setLoading(true);
    const data = await bannerService.fetchBannerById(id);
    if (data.success) {
      dispatch(setBanner(data));
    }
    setLoading(false);
    return data;
  };

  const updateBanner = async (Id, payload) => {
    setLoading(true);
    const data = await bannerService.updateBanner(Id, payload);
    setLoading(false);
    return data;
  };

  const deleteBanner = async (id) => {
    setLoading(true);
    const data = await bannerService.deleteBanner(id);
    setLoading(false);
    return data;
  };

  return {
    getBannerList,
    banners,
    addBanner,
    getBannerById,
    updateBanner,
    deleteBanner,
    banner,
    loading,
    error,
  };
};

export default useBanner;
