import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Avatar,
  Button,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemAvatar,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  useMediaQuery,
  Select,
  MenuItem,
  FormControl,
  styled,
  Skeleton,
  Stack,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import useDashboard from "../../hooks/useDashboard";
import getLocalizedValue from "../../utils/getLocalizedValue";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatTimestamp } from "../../utils/formatHelper";
import AdvertisementTable from "./AdvertisementTable";
import CustomDataGrid from "./CustomDataGrid";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";

const headers = [
  { key: "AD_TITLE", align: "left" },
  { key: "ID", align: "center" },
  { key: "USER", align: "center" },
  { key: "STATUS", align: "center" },
  { key: "VIEW_PERIOD", align: "center" },
  { key: "CATEGORY", align: "center" },
  { key: "ACTION", align: "center" },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const initialTimeFrame = [
  { type: 0, frame: ["today", "yesterday"] },
  { type: 1, frame: ["this_week", "last_week", "last_7_days"] },
  { type: 2, frame: ["this_month", "last_month", "last_30_days"] },
];

export default function Dashboard() {
  const navigate = useNavigate();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [chartPeriod, setChartPeriod] = useState(0);
  const [filter, setFilter] = React.useState("total_ads");
  const [timeframe, setTimeFrame] = React.useState("today");
  const {
    getDashboardTopCustomer,
    getDashboardRecentAds,
    getDashboardCardData,
    getDashboardReports,
    dashboardReports,
    topCustomers,
    cardCounts,
    recentAds,
    loading,
  } = useDashboard();
  const [sortConfig, setSortConfig] = useState({
    key: "title",
    direction: "asc",
  });
  const [sortedAds, setSortedAds] = useState([]);
  const [recentAdList, setRecentAdList] = useState([]);

  // Utility function for sorting data
  const sortData = (data, key, direction) => {
    if (!key) return data;

    return [...data].sort((a, b) => {
      const aValue = resolveValue(a, key);
      const bValue = resolveValue(b, key);
      const isANumber = !isNaN(aValue) && typeof aValue !== "string";
      const isBNumber = !isNaN(bValue) && typeof bValue !== "string";

      if (isANumber && isBNumber) {
        // Numeric sorting
        const numA = parseFloat(aValue);
        const numB = parseFloat(bValue);
        return direction === "asc" ? numA - numB : numB - numA;
      }

      // String sorting (force both values to strings for comparison)
      const aString = aValue ? String(aValue).toLowerCase() : "";
      const bString = bValue ? String(bValue).toLowerCase() : "";
      return direction === "asc"
        ? aString.localeCompare(bString)
        : bString.localeCompare(aString);
    });
  };

  // Resolve value from object based on key
  const resolveValue = (obj, key) => {
    const keyParts = key.split(".");

    // Handle known cases first, then use dynamic resolution
    const fieldMap = {
      user: obj?.postedBy?.name,
      category: obj?.category?.categoryId?.name?.en,
      startDate: obj?.advertisementTimeFrame?.startDate,
    };

    if (fieldMap[keyParts[0]]) {
      return fieldMap[keyParts[0]] || "";
    }

    // Default handling for other cases
    return keyParts.reduce((acc, part) => acc?.[part], obj) || "";
  };

  // Handle sort action
  const handleSort = (key) => {
    const sortKeyMap = {
      AD_TITLE: "title",
      USER: "user",
      ID: "soldId",
      CATEGORY: "category",
      STATUS: "status",
      VIEW_PERIOD: "startDate",
    };

    const sortKey = sortKeyMap[key] || key;
    console.log(sortKey, "sortKey");

    setSortConfig((prev) => ({
      key: sortKey,
      direction:
        prev.key === sortKey && prev.direction === "asc" ? "desc" : "asc",
    }));
  };

  // Effect to sort data when sortConfig or recentAds change
  useEffect(() => {
    setRecentAdList(recentAds);
    if (sortConfig.key && recentAdList?.length) {
      const sorted = sortData(
        recentAdList,
        sortConfig.key,
        sortConfig.direction
      );
      setRecentAdList(sorted);
      setSortedAds(sorted);
    }
  }, [sortConfig, recentAds]);

  const handleChange = (event) => {
    const value = event.target.value;
    const payload = {
      type:
        chartPeriod == 1 ? "weekly" : chartPeriod == 2 ? "monthly" : "daily",
      timeframe:
        chartPeriod == 1
          ? "this_week"
          : chartPeriod == 2
          ? "this_month"
          : "today",
      filter: value,
      timeZone,
    };
    setFilter(
      chartPeriod == 1 ? "this_week" : chartPeriod == 2 ? "this_month" : "today"
    );
    getDashboardReports(payload);
    setFilter(value);
  };

  const handleTimeFrameChange = (direction) => {
    const typeFrames = initialTimeFrame.find(
      (item) => item.type === chartPeriod
    );
    if (typeFrames) {
      const currentIndex = typeFrames.frame.indexOf(timeframe);
      if (currentIndex !== -1) {
        const nextIndex =
          direction === "next"
            ? (currentIndex + 1) % typeFrames.frame.length // Loop to the start
            : (currentIndex - 1 + typeFrames.frame.length) %
              typeFrames.frame.length; // Loop to the end
        setTimeFrame(typeFrames.frame[nextIndex]);
        const payload = {
          type:
            chartPeriod == 1
              ? "weekly"
              : chartPeriod == 2
              ? "monthly"
              : "daily",
          timeframe: typeFrames.frame[nextIndex],
          filter,
          timeZone,
        };
        getDashboardReports(payload);
      }
    }
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(Number(number) || 0);
  };

  const handleChartPeriodChange = (event, newValue) => {
    const payload = {
      type: newValue == 1 ? "weekly" : newValue == 2 ? "monthly" : "daily",
      timeframe:
        newValue == 1 ? "this_week" : newValue == 2 ? "this_month" : "today",
      timeZone,
      filter,
    };
    setTimeFrame(
      newValue == 1 ? "this_week" : newValue == 2 ? "this_month" : "today"
    );
    getDashboardReports(payload);
    setChartPeriod(newValue);
  };

  const getCount = (title) => {
    const item = cardCounts.find((item) => item.type === title);
    return formatNumber(item?.count || 0);
  };

  useEffect(() => {
    getDashboardCardData();
    getDashboardTopCustomer();
    getDashboardRecentAds();
    const payload = {
      type:
        chartPeriod == 1 ? "weekly" : chartPeriod == 2 ? "monthly" : "daily",
      timeframe:
        chartPeriod == 1
          ? "this_week"
          : chartPeriod == 2
          ? "this_month"
          : "today",
      filter,
      timeZone,
    };
    getDashboardReports(payload);
  }, []);

  return loading ? (
    <DashboardSkeleton />
  ) : (
    <Box sx={{ p: 3 }}>
      {/* Header */}
      <Paper elevation={0}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
            p: 2,
          }}
        >
          <Typography
            variant="h4"
            sx={{ color: "primary.main" }}
            fontWeight="bold"
          >
            {t("DASHBOARD")}
          </Typography>
          <Typography variant="h5" color="text.secondary" fontWeight="bold">
            {t("WELCOME_ADMIN")}
          </Typography>
        </Box>
      </Paper>

      {/* Stats Cards */}
      <Paper elevation={0}>
        <Grid container spacing={2} sx={{ mb: 2, p: 2 }}>
          <Grid item xs={6} md={3}>
            <Card sx={{ bgcolor: "#F06B5B" }}>
              <CardContent>
                <Typography variant="h6" color="white" textAlign="center">
                  {t("TOTAL_ADS")}
                </Typography>
                <Typography variant="h4" color="white" textAlign="center">
                  {getCount("totalAds")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card sx={{ bgcolor: "#F4A261" }}>
              <CardContent>
                <Typography variant="h6" color="white" textAlign="center">
                  {t("TOTAL_REVENUE_KWD")}
                </Typography>
                <Typography variant="h4" color="white" textAlign="center">
                  {getCount("totalRevenue")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card sx={{ bgcolor: "#E9C46A" }}>
              <CardContent>
                <Typography variant="h6" color="white" textAlign="center">
                  {t("TOTAL_USERS")}
                </Typography>
                <Typography variant="h4" color="white" textAlign="center">
                  {getCount("totalUsers")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={3}>
            <Card sx={{ bgcolor: "#2A9D8F" }}>
              <CardContent>
                <Typography variant="h6" color="white" textAlign="center">
                  {t("TOTAL_ACTIVE_ADS")}
                </Typography>
                <Typography variant="h4" color="white" textAlign="center">
                  {getCount("totalActiveAds")}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Paper>
      {/* Chart Section */}
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <Box sx={{ p: 1, mt: "5px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Tabs
                  value={chartPeriod}
                  onChange={handleChartPeriodChange}
                  variant="fullWidth"
                  sx={{
                    ml: 5,
                    "& .MuiTab-root": {
                      backgroundColor: "lightgray",
                      margin: "0 6px",
                      transition: "background-color 0.3s",
                      fontWeight: "bold",
                      height: "30px !important",
                      minHeight: "30px !important",
                      lineHeight: "1.2",
                      padding: "4px 12px",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "primary.main",
                      color: "#fff !important",
                    },
                    "& .MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                >
                  <Tab
                    label={t("DAILY")}
                    sx={{ textTransform: "none", minWidth: "120px" }}
                  />
                  <Tab
                    label={t("WEEKLY")}
                    sx={{ textTransform: "none", minWidth: "120px" }}
                  />
                  <Tab
                    label={t("MONTHLY")}
                    sx={{ textTransform: "none", minWidth: "120px" }}
                  />
                </Tabs>
                <FormControl>
                  <Select
                    sx={{ width: "150px", height: "30px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filter}
                    onChange={handleChange}
                  >
                    <MenuItem value={"total_revenue"}>
                      {t("TOTAL_REVENUE")}
                    </MenuItem>
                    <MenuItem value={"total_ads"}>{t("TOTAL_ADS")}</MenuItem>
                    <MenuItem value={"total_users"}>
                      {t("TOTAL_USERS")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <IconButton
                  edge="end"
                  onClick={() => handleTimeFrameChange("prev")}
                >
                  <KeyboardArrowLeftIcon
                    fontSize="large"
                    style={{
                      transform:
                        theme.direction === "rtl" ? "rotate(180deg)" : "none",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </IconButton>
                <Typography
                  sx={{ textTransform: "capitalize", fontWeight: "bold" }}
                >
                  {t(timeframe.toUpperCase())}
                </Typography>
                <IconButton
                  edge="end"
                  onClick={() => handleTimeFrameChange("next")}
                >
                  <KeyboardArrowRightIcon
                    fontSize="large"
                    style={{
                      transform:
                        theme.direction === "rtl" ? "rotate(180deg)" : "none",
                      transition: "transform 0.3s ease",
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
            <Box sx={{ height: 275, width: "105%", ml: "-2.5rem" }}>
              <ResponsiveContainer
                display="flex"
                justifyContent="center"
                width="100%"
                height="100%"
              >
                <BarChart data={dashboardReports}>
                  <CartesianGrid
                    strokeDasharray="1 1"
                    vertical={false}
                    horizontal={true}
                  />
                  <XAxis
                    dataKey={chartPeriod === 0 ? "hour" : "date"}
                    tickFormatter={(value) =>
                      chartPeriod === 0
                        ? `${
                            value === 0 ? 12 : value > 12 ? value - 12 : value
                          }${value < 12 ? " AM" : " PM"}`
                        : value
                    }
                  />
                  <YAxis />
                  <Tooltip
                    content={({ payload, label }) => {
                      if (!payload || payload.length === 0) return null;
                      const formattedLabel =
                        chartPeriod === 0
                          ? `${
                              label === 0 ? 12 : label > 12 ? label - 12 : label
                            }${label < 12 ? " AM" : " PM"}`
                          : label;

                      return (
                        <div
                          style={{
                            backgroundColor: "#fff",
                            padding: "5px",
                            border: "1px solid #ccc",
                          }}
                        >
                          <p>{`Time: ${formattedLabel}`}</p>
                          <p>{`Count: ${payload[0].value}`}</p>
                        </div>
                      );
                    }}
                  />
                  <Bar dataKey="count" fill="#F06B5B" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>

        {/* Top Customers */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 3, minHeight: 395 }} elevation={0}>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{ textAlign: "center", color: "#F06B5B" }}
            >
              {t("TOP_CUSTOMERS")}
            </Typography>
            <List>
              {topCustomers.map((customer) => (
                <ListItem
                  key={customer.userId}
                  sx={{
                    backgroundColor: "#f3f3f1",
                    marginTop: ".5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "5px",
                    padding: "0.5rem",
                  }}
                >
                  <Box display="flex" alignItems="center">
                    <ListItemAvatar>
                      <Avatar src={customer.profilePicture} />
                    </ListItemAvatar>
                    <Box>
                      <Typography fontWeight="bold">
                        {customer.userName}
                      </Typography>
                      <Typography sx={{ color: "#eb6953", fontSize: "12px" }}>
                        {t("MEMBER_SINCE")}{" "}
                        {formatTimestamp(customer.createdAt, false)}
                      </Typography>
                    </Box>
                  </Box>
                  <IconButton edge="end" href={`/user/${customer.userId}`}>
                    <ChevronRightIcon
                      size="lg"
                      style={{
                        color: "#eb6953",
                        transform:
                          theme.direction === "rtl" ? "rotate(180deg)" : "none",
                        transition: "transform 0.3s ease",
                      }}
                    />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Recent Ads */}
      <Paper sx={{ mt: 2 }} elevation={0}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 2,
          }}
        >
          <Typography variant="h6" fontWeight="bold" color="#F06B5B">
            {t("RECENT_ADS")}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            // endIcon={<ArrowForwardIcon />}
            href="/ads"
            sx={{
              textTransform: "none",
              "& .MuiButton-startIcon": {
                marginLeft: theme.direction === "rtl" ? "8px" : "0px",
                marginRight: theme.direction === "ltr" ? "8px" : "0px",
              },
            }}
          >
            {t("GO_TO_ADS_MANAGEMENT")}
          </Button>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ bgcolor: "#F06B5B" }}>
              <TableRow>
                {headers.map(({ key, align }) => (
                  <TableCell
                    key={key}
                    sx={{
                      padding: "5px",
                      paddingLeft: align === "left" ? "16px" : "5px",
                      paddingRight: align === "left" ? "5px" : "16px",
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor: "primary.main",
                      textAlign: align === "left" ? "start" : "center",
                      borderRadius: "0px",
                      border: "none ",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSort(key)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: key !== "AD_TITLE" && "center",
                      }}
                    >
                      {t(key)}
                      {key !== "ACTION" && (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <UnfoldMoreRoundedIcon />
                        </Box>
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {recentAdList?.map((ad) => (
                <StyledTableRow key={ad?._id}>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "0px",
                      textAlign: "start",
                      border: "none",
                      maxWidth: "300px",
                    }}
                  >
                    <Box
                      sx={{
                        maxWidth: "250px", // Set maximum width for ellipsis
                        overflow: "hidden", // Hide content beyond the max width
                        textOverflow: "ellipsis", // Add "..." for overflowing text
                        whiteSpace: "nowrap", // Prevent text from wrapping to the next line
                        mx: "10px",
                      }}
                    >
                      <Typography variant="">{ad?.title}</Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "0px",
                      border: "none",
                      maxWidth: "250px",
                    }}
                    align="center"
                  >
                    {ad?.soldId}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "0px",
                      border: "none",
                      maxWidth: "250px",
                    }}
                    align="center"
                  >
                    {ad?.postedBy?.name}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "0px",
                      border: "none",
                      maxWidth: "250px",
                      color:
                        ad?.status === "Active"
                          ? "green"
                          : ad?.status === "Pending"
                          ? "red"
                          : "orange",
                    }}
                    align="center"
                  >
                    {ad?.status}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "0px",
                      border: "none",
                      maxWidth: "250px",
                    }}
                    align="center"
                  >
                    {ad?.advertisementTimeFrame?.startDate ||
                    ad?.advertisementTimeFrame?.endDate ? (
                      <>
                        <Box>
                          <span style={{ color: "#80808091" }}>
                            {t("START")}:{" "}
                          </span>
                          {ad?.advertisementTimeFrame?.startDate
                            ? formatTimestamp(
                                ad?.advertisementTimeFrame?.startDate,
                                false
                              )
                            : ""}
                        </Box>
                        <Box>
                          <span style={{ color: "#80808091" }}>
                            {t("END")}:{" "}
                          </span>
                          {ad?.advertisementTimeFrame?.endDate
                            ? formatTimestamp(
                                ad?.advertisementTimeFrame?.endDate,
                                false
                              )
                            : ""}
                        </Box>
                      </>
                    ) : (
                      "N/A"
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "0px",
                      border: "none",
                      maxWidth: "250px",
                    }}
                    align="center"
                  >
                    {getLocalizedValue(ad?.category?.categoryId?.name)}
                  </TableCell>
                  <TableCell
                    sx={{
                      fontWeight: "bold",
                      padding: "5px",
                      border: "none",
                      maxWidth: "250px",
                    }}
                    align="center"
                  >
                    <Button
                      size="small"
                      variant="outlined"
                      color="black"
                      sx={{
                        textTransform: "inherit",
                        padding: "0",
                        minWidth: "45px",
                        fontWeight: "bold",
                      }}
                      onClick={() => navigate(`/ad-info/${ad?._id}`)}
                    >
                      {t("VIEW")}
                    </Button>
                  </TableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}

const DashboardSkeleton = () => {
  return (
    <Box padding={2}>
      {/* First Row: Summary Cards */}
      <Skeleton
        variant="rectangular"
        height={70}
        sx={{ marginBottom: "1rem" }}
      />
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Skeleton variant="rectangular" height={120} />
          </Grid>
        ))}
      </Grid>

      {/* Second Row: Graph and Top Customers */}
      <Grid container spacing={2} mt={2}>
        <Grid item xs={12} md={8}>
          <Skeleton variant="rectangular" height={300} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Stack spacing={2}>
            {Array.from({ length: 5 }).map((_, index) => (
              <Skeleton key={index} variant="rectangular" height={50} />
            ))}
          </Stack>
        </Grid>
      </Grid>

      {/* Third Row: Recent Ads Table */}
      <Box mt={2}>
        <Skeleton variant="text" sx={{ fontSize: "1.5rem", width: "20%" }} />
        <Skeleton variant="rectangular" height={400} />
      </Box>
    </Box>
  );
};
