import api from "../utils/API";

export const fetchConversations = async (user, search = "", page = 1) => {
  const response = await api.get(
    `/conversations?page=${page}&limit=10&search=${search}`
  );
  return response.data;
};

export const fetchMessages = async (chatId, page = 1) => {
  const response = await api.get(`/messages/${chatId}?page=${page}&limit=10`);
  return response.data;
};

export const initConversation = async (post) => {
  const response = await api.post("/conversations", {
    postId: post._id,
    postOwnerId: post.postedBy._id,
  });
  return response.data;
};
