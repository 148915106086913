import { useNavigate, useParams } from "react-router-dom";
import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography,
  Skeleton,
  Grid,
} from "@mui/material";
import CustomTabPanel from "../../components/common/CustomTabPanel";
import useCategory from "../../hooks/useCategory";
import { useFormik } from "formik";
import { useEffect } from "react";
import { ArrowBack } from "@mui/icons-material";
import CustomPageHeader from "../../components/common/CustomPageHeader";
import { useTranslation } from "react-i18next";

function CategoryEditPage() {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(0);
  const {
    createCategory,
    loading,
    getCategoryById,
    category,
    updateCategory,
    deleteCategory,
    clearCategory,
  } = useCategory();
  const [iconImage, setIconImage] = React.useState(null);

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const formik = useFormik({
    initialValues: {
      name: {
        en: "",
        ar: "",
      },
    },
    onSubmit: async (values) => {
      try {
        const formData = prepareFormData(values);

        const response = id
          ? await updateCategory(id, formData)
          : await createCategory(formData);

        if (response.success) {
          formik.resetForm(); // Reset form after successful submission
          navigate("/category"); // Navigate to the category list page
        } else {
          alert("Oops.. Something went wrong.");
        }
      } catch (error) {
        console.error("Error during form submission:", error);
        alert("Oops.. Something went wrong.");
      }
    },
  });

  const prepareFormData = (values) => {
    const formData = new FormData();
    if (iconImage) {
      formData.append("iconImage", iconImage[0]);
    }
    formData.append("name", JSON.stringify(values.name));
    return formData;
  };

  const handleFileChange = (event) => {
    const file = event.target.files;
    if (file) {
      setIconImage([...file]);
    }
  };

  const handleDeleteCategory = () => {
    if (id) {
      if (!window.confirm("Are you sure you want to delete this category?")) {
        return;
      }
      const payload = { ids: [id] };

      deleteCategory(payload).then((response) => {
        if (response.success) {
          navigate("/category");
        } else {
          alert("Oops.. Something went wrong.");
        }
      });
    } else {
      navigate("/category");
    }
  };
  useEffect(() => {
    clearCategory();
    formik.resetForm();
    if (id) {
      getCategoryById(id);
    }
  }, [id]);

  useEffect(() => {
    formik.resetForm();
    if (category) {
      formik.setValues({
        name: {
          en: category?.name?.en,
          ar: category?.name?.ar,
        },
      });
      if (category?.iconImage) {
        setIconImage(category?.iconImage);
      }
    }
  }, [category]);
  return loading ? (
    <CategoryFormSkeleton />
  ) : (
    <Box sx={{ width: "100%" }}>
      <CustomPageHeader
        heading={`${id ? t("EDIT") : t("ADD")} ${t("CATEGORY_ITEM")}`}
        buttontext={t("BACK_TO_LIST")}
        path="/category"
      />
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          <Box style={{ display: "flex", width: "100%" }}>
            <Button
              variant={value === 0 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "18px",
                fontWeight: "bold",
                textTransform: "inherit",
                height: "35px",
              }}
              onClick={() => handleChange(0)}
            >
              {t("ENGLISH")}
            </Button>
            <Button
              variant={value === 1 ? "contained" : "text"}
              style={{
                borderRadius: "0px",
                width: "50%",
                fontSize: "18px",
                fontWeight: "bold",
                textTransform: "inherit",
                height: "35px",
              }}
              onClick={() => handleChange(1)}
            >
              {t("ARABIC")}
            </Button>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Typography variant="body1" fontWeight="bold">
              {t("CATEGORY_NAME")}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name.en"
              value={formik?.values?.name?.en}
              onChange={formik.handleChange}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1} dir="rtl">
            <Typography variant="body1" fontWeight="bold">
              {t("CATEGORY_NAME")}
            </Typography>
            <TextField
              fullWidth
              size="small"
              name="name.ar"
              value={formik?.values?.name?.ar}
              onChange={formik.handleChange}
            />
          </CustomTabPanel>
        </Container>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              gap: 5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
              }}
            >
              <Typography fontWeight="bold" variant="body2">
                {t("ICON_IMAGE")}
              </Typography>
              <Box
                sx={{
                  height: "70px",
                  width: "70px",
                  backgroundColor: "primary.main",
                  borderRadius: "10px",
                }}
              >
                {iconImage && (
                  <img
                    style={{
                      height: "70px",
                      width: "70px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                    src={
                      typeof iconImage === "string"
                        ? iconImage
                        : URL.createObjectURL(iconImage[0])
                    }
                    alt="icon preview"
                  />
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                sx={{
                  textTransform: "inherit",
                  fontWeight: "bold",
                }}
              >
                {t("UPLOAD_IMAGE")}
                <input
                  type="file"
                  onChange={handleFileChange}
                  id="imageUploadInput"
                  accept="image/*"
                  style={{ display: "none" }}
                  multiple
                />
              </Button>
              <Typography fontWeight="bold" variant="body2">
                {t("IMAGE_SIZE")}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Paper>
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                height: "40px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={formik.handleSubmit}
            >
              {t("SAVE")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                height: "40px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={handleDeleteCategory}
            >
              {t("DELETE_CATEGORY")}
            </Button>
          </Box>
        </Container>
      </Paper>
    </Box>
  );
}

export default CategoryEditPage;

const CategoryFormSkeleton = () => {
  return (
    <Box sx={{ width: "100%" }}>
      {/* Header */}
      <Skeleton variant="rectangular" height={70} sx={{ mb: 2 }} />

      {/* Form Container */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", padding: "20px", mt: "20px" }}
      >
        <Container maxWidth="sm">
          {/* Language Buttons */}
          <Box style={{ display: "flex", width: "100%", mb: 2 }}>
            <Skeleton variant="rectangular" height={35} width="50%" />
            <Skeleton
              variant="rectangular"
              height={35}
              width="50%"
              sx={{ ml: 1 }}
            />
          </Box>

          {/* Form Fields */}
          <Box mt={4}>
            <Skeleton variant="text" sx={{ fontSize: "1rem", mb: 1 }} />
            <Skeleton variant="rectangular" height={40} width="100%" />
          </Box>
        </Container>
      </Paper>

      {/* Icon Upload Section */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Grid container spacing={3} justifyContent="center">
            {/* Icon Preview */}
            <Grid item>
              <Skeleton
                variant="rectangular"
                height={70}
                width={70}
                sx={{ borderRadius: "10px" }}
              />
            </Grid>

            {/* Upload Button */}
            <Grid item>
              <Skeleton variant="rectangular" height={40} width={150} />
              <Skeleton
                variant="text"
                sx={{ fontSize: "0.875rem", mt: 1, width: 100 }}
              />
            </Grid>
          </Grid>
        </Container>
      </Paper>

      {/* Action Buttons */}
      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "30px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Skeleton variant="rectangular" height={40} width={150} />
            <Skeleton variant="rectangular" height={40} width={150} />
          </Box>
        </Container>
      </Paper>
    </Box>
  );
};
