import {
  Alert,
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function AdminLoginForm() {
  const theme = useTheme(); // Access the theme for colors
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(true);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const { login } = useAuth();

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values) => {
      if (!values.email || !values.password) {
        setLoginErrorMessage("Email and password are required.");
        return;
      }
      const response = await login(values);
      if (response.success) {
        navigate("/");
        setLoginErrorMessage("");
      } else {
        setIsLoginSuccessful(false);
        setLoginErrorMessage(response?.errorMessage);
      }
    },
  });

  return (
    <Container maxWidth="xs">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          component="img"
          src="./logo-white.png"
          alt="Admin Panel Logo"
          sx={{
            width: 250,
            height: 250,
            objectFit: "contain",
          }}
        />
      </Box>

      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{ marginBottom: 4, textAlign: "center" }}
      >
        {t("ADMIN_LOGIN")}
      </Typography>
      {isLoginSuccessful === false && (
        <Alert severity="error" style={{ width: "100%", marginBottom: "20px" }}>
          {loginErrorMessage}
        </Alert>
      )}
      {/* Email Field */}
      <Typography variant="body2" fontWeight="bold" mb={"5px"}>
        {t("EMAIL")}
      </Typography>
      <TextField
        fullWidth
        variant="outlined"
        type="email"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        sx={{
          marginBottom: 2,
          backgroundColor: "white",
          borderRadius: 2,
        }}
      />

      {/* Password Field */}
      <Typography variant="body2" fontWeight="bold" mb={"5px"}>
        {t("PASSWORD")}
      </Typography>
      <OutlinedInput
        fullWidth
        variant="outlined"
        type={showPassword ? "text" : "password"}
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleTogglePasswordVisibility}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        sx={{
          marginBottom: 2,
          backgroundColor: "white",
          borderRadius: 2,
        }}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            formik.handleSubmit();
          }
        }}
      />
    </Container>
  );
}

export default AdminLoginForm;
