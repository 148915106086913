import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import usePaymentPlans from "../../hooks/usePaymentPlans";
import CustomTable from "../../components/common/CustomTable";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@emotion/react";
import { useDispatch } from "react-redux";
import { setPayment } from "../../store/paymentPlanSlice";
import CloseIcon from "@mui/icons-material/Close";
import getLocalizedValue from "../../utils/getLocalizedValue";
import SearchIcon from "@mui/icons-material/Search";
import i18n from "i18next";
import DoneIcon from "@mui/icons-material/Done";
// Columns definition

function PaymentPlansPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paymentPlan } = usePaymentPlans();
  const [loading, setLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [paymentList, setPaymentList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { getPaymentPlans, deletePaymentPlans } = usePaymentPlans();
  const [pagination, setPagination] = useState({
    page: paymentPlan?.page || 1,
    limit: paymentPlan?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: paymentPlan?.totalCount || 0,
    totalPages: paymentPlan?.totalPages || 0,
  });
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    { id: "_id", label: "", align: i18n.language == "en" ? "left" : "right" },
    {
      id: "paymentPlanName",
      label: t("PAYMENT_PLANS"),
      align: i18n.language == "en" ? "left" : "right",
    },
    { id: "publish", label: t("PUBLISH"), align: "center" },
    { id: "display_order", label: t("DISPLAY_ORDER"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];

  // Fetch Payment Plans
  const fetchPaymentPlans = async (payload) => {
    setLoading(true);
    try {
      const data = await getPaymentPlans(payload);
      if (data.success) {
        setPaymentList(data.data || []);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  // Search Payment Plans
  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: searchQuery,
    };
    fetchPaymentPlans(payload);
    return;
  };

  // Handle Checkbox Selection
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };

  // Handle Delete
  const handleDelete = async () => {
    if (selectedIds.length === 0) {
      alert("No items selected for deletion.");
      return;
    }
    try {
      if (
        !window.confirm(
          `Are you sure you want to delete selected ${
            selectedIds.length > 1 ? "plans" : "plan"
          }?`
        )
      ) {
        return;
      }
      const response = await deletePaymentPlans({ ids: selectedIds });
      if (response.success) {
        alert("Selected payment plans deleted successfully.");
        fetchPaymentPlans();
      }
    } catch (error) {
      console.error("Error deleting payment plans:", error);
    }
  };

  const handleChange = (event) => {
    dispatch(
      setPayment({
        data: {
          ...paymentPlan,
          type: event.target.value,
        },
      })
    );
  };

  // Map Payment List to Table Rows
  const tableData = useMemo(() => {
    return paymentList.map((item, index) => ({
      _id: (
        <Box sx={{ maxWidth: "15px" }}>
          <Checkbox
            checked={selectedIds.includes(item._id)}
            onChange={() => handleCheckboxChange(item._id)}
          />
        </Box>
      ),
      paymentPlanName: getLocalizedValue(item.paymentPlanName),
      publish: item.isEnabled ? (
        <DoneIcon
          fontSize="small"
          sx={{ color: "green", fontWeight: "bold" }}
        />
      ) : (
        <CloseIcon fontSize="small" sx={{ color: "red", fontWeight: "bold" }} />
      ),
      display_order: index + 1,
      // (pagination.page - 1) * pagination.limit + (index + 1),
      action: (
        <Button
          size="small"
          variant="outlined"
          color="black"
          sx={{
            textTransform: "inherit",
            padding: "0",
            minWidth: "45px",
            fontWeight: "bold",
          }}
          onClick={() => navigate(`/payment-plan/${item._id}`)}
        >
          {t("EDIT")}
        </Button>
      ),
    }));
  }, [paymentList, selectedIds, navigate]);

  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: searchQuery,
    };
    fetchPaymentPlans(payload);
  }, [pagination?.page, pagination?.limit]);

  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t("PAYMENT_PLANS")}
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              // onClick={() => navigate("/payment-plan/create")}
              onClick={handleClickOpen}
            >
              {t("ADD_ITEM")}
            </Button>
            <Dialog
              open={open}
              onClose={handleClose}
              keepMounted
              aria-labelledby="responsive-dialog-title"
              PaperProps={{
                sx: {
                  width: "100%",
                  maxWidth: "820px!important",
                },
              }}
            >
              <Box p={2} sx={{ backgroundColor: "#f3f3f1", width: "100%" }}>
                <Paper sx={{ position: "relative", boxShadow: "none" }}>
                  <CloseIcon
                    color="primary"
                    sx={{
                      position: "absolute",
                      top: "0",
                      right: i18n.language === "en" ? "0" : "auto",
                      left: i18n.language === "en" ? "auto" : "0",
                      cursor: "pointer",
                    }}
                    onClick={handleClose}
                  />
                  <DialogTitle
                    color="primary"
                    display="flex"
                    justifyContent="center"
                    fontWeight="bold"
                    id="responsive-dialog-title"
                  >
                    {t("ADD_ITEM")}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <FormControl
                        sx={{
                          minWidth: "500px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={paymentPlan?.type || ""}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="REGULAR_PAYMENT_PLAN"
                            control={<Radio />}
                            label={t("REGULAR_PAYMENT_PLAN")}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontWeight: "bold",
                              },
                            }}
                          />
                          <FormControlLabel
                            value="PROMOTIONAL_PAYMENT_OPTION"
                            control={<Radio />}
                            label={t("PROMOTIONAL_PAYMENT_OPTION")}
                            sx={{
                              "& .MuiFormControlLabel-label": {
                                fontWeight: "bold",
                              },
                            }}
                          />
                        </RadioGroup>
                      </FormControl>
                    </DialogContentText>
                  </DialogContent>
                </Paper>
              </Box>
              <Box
                fullWidth
                display="flex"
                sx={{
                  backgroundColor: "#f3f3f1",
                  padding: "0px 16px 18px",
                }}
              >
                <Paper
                  sx={{
                    padding: "25px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    boxShadow: "none",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mx: "5px",
                      textTransform: "inherit",
                      minWidth: "150px",
                      fontWeight: "bold",
                    }}
                    size="large"
                    onClick={() =>
                      paymentPlan.type
                        ? navigate("/payment-plan/create")
                        : alert("Please select a payment plan type")
                    }
                  >
                    {t("SELECT")}
                  </Button>
                </Paper>
              </Box>
            </Dialog>
            <Button
              variant="contained"
              color="secondary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="large"
              onClick={handleDelete}
            >
              {t("DELETE")}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              placeholder={t("SEARCH_ITEM_NAME")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{
                mx: "5px",
                textTransform: "inherit",
                minWidth: "150px",
                fontWeight: "bold",
              }}
              size="medium"
              onClick={handleSearch}
            >
              {t("SEARCH")}
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={tableData}
          columns={columns}
          loading={loading}
          pagination={{ ...pagination, ...pageData }}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

export default PaymentPlansPage;
