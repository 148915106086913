import {
  Box,
  Button,
  Checkbox,
  Container,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import i18n from 'i18next';
import CustomTable from "../../components/common/CustomTable";
import { useNavigate } from "react-router-dom";
import getLocalizedValue from "../../utils/getLocalizedValue";
import useCategory from "../../hooks/useCategory";
import { useTranslation } from "react-i18next";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

function ManuPage() {
  const { t } = useTranslation();
  // Columns definition
  const columns = [
    { id: "id", label: "", align: i18n.language == 'en' ? 'left': 'right' },
    // { id: "icon", label: t("ICON"), align: i18n.language == 'en' ? 'left': 'right' },
    { id: "name", label: t("CATEGORY_MENU_LIST"), align: i18n.language == 'en' ? 'left': 'right' },
    { id: "publish", label: t("PUBLISH"), align: "center" },
    { id: "displayOrder", label: t("DISPLAY_ORDER"), align: "center" },
    { id: "action", label: t("ACTION"), align: "center" },
  ];

  const navigate = useNavigate();
  const { fetchCategories, categories, loading, deleteCategory } =
    useCategory();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    page: categories?.page || 1,
    limit: categories?.limit || 10,
  });
  const [pageData, setPageData] = useState({
    totalCount: categories?.totalCount || 0,
    totalPages: categories?.totalPages || 0,
  });
  const [selectedIds, setSelectedIds] = useState([]);
  const [search, setSearch] = useState("");

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) =>
      prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id]
    );
  };
  const handleSearch = () => {
    setPagination({ ...pagination, page: 1 });
    const payload = {
      page: 1,
      limit: pagination?.limit,
      search: search,
    };
    fetchCategories(payload);
  };

  useEffect(() => {
    const payload = {
      page: pagination?.page,
      limit: pagination?.limit,
      search: "",
    };
    fetchCategories(payload);
  }, [pagination?.page, pagination?.limit]);
  useEffect(() => {
    if (categories?.categories?.length > 0) {
      setData(
        categories?.categories?.map((category, index) => ({
          id: (
            <Box sx={{ maxWidth: "20px" }}>
              <Checkbox
                checked={selectedIds.includes(category._id)}
                onChange={() => handleCheckboxChange(category._id)}
              />
            </Box>
          ),
          // icon: (
          //   <img
          //     src={category.iconImage}
          //     alt="icon"
          //     style={{ width: "40px", height: "40px" }}
          //   />
          // ),
          name: getLocalizedValue(category.name),
          publish: (
            category?.isPublished
            ? <CheckIcon sx={{ color: 'green', fontWeight: 'xxl' }} />
            : <CloseIcon sx={{ color: 'red', fontWeight: 'xxl' }} />
          ),
          displayOrder: (pagination.page - 1) * pagination.limit + (index + 1),
          action: (
            <Button
              size="small"
              variant="outlined"
              color="black"
              sx={{
                textTransform: "inherit",
                padding: "0",
                minWidth: "45px",
                fontWeight: "bold"
              }}
              onClick={() => {
                navigate(`/menu/${category?._id}`);
              }}
            >
              {t('EDIT')}
            </Button>
          ),
        }))
      );
    } else {
      setData([]);
    }
    setPageData({
      totalCount: categories?.totalCount || 0,
      totalPages: categories?.totalPages || 0,
    });
  }, [categories, selectedIds]);
  return (
    <div>
      <Paper elevation={0} sx={{ borderRadius: "0px", padding: "10px" }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          color="primary"
        >
          {t('CATEGORY_MENU')}
        </Typography>
      </Paper>

      <Paper
        elevation={0}
        sx={{ borderRadius: "0px", mt: "20px", padding: "20px" }}
      >
        <Container maxWidth="sm">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
              onClick={() => navigate("/menu/create")}
            >
              {t('ADD_MENU_ITEM')}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="large"
            >
              {t('DELETE')}
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: "20px",
            }}
          >
            <TextField
              fullWidth
              sx={{ mx: "5px" }}
              size="small"
              value={search}
              onChange={(e) => {
                e.preventDefault();
                setSearch(e.target.value);
              }}
              placeholder={t('SEARCH_CATEGORY_NAME')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              color="primary"
              sx={{ mx: "5px", textTransform: "inherit", minWidth: "150px", fontWeight: "bold" }}
              size="medium"
              onClick={handleSearch}
            >
              {t('SEARCH')}
            </Button>
          </Box>
        </Container>
      </Paper>

      <Paper elevation={0} sx={{ borderRadius: "0px", mt: "20px" }}>
        <CustomTable
          data={data}
          columns={columns}
          loading={loading}
          pagination={{...pagination, ...pageData}}
          setPagination={setPagination}
        />
      </Paper>
    </div>
  );
}

export default ManuPage;
