import React from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLanguage = i18n.language === "en" ? "ar" : "en";
    i18n.changeLanguage(newLanguage);
  };

  return (
    <>
         <Button color="inherit" onClick={toggleLanguage}>
          {i18n.language === "en" ? "العربية" : "English"}
        </Button>
    </>
  );
};

export default LanguageSelector;
